import styled from "styled-components";

export const ArticleDiv = styled.div`
  color: #1a202c;
  //width: 50%;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
  }
  @media (max-width: 1196px) {
    width: 80%;
    margin-bottom: 50px;
  }

  @media (max-width: 616px) {
    width: 100%;
  }
`;

export const ArticleTitle = styled.text`
  text-align: left;
  font-size: 38px;
  font-weight: 600;
  color: #1a202c;
  opacity: 1;
  //margin-bottom: 21px;
  //border-bottom: 2px solid #00cb2d;
`;

export const ArticleDescription = styled.text`
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  ///letter-spacing: 1.20px;
  line-height: 28px;
  color: #1a202c;
  opacity: 1;

  @media (max-width: 590px) {
    font-size: 20px;
  }
`;
