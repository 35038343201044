import Layout from "../../components/Layout";
import ServicesBackground from "../../assets/Services/services.png";
import lab_background from "../../assets/Services/lab_background.png";
import {
  CardContent,
  ContactService,
  ContainerLabInfo,
  FormContactService,
  InfoContact,
  LabImg,
  LabsContent,
  LabsTest,
  ParagraphLabInfo,
  TextCard,
  TitleContainer,
  TitleLabInfo,
} from "./styles";
import { ColForm, Faixa, RowForm } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import {
  CheckForm,
  Form,
  FormContactUs,
  HelperText,
  LabsCardBack,
  LabsCardFront,
  LabsCards,
  PolicyPricacy,
  SubBack,
  SubContent,
  SubDownload,
  TitleContactInfo,
  TitleSub,
} from "pages/Areas/styles";
import lab_testes from "../../assets/Services/lab_testes.jpg";
import sub_lab_testes from "../../assets/Services/sub_lab_testes.jpg";
import downloadIcon from "./../../assets/Areas/download.svg";
import lab_essay from "../../assets/Services/lab_essay.jpg";
import labs_raiox from "../../assets/Services/labs_raiox.jpg";
import labs_analysis from "../../assets/Services/labs_analysis.jpg";
import labs_img from "../../assets/Services/labs_img.png";
import FOTO_Back from "../../assets/Services/FOTO_Back.png";
import circle_right_main from "../../assets/About/circle_right_main.svg";
import { Checkbox, TextField } from "@mui/material";
import MainBtn from "components/Buttons/MainBtn";
import { useState } from "react";
import { footerIcons } from "utils/links";
import { useTranslation } from "react-i18next";
import { ParallaxContainer } from "components/Parallax/styles";
import {
  handleCellPhoneChange,
  handleEmailInputChange,
  isAnyFieldEmpty,
} from "utils/formatText";
export default function Services() {
  const { t } = useTranslation();
  const labs = [
    {
      left: false,
      title: t("productResistanceAssessmentTests"),
      description: t("carryOutDustTestsEvaluate"),
      img: lab_essay,
    },
    {
      left: true,
      title: "Raio-X",
      description: t("carryOutNon_destructive"),
      img: labs_raiox,
    },
    {
      false: false,
      title: t("metallographicAnalysis"),
      description: t("performTechniqueOnPCBs"),
      img: labs_analysis,
    },
  ];
  const [formScheduleVisit, setFormScheduleVisit] = useState({
    name: "",
    email: "",
    phone: "",

    company: "",

    message: "",
    check: false,
  });
  const [formError, setFormError] = useState({
    errors: {
      email: "",
    },
  });

  const handleForm = () => {
    // console.log("form", formScheduleVisit);
    const url = "https://api.conecthus.org.br/mailer/send";
    const params = {
      sender: "Custumer_Service_Office",
      receiver: "cso@conecthus.org.br",
      subject: "Formulario - Agende uma visita",
      message: `<h3>Segue o formulario de contato de ${formScheduleVisit.name}</h3>
         <br>nome: ${formScheduleVisit.name}</br>
         <p></p>
         <br>Email: ${formScheduleVisit.email}</br>
         <p></p>
         <br>Telefone: ${formScheduleVisit.phone}</br>
         <p></p>
         <br>Empresa: ${formScheduleVisit.company}</br>
         <p></p>
         <br>Mensagem: ${formScheduleVisit.message}</br>
         <p></p>
         
         `,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFormScheduleVisit({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: "",
          check: false,
        });
        window.location.href = "#";
        const params = {
          sender: "Custumer_Service_Office",
          receiver: `${formScheduleVisit.email}`,
          subject: "Confirmação de Recebimento - Conecthus",
          message: `<h3>Prezado(a) ${formScheduleVisit.name}</h3>
          <br>Agradecemos por entrar em contato conosco através do formulário em nosso website. Este e-mail é para confirmar que recebemos sua mensagem e estamos trabalhando para fornecer a melhor assistência.</b><br></br><br>Nossa equipe está analisando suas informações e faremos o possível para responder em breve.</br>
          <br></br><br>Atenciosamente,</br><br></br><br>Instituto Conecthus</br>`,
        };

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  return (
    <Layout>
      {/* <Parallax
        background={ServicesBackground}
        title={t("servicesWithInfrastructure")}
        leftBottom
      /> */}
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <ParallaxContainer background={ServicesBackground} />
        <TitleContainer>
          <h3>
            <a className="header-anchor" href="#serviceWith" id="serviceWith">
              #
            </a>
            {t("serviceWith")}
          </h3>
          <h3>
            <a
              className="header-anchor"
              href="#infrastructureTeam"
              id="infrastructureTeam"
            >
              #
            </a>
            {t("infrastructureTeam")}{" "}
          </h3>
          <h3>
            <a
              className="header-anchor"
              href="#specializedGuarantee"
              id="specializedGuarantee"
            >
              #
            </a>
            {t("specializedGuarantee")}
          </h3>
          <h3>
            <a
              className="header-anchor"
              href="#customerSatisfaction"
              id="customerSatisfaction"
            >
              #
            </a>
            {t("customerSatisfaction")}
          </h3>
        </TitleContainer>
      </div>
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>

      <ContainerLabInfo background={lab_background} id="main">
        <TitleLabInfo>
          <a
            className="header-anchor"
            href="#reliabilityLaboratory"
            id="reliabilityLaboratory"
          >
            #
          </a>
          {t("reliabilityLaboratory")}
        </TitleLabInfo>
        <ParagraphLabInfo>
          <a
            className="header-anchor"
            href="#reliabilityTestingPerformed"
            id="reliabilityTestingPerformed"
          >
            #
          </a>
          {t("reliabilityTestingPerformed")}
          <div style={{ display: "inline", fontWeight: "bold" }}>
            {" "}
            {t("reliabilityTestingPerformed1")}
          </div>
          {t("reliabilityTestingPerformed2")}
        </ParagraphLabInfo>
      </ContainerLabInfo>

      <LabsTest>
        <h2>
          <a
            className="header-anchor"
            href="#reliabilityTestingLaboratory"
            id="reliabilityTestingLaboratory"
          >
            #
          </a>
          {t("reliabilityTestingLaboratory")}
        </h2>
        <h4>
          <a
            className="header-anchor"
            href="#theConecthusInstituteHardware"
            id="theConecthusInstituteHardware"
          >
            #
          </a>
          {t("theConecthusInstituteHardware")}
        </h4>
        <LabsCards>
          <LabsCardBack left={true} background={lab_testes}></LabsCardBack>
          <LabsCardFront right={true}>
            <SubBack labs_sub_back={sub_lab_testes}></SubBack>
            <TitleSub>
              <a
                className="header-anchor"
                href="#testingAndReliabilityLaboratory"
                id="testingAndReliabilityLaboratory"
              >
                #
              </a>
              {t("testingAndReliabilityLaboratory")}
            </TitleSub>
            <SubContent>
              <h3>
                <a
                  className="header-anchor"
                  href="#theReliabilityLaboratoryVariety"
                  id="theReliabilityLaboratoryVariety"
                >
                  #
                </a>
                {t("theReliabilityLaboratoryVariety")}
              </h3>
              <SubDownload>
                <h3>Portfólio Download</h3>
                <img src={downloadIcon} alt="" />
              </SubDownload>
            </SubContent>
          </LabsCardFront>
        </LabsCards>
      </LabsTest>

      <LabsContent>
        {labs.map((item: any) => (
          <CardContent left={item.left}>
            <img src={item.img} alt="" />
            <TextCard>
              <h2>{item.title}</h2>
              <h3>{item.description}</h3>
            </TextCard>
          </CardContent>
        ))}
      </LabsContent>

      <LabImg background={labs_img} />

      <ContactService>
        <TitleContactInfo>
          <div className="ImgInfo">
            <img src={circle_right_main} alt="" height={36} />
          </div>
          <h1>
            <a
              className="header-anchor"
              href="#speakToOneurExperts"
              id="speakToOneurExperts"
            >
              #
            </a>
            {t("speakToOneurExperts")},
          </h1>
          <h2>
            <a
              className="header-anchor"
              href="#scheduleVisitAndSee"
              id="scheduleVisitAndSee"
            >
              #
            </a>
            {t("scheduleVisitAndSee")}
          </h2>
        </TitleContactInfo>

        <FormContactService>
          <InfoContact>
            <a
              className="header-anchor"
              href="#fillOutTheForm"
              id="fillOutTheForm"
            >
              #
            </a>
            <p>{t("fillOutTheForm")}</p>

            <ColForm>
              <RowForm>
                <img src={footerIcons.mail_main} alt="" />
                <p>cso@conecthus.org.br</p>
              </RowForm>
            </ColForm>

            <ColForm>
              <RowForm>
                <img src={footerIcons.phone_main} alt="" />
                <p>
                  {`+55 (92) 99214.8657 | +55 (92) 99487.6001 | +55 (92) 99523.4420`}
                </p>
              </RowForm>
            </ColForm>

            <img className="imgCard" src={FOTO_Back} alt="" />
          </InfoContact>

          <FormContactUs>
            <h1>{t("scheduleAVisit")}</h1>
            <h3 style={{ textAlign: "center", padding: 20 }}>
              {t("strengthenTheReliability")}
            </h3>

            <Form>
              <TextField
                id="outlined-basic"
                label={t("name")}
                variant="outlined"
                value={formScheduleVisit.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormScheduleVisit((prev: any) => ({
                    ...prev,
                    name: event.target.value,
                  }));
                }}
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={formScheduleVisit.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormScheduleVisit((prev: any) => ({
                    ...prev,
                    email: event.target.value,
                  }));
                }}
                onBlur={(e: any) =>
                  handleEmailInputChange(e, setFormError, "email")
                }
              />
              {formError.errors.email && (
                <HelperText>
                  <a>{t(formError.errors.email)}</a>
                </HelperText>
              )}
              <TextField
                id="outlined-basic"
                label={t("phone")}
                variant="outlined"
                value={formScheduleVisit.phone}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleCellPhoneChange(event, setFormScheduleVisit, "phone");
                }}
              />

              <TextField
                id="outlined-basic"
                label={t("company")}
                variant="outlined"
                value={formScheduleVisit.company}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormScheduleVisit((prev: any) => ({
                    ...prev,
                    company: event.target.value,
                  }));
                }}
                inputProps={{ maxLength: 50 }}
              />

              <TextField
                id="outlined-basic"
                label={t("yourMessage")}
                variant="outlined"
                value={formScheduleVisit.message}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormScheduleVisit((prev: any) => ({
                    ...prev,
                    message: event.target.value,
                  }));
                }}
                multiline
                maxRows={4}
                rows={4}
                inputProps={{ maxLength: 200 }}
              />
              <CheckForm>
                <Checkbox
                  checked={formScheduleVisit.check}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFormScheduleVisit((prev: any) => ({
                      ...prev,
                      check: event.target.checked,
                    }));
                  }}
                  sx={{
                    "&:hover": {
                      height: "100%",
                    },
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <PolicyPricacy>
                  {t("institutoConecthuRequests")}
                  <h3 style={{ display: "inline", color: "#08d040" }}>
                    {" "}
                    {t("privacyPolicy")}
                  </h3>
                </PolicyPricacy>
              </CheckForm>
            </Form>
            <MainBtn
              onClick={handleForm}
              disabled={
                isAnyFieldEmpty(formScheduleVisit) || !formScheduleVisit.check
              }
              label={t("send")}
              variant="contained"
              padding="15px 40px"
            />
          </FormContactUs>
        </FormContactService>
      </ContactService>
    </Layout>
  );
}
