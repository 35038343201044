import React from "react";

import foto1 from "../../../../assets/About/carousel_weAre/1.png";
import foto2 from "../../../../assets/About/carousel_weAre/2.png";
import foto3 from "../../../../assets/About/carousel_weAre/3.png";
import foto4 from "../../../../assets/About/carousel_weAre/4.png";
import foto5 from "../../../../assets/About/carousel_weAre/5.png";
import foto6 from "../../../../assets/About/carousel_weAre/6.png";
import foto7 from "../../../../assets/About/carousel_weAre/7.png";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";

import Carousel from "react-multi-carousel";
function WeAreCarousel() {
  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
  };

  const images = [foto1, foto2, foto3, foto4, foto5, foto6, foto7];

  return (
    <div id="main-slide" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
     
       
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
          //customDot={<CustomDot />}
        >
          {images.slice(0, images.length).map((image, index) => {
            return (
              <div key={index}>
                <img
                  src={image}
                  alt=""
                  style={{
                    height: "468px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
            );
          })}
         
        </Carousel>
      </div>
    </div>
  );
}

export default WeAreCarousel;
