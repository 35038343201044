import Layout from "components/Layout";
import { Faixa } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import background from "../../assets/Academy/background.png";
import capa_cursos from "../../assets/Academy/capa_cursos.jpg";
import course1 from "../../assets/Academy/courses/course1.jpg";
import course2 from "../../assets/Academy/courses/course2.jpg";
import course3 from "../../assets/Academy/courses/course3.jpg";
import course4 from "../../assets/Academy/courses/course4.jpg";
import training1 from "../../assets/Academy/trainings/training1.jpg";
import training2 from "../../assets/Academy/trainings/training2.jpg";
import training3 from "../../assets/Academy/trainings/training3.jpg";
import training4 from "../../assets/Academy/trainings/training4.jpg";
import {
  AcademyHeader,
  BgBack,
  BgContent,
  CardCourse,
  Cards,
  Content,
  ContentForm,
  FormContact,
  GetInContact,
  OurCourses,
  TitleAcademyHeader,
  TitleFormContact,
} from "./styles";
import { ClientsCard, ClientsCardButton, ClientsDiv } from "pages/Home/styles";
import MainBtn from "components/Buttons/MainBtn";
import form_back from "../../assets/Academy/form_back.png";
import { Checkbox, TextField } from "@mui/material";
import { CheckForm, HelperText, PolicyPricacy } from "pages/Areas/styles";
import circle_right_main from "../../assets/About/circle_right_main.svg";
import { useTranslation } from "react-i18next";
import ArrowNarrow from "assets/ArrowNarrow";
import {
  handleCellPhoneChange,
  handleEmailInputChange,
  isAnyFieldEmpty,
} from "utils/formatText";
import { useState } from "react";

function Academy() {
  const { t } = useTranslation();
  const Courses = [
    {
      name: t("SMTTechnologyApplied"),
      img: course1,
    },
    {
      name: "Lean Manufacturing",
      img: course2,
    },
    {
      name: t("leanSeisSigma"),
      img: course3,
    },
    {
      name: t("industry4_0Enabling"),
      img: course4,
    },
  ];
  const Trainings = [
    {
      name: t("developmentOfAgile"),
      img: training1,
    },
    {
      name: t("advancedIndustrialAutomation"),
      img: training2,
    },
    {
      name: t("researchDevelopmentInnocvation"),
      img: training3,
    },
    {
      name: t("trainingDevelopers"),
      img: training4,
    },
  ];

  const [formRegisterContact, setFormRegisterContact] = useState<any>({
    name: "",
    email: "",
    phone: "",
    message: "",
    check: false,
  });
  const [formError, setFormError] = useState({
    errors: {
      email: "",
    },
  });

  const handleForm = () => {
    // console.log("form", formRegisterContact);
    const url = "https://api.conecthus.org.br/mailer/send";
    const params = {
      sender: "Treinamento_Conecthus",
      receiver: "academy@conecthus.org.br",
      subject: "Formulario - Treinamentos",
      message: `<h3>Segue o formulario de contato de ${formRegisterContact.name}</h3>
         <br>nome: ${formRegisterContact.name}</br>
         <p></p>
         <br>Email: ${formRegisterContact.email}</br>
         <p></p>
         <br>Telefone: ${formRegisterContact.phone}</br>
         <p></p>
         <br>Mensagem: ${formRegisterContact.message}</br>
         <p></p>
         
         `,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFormRegisterContact({
          name: "",
          email: "",
          phone: "",
          message: "",
          check: false,
        });
        window.location.href = "#";
        const params = {
          sender: "Treinamento_Conecthus",
          receiver: `${formRegisterContact.email}`,
          subject: "Confirmação de Recebimento - Conecthus",
          message: `<h3>Prezado(a) ${formRegisterContact.name}</h3>
           <br>Agradecemos por entrar em contato conosco através do formulário em nosso website. 
           Este e-mail é para confirmar que recebemos sua mensagem e estamos trabalhando para fornecer 
           a melhor assistência.</b><br></br>
           <br>Nossa equipe está analisando suas informações e faremos o possível para responder em breve.</br>
           <br></br><br>Atenciosamente,</br><br></br>
           <br>Instituto Conecthus</br>`,
        };

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  return (
    <Layout>
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <AcademyHeader background={background}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TitleAcademyHeader>
              <h3>
                <a
                  className="header-anchor"
                  href="#coursesWorkshopsTrainings"
                  id="coursesWorkshopsTrainings"
                >
                  #
                </a>
                {t("coursesWorkshopsTrainings")}
              </h3>
            </TitleAcademyHeader>
          </div>
        </AcademyHeader>
      </div>
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>

      <Content>
        <div style={{ position: "relative" }}>
          <BgContent background={capa_cursos}>
            <BgBack>
              <h1>
                <a
                  className="header-anchor"
                  href="#structureReadyEmpower"
                  id="structureReadyEmpower"
                >
                  #
                </a>
                {t("structureReadyEmpower")}: <h1>{t("yourTeam")}.</h1>
              </h1>
            </BgBack>
          </BgContent>
        </div>
        <h2>
          <a
            className="header-anchor"
            href="#believeThatInnovationBegins"
            id="believeThatInnovationBegins"
          >
            #
          </a>
          {t("believeThatInnovationBegins")}
        </h2>
      </Content>

      <OurCourses>
        <h1>
          <a
            className="header-anchor"
            href="#seeOurCoursesTraining"
            id="seeOurCoursesTraining"
          >
            #
          </a>
          {t("seeOurCoursesTraining")}
        </h1>
        <Cards>
          {Courses.map((item: any) => (
            <CardCourse background={item.img}>
              <h3>{item.name}</h3>
            </CardCourse>
          ))}
        </Cards>
      </OurCourses>
      <ClientsDiv>
        <ClientsCard>
          <h3>{t("readyBoostYourBusiness")}</h3>
          <ClientsCardButton>
            {t("talkToUs")}
            <ArrowNarrow />
          </ClientsCardButton>
        </ClientsCard>
      </ClientsDiv>

      <OurCourses>
        <Cards>
          {Trainings.map((item: any) => (
            <CardCourse background={item.img}>
              <h3>{item.name}</h3>
            </CardCourse>
          ))}
          <p>
            <a
              className="header-anchor"
              href="#WesternAmazon"
              id="WesternAmazon"
            >
              #
            </a>
            {t("WesternAmazon")}
          </p>
          <p> {t("emphasisOnAI")}</p>
        </Cards>
      </OurCourses>
      <GetInContact background={form_back}>
        <TitleFormContact>
          <img src={circle_right_main} alt="" height={36} />
          <h1>
          <a
              className="header-anchor"
              href="#letTurnIdeaIntoReality"
              id="letTurnIdeaIntoReality"
            >
              #
            </a>
            {t("letTurnIdeaIntoReality")}</h1>
        </TitleFormContact>
        <ContentForm>
          <h1 style={{ textAlign: "center", padding: 20 }}>
          <a
              className="header-anchor"
              href="#getTouchAndBook"
              id="getTouchAndBook"
            >
              #
            </a>
            {t("getTouchAndBook")}
          </h1>
          <h3>
          <a
              className="header-anchor"
              href="#connectToTheFutureAcademy"
              id="connectToTheFutureAcademy"
            >
              #
            </a>
            {t("connectToTheFutureAcademy")}</h3>

          <FormContact>
            <TextField
              id="outlined-basic"
              label={t("name")}
              variant="outlined"
              value={formRegisterContact.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormRegisterContact((prev: any) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              placeholder="Email pessoal"
              variant="outlined"
              value={formRegisterContact.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormRegisterContact((prev: any) => ({
                  ...prev,
                  email: event.target.value,
                }));
              }}
              onBlur={(e: any) =>
                handleEmailInputChange(e, setFormError, "email")
              }
              inputProps={{ maxLength: 100 }}
            />
            {formError.errors.email && (
              <HelperText>
                <a>{t(formError.errors.email)}</a>
              </HelperText>
            )}
            <TextField
              id="outlined-basic"
              label={t("phone")}
              variant="outlined"
              value={formRegisterContact.phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleCellPhoneChange(event, setFormRegisterContact, "phone");
              }}
              inputProps={{ maxLength: 12 }}
            />

            <TextField
              id="outlined-basic"
              label={t("yourMessage")}
              variant="outlined"
              placeholder={t("whatIsThePurpose")}
              value={formRegisterContact.message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormRegisterContact((prev: any) => ({
                  ...prev,
                  message: event.target.value,
                }));
              }}
              multiline
              maxRows={4}
              rows={4}
              inputProps={{ maxLength: 200 }}
            />

            <CheckForm>
              <Checkbox
                checked={formRegisterContact.check}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormRegisterContact((prev: any) => ({
                    ...prev,
                    check: event.target.checked,
                  }));
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <PolicyPricacy>{t("institutoConecthuRequests")}</PolicyPricacy>
            </CheckForm>
          </FormContact>
          <MainBtn
            onClick={handleForm}
            disabled={
              isAnyFieldEmpty(formRegisterContact) || !formRegisterContact.check
            }
            label={t("send")}
            variant="contained"
            padding="15px 40px"
          />
        </ContentForm>
      </GetInContact>
    </Layout>
  );
}

export default Academy;
