import styled from "styled-components";

export const ConecthusNews = styled.div`
  padding: 4% 12% 4% 12%;
  width: 100%;
  background-color: #ffffff;
  @media (max-width: 900px) {
    padding: 32px 20px;
  }
`;
export const Newsheader = styled.div`
  display: grid;
  grid-template-columns: auto auto; /* Duas colunas */
  column-gap: 25%;
  padding-bottom: 75px;
  .first-column {
    grid-row: span 2; /* Ocupa duas linhas */
  }
  h3 {
    font-size: 20px;
    font-weight: 400;
    //margin: 0;
  }
  @media (max-width: 900px) {
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
    gap: 32px;
    padding-bottom: 32px;
  }
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 600;
  }
  @media (max-width: 900px) {
    //flex-direction: column;
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }
  }
`;

export const NewsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 30px;
  @media (max-width: 900px) {
    grid-template-columns: auto;
  }
`;
export const NewsCard = styled.div<any>`
  width: 100%;
  height: 405px;
  background-image: url(${({ background }) => background});
  background-size: cover;
  border-bottom: 100px solid white;
  position: relative;
  box-shadow: 0px 38px 50px 0px #00000026;
  border-radius: 10px;

  h3 {
    font-size: 16px;
    font-weight: 400;
    position: absolute;
    top: 100%;
    color: #000000;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 12px;
  }
`;
export const More = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 29px;
`;
export const SeeMoreButton = styled.div`
  width: 200px;
  height: 48px;
  padding: 0px 50px 0px 50px;
  gap: 10px;
  border-radius: 25px;
  border: 1px solid #000000;
  opacity: 0px;
  color: #000000;
  text-align: center;
  align-content: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
`;

export const FormInfoTitle = styled.div`
  width: 460px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
`;
export const CommunicationHeader = styled.div<any>`
  width: 100%;
  height: 85vh;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  background-attachment: local;
  display: flex;
  overflow: visible;
  text-align: start;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 900px) {
    background-size: 287%;
    background-position: 19% 31%;
    height: 400px;
    /* padding: 20px 20px; */
    text-align: center;
  }
`;

export const TitleContainerComm = styled.div`
  position: absolute;
  width: 100%;
  top: auto;
  left: auto;
  right: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 12%;
  bottom: 25%;
  h3 {
    color: #ffffff;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    margin: 0;
  }
  @media (max-width: 900px) {
    // padding: 40px 30px;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: inherit;
    right: inherit;
    top: inherit;
    text-align: center;
    align-items: center;
    bottom: 0;
    padding-left: 0;
    h3 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      margin: 0;
    }
  }
`;
