import styled from "styled-components";
import imgHome from "../../assets/Home/IMG_BACKGROUND.png";
import MOSAICO from "../../assets/Home/MOSAICO_CONECTHUS.png";
import escritorio from "../../assets/Home/escritorio.png";
import workwUs from "../../assets/Home/workwUs.png";
export const Container = styled.div`
  background-color: #1f1f24;
`;

export const HomeScreen = styled.div`
  width: 100%;
  height: 85vh;
  background-image: url(${imgHome});
  background-attachment: local;
  background-size: cover;
  // opacity: 0.8;
  display: grid;
  overflow: visible;
  text-align: center;
  position: relative;
  @media (max-width: 1050px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;
export const Mosaico = styled.div`
  background-image: url(${MOSAICO});
  width: 100%;
  height: 100%;
  background-size: cover;
`;
export const Title2 = styled.text`
  //background-color: red;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 4px;
  display: flex;
  justify-content: center;
  align-items: end;
  align-content: end;
`;
export const HomeTitle = styled.text`
  position: absolute;
  top: 20%;
  /* left: 8%; */

  text-align: start;
  padding: 0px 12%;
  h1 {
    /// font-family: "Barlow", sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    width: 530px;
  }
  h2 {
    font-size: 20px;
    line-height: 30px;
    width: 360px;
  }
  @media (max-height: 630px) {
    h1 {
      font-size: 30px;
    }
  }
  @media (max-width: 900px) {
    padding-left: 20px;
    top: none;
    h1 {
      width: 270px;
    }
    h2 {
      width: 270px;
    }
  }
`;

export const BallIcon = styled.img`
  width: 43px;
  height: 43px;
  float: left;
  margin-left: 1000px;
  margin-top: 350px;

  @media (max-width: 1050px) {
    position: absolute;
    margin: 0;
    bottom: 30px;
  }
`;

export const AboutDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 62px 12% 10px 12%;
  //margin-bottom: 200px;
  color: #1f1f24;
  flex-direction: column;
  /* @media (max-width: 1196px) {
  } */
  @media (max-width: 900px) {
    padding: 16px 20px;
  }
`;
export const SelosLogos = styled.div`
  padding-top: 20px;
  padding-bottom: 60px;
  @media (max-width: 800px) {
    display: none;
  }
`;
export const ArticleDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5%;
  padding-bottom: 4%;
  align-items: center;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const ArticleContent = styled.div`
  color: #1a202c;
  //width: 50%;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 57.6px;
  }
  @media (max-width: 1196px) {
    width: 80%;
    margin-bottom: 50px;
  }

  @media (max-width: 616px) {
    width: 100%;
  }
`;
export const AreaActivity = styled.div`
  width: 100%;
  height: 100%;
  background-color: #02bc4d;
  position: relative;
  z-index: 99;
  padding: 20px 12% 100px 12%;
  @media (max-width: 900px) {
    padding: 20px 0px 20px 20px;
  }
`;
export const CardsArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(280px, 1fr)); /* Quatro colunas */
  grid-template-rows: repeat(2, minmax(452px, 1fr)); /* Duas linhas */
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  padding-top: 92px;
  justify-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: row;
    overflow-x: auto; /* Alterado para 'auto' para mostrar a barra de rolagem quando necessário */
  }
`;
export const AreaTitle = styled.div`
  h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: 600;
  }
`;
export const AreaCard = styled.div`
  height: 451px;
  width: 280px;
  border-radius: 16px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  box-shadow: 0px 1px 3px -1px #00000099;
  transition: transform 0.3s ease; /* Adiciona uma transição suave */
  &:hover {
    transform: scale(1.02); /* Aumenta o tamanho em 2% ao passar o mouse */
    box-shadow: 0px 38.27px 50.35px 0px #00000026;
  }
  h1 {
    font: normal normal 600 30px/38px Barlow;
  }
  h4 {
    margin: 0;
    font: normal normal 400 16px/24px Barlow;
  }
  .title {
    display: flex;
    flex-wrap: nowrap;
    height: 135px;
    justify-content: space-between;
  }
  img {
    height: 50px;
    width: 50px;
  }
`;

export const Chips = styled.div`
  width: auto;
  //height: 24px;
  background-color: #08d001;
  border-radius: 8px;
  color: #ffffff;
  padding: 0px 7px 0px 7px;
`;
export const LabsDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 20px 12% 100px 12%;
  h2 {
    text-align: center;
    letter-spacing: 0.6%;
    padding: 27px 0px 27px 0px;
    font-size: 2vh;
  }
  h3 {
    letter-spacing: 0.6%;
    padding: 0px 12% 0px 12%;
    font-size: 20px;
    font-weight: 400;
  }
  // padding-bottom: 300px;

  @media (max-width: 1200px) {
    padding: 20px 20px 50px 20px;
  }
`;
export const FxLeft = styled.div`
  position: absolute;
  left: -24%;
  top: 0;
  z-index: 0;
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const FxRight = styled.div`
  position: absolute;
  right: -17%;
  top: 0;
  z-index: 0;
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const BoxVideos = styled.div`
  padding: 0px 12% 100px 12%;
  display: flex;
  flex-direction: column;
  h2 {
    font: normal normal 600 30px/32px Barlow;
    letter-spacing: 0.6%;
  }
  @media (max-width: 1200px) {
    padding: 0px 20px 50px 20px;
  }
`;
export const LabsTitle = styled.div`
  color: #1f1f24;
  h1 {
    font: normal normal 600 48px/57px Barlow;
  }
  h4 {
    font: normal normal normal 20px/28px Barlow;
    letter-spacing: 0.2%;
  }
`;
export const LabsWrapperBoxVideo = styled.div`
  // width: 40%;
  padding-top: 36px;
  @media (max-width: 1196px) {
    width: 100%;
    //height: 244px;
  }
`;
export const BulletsDiv = styled.div`
  border-width: 7px 7px 0px;
  border-style: solid;
  border-color: black;
  border-image: initial;
  border-radius: 19px 19px 0px 0px;
  width: 28%;
  height: 65px;
  //padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    height: 45px;
    border-width: 4px 4px 0px;
  }
`;

export const SocialIconDiv = styled.div`
  width: 100px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1196px) {
    flex-direction: row;
    align-items: center;
    width: 250px;
    height: 50px;
    margin-bottom: 20px;
  }
`;

export const ClientsDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: #1a202c;
  padding: 100px 12% 100px 12%;
`;
export const ClientsTitle = styled.text`
  text-align: center;
  font: normal normal 600 24px/38px Barlow;
  letter-spacing: 1.01px;
  color: rgba(255, 255, 255, 1);
  margin-top: 113px;
  margin-bottom: 42px;
  opacity: 1;
`;
export const Escritorio = styled.div`
  height: 400px;
  width: 100%;
  background-image: url(${escritorio});
  background-size: cover;
`;
export const ClientsCard = styled.div`
  color: #08d040;
  border-radius: 16px;
  padding: 48px 62px 48px 62px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 2px solid white;
  h3 {
    // width: 50%;
    margin: 0;
    font: normal normal 600 30px/38px Barlow;
  }
  @media (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
    padding: 48px 26px;
    gap: 30px;
    h3 {
      width: 100%;
      font-size: 33px;
      font-style: normal;
      font-weight: 500;
      line-height: 37px; /* 120% */
    }
  }
`;
export const ClientsCardButton = styled.div`
  width: 234px;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid white;
  color: #08d040;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  transition: color 0.3s ease;
  &:hover {
    background-color: #08d040;
    color: black;
    border: 1px solid #08d040;
  }
`;
export const PartnersDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 32px 12% 0px 12%;
  text-align: center;
  h1 {
    // text-align: justify;
    font: normal normal 600 30px/38px Barlow;
  }
  h2 {
    font-size: 50px;
    font-weight: bold;
    color: #cccccc;
    text-align: center;
  }
  @media (max-width: 900px) {
    padding: 32px 30px;
  }
`;
export const DividerPartiner = styled.div`
  background-color: #08d040;
  width: 100%;
  height: 6px;
  border-radius: 8px;
`;
export const PartinersItems = styled.div`
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  width: 100%;
  .item {
    border: 1px solid black;
    border-radius: 32px;
    width: 150px;
    height: 150px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 95px;
      height: 95px;
    }
  }
  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Quatro colunas */
    column-gap: 5px;
    row-gap: 10px;
    .item {
      width: 100%; /* Ocupa toda a largura da coluna */
      height: 80px;
      //aspect-ratio: 1 / 1; /* Mantém a proporção 1:1 dos itens */
      display: grid;
      flex-shrink: 0;
      border-radius: 16px;
    }

    .item img {
      width: 100%;
      height: 100%;
      object-fit: contain; /* Para garantir que a imagem cubra todo o espaço */
    }
  }
`;
export const CarouselDiv = styled.div`
  width: 100%;
  height: 339px;
  display: flex;
  align-items: center;
  background: #1f1f24 linear-gradient(230deg, #13131408 0%, #808080 100%) 0% 0%
    no-repeat padding-box;
  padding: 100px;
`;

export const OurDifferences = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 20px 12% 100px 12%;
  h1 {
    font: normal normal 600 48px/57px Barlow;
  }
  @media (max-width: 900px) {
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      // text-align: center;
      padding: 20px 0px;
    }
  }
`;
export const CardsOurDiff = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* Quatro colunas */
  grid-template-rows: repeat(2, minmax(0, 1fr)); /* Duas linhas */
  width: 100%;

  margin-top: 20px;
  @media (max-width: 1400px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
export const CardOurDiff = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font: normal normal 600 30px/38px Barlow;

    height: 100px;
  }
  .title {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
  h4 {
    margin: 0;
    font: normal normal 400 18px/27px Barlow;
  }
  img {
    height: 50px;
    width: 50px;
  }
  @media (max-width: 900px) {
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      margin: 0;
      margin-bottom: 13px;
    }
  }
`;
export const LastCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 420px;
  justify-content: center;
  text-wrap: balance;
  h1 {
    margin: 0;
    font: normal normal 600 30px/38px Barlow;
  }
`;
export const WorkUsDiv = styled.div`
  height: 37vh;
  width: 100%;
  background-image: url(${workwUs});
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const WorkUsDivOpacity = styled.div`
  width: 100%;
  height: 343px;
  opacity: 0.47;
`;

export const WorkUsTitle = styled.text`
  text-align: left;
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  h2 {
    margin: 0;
    font: normal normal 500 45px/57px Barlow;
    letter-spacing: 0.2px;
    color: #08d040;
  }

  @media (max-width: 800px) {
    margin-right: 0px;
    margin-bottom: 20px;
    h2 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const HomeButton = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  background-color: #02bc4d;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  width: 255px;
  width: 255px;
  justify-content: center;
  height: 42px;

  &:hover {
    background-color: #08d040;
    gap: 31px;
    img {
      height: 20px;
      width: 20px;
    }
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const OurLabsTitle = styled.div`
  border-width: 7px 7px 0px;
  border-style: solid;
  border-color: black;
  border-image: initial;
  border-radius: 19px 19px 0px 0px;
  height: 59px;
  width: 64%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;
  background-color: white;
  flex-shrink: 0;
  h1 {
    font-size: 30px;
  }

  @media (max-width: 500px) {
    height: 42px;
    border-width: 4px 4px 0px;
    h1 {
      font-size: 10px;
      line-height: 20px;
    }
  }
`;
