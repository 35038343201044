import React from 'react';
import { Routes } from './routes';
import GlobalStyles from './style/GlobalStyles';

const App = () => {
  return (
    <>
      <GlobalStyles/>
      <Routes />
    </>
  );
}

export default App;
