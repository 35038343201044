import styled from "styled-components";

export const ParallaxContainer = styled.div<any>`
  width: 100%;
  height: 85vh;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  // opacity: 40%;
  background-attachment: local;
  display: flex;
  overflow: visible;
  text-align: start;
  position: relative;
  justify-content: flex-end;
  padding: 23% 12% 0px 0px;

  @media (max-width: 1050px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 900px) {
    background-size: 230%;
    background-position: 16% 23%;
    height: 400px;
    padding: 20px 20px;
    text-align: center;
  }
`;

export const Title = styled.div<any>`
  position: absolute;
  //background-color: red;
  width: 35%;
  top: ${({ top }) => (top ? "5%" : "30%")};
  right: 10%;
  left: ${({ top }) => (top ? "12%" : "")};

  h3 {
    color: ${({ bottom }) => (bottom ? "#373936" : "white")};
    font-size: 48px;
    font-weight: 600;
    line-height: 57px;
  }
  @media (max-width: 900px) {
    padding: 20px 20px;
    text-align: center;
    width: 100%;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 145px;
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    img {
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      // margin: 30px;
    }
  }
`;

export const TitleBottom = styled.div<any>`
  position: absolute;
  /* width: 35%; */
  top: 25%;
  /* right: 10%; */
  /* text-align: center; */
  display: flex;
  width: ${({ left }) => (left ? "35%" : "100%")};
  height: 45%;
  justify-content: ${({ left }) => (left ? "flex-start" : "center")};
  align-items: flex-end;
  padding-left: ${({ left }) => (left ? "12%" : "0px")};
  h3 {
    color: ${({ left }) => (left ? "white" : "#373936")};
    font-size: 48px;
    font-weight: 600;
    line-height: 57px;
    margin: 0;
  }
  @media (max-width: 1200px) {
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;
export const TitleLeftBottom = styled.div<any>`
  position: absolute;
  top: 43%;
  display: flex;
  width: 56%;
  height: 45%;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 12%;
  h3 {
    color: white;
    font-size: 48px;
    font-weight: 600;
    line-height: 57px;
  }
`;
export const TitleRightBottom = styled.div<any>`
  position: absolute;
  top: 44%;
  right: 10%;
  display: flex;
  width: 550px;
  //background-color: red;

  justify-content: flex-start;

  h3 {
    color: white;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 120% */
  }
  @media (max-width: 1000px) {
    width: auto;
    right: 0;
    margin: 12%;
    h3 {
      font-size: 28px;
      line-height: normal;
    }
  }
  @media (max-width: 900px) {
    top: auto;
    bottom: 0;
    margin: 12% 25%;
    h3 {
      text-align: center;
      margin: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;
