import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

interface ContainerInterface {
  isToggle: boolean;
}

export const Container = styled.div<ContainerInterface>`
  width: 100%;
  height: 85px;
  background: #ffffff;
  color: #212121;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12% 0px 12%;
  position: sticky;
  top: -1px;
  z-index: 999;
  gap: 22px;
  @media (max-width: 1080px) {
    justify-content: space-between;
    //padding-right: 31px;
  }

@media (max-width: 900px) {
  padding:0px 20px;
}
`;

export const Logo = styled.img<any>`
  width: 156px;
  @media (max-width: 900px) {
    
    display: ${({ isInputEnabled }) => (isInputEnabled ? "none" : "")};
  }
  /* @media(max-width: 1080px) {
        width: 200px;
    } */
`;

export const WrapperHamburgerIcon = styled.div`
  // display: block;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  padding: 10px 8px;
  background-color: #333c50;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  @media (min-width: 821px) {
    display: none;
  }
`;

export const CloseStyledIcon = styled(CloseIcon)`
  &&.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
  color: #ffffff;
`;

export const HamburguerIcon = styled(MenuIcon)`
  &&.MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
  color: #ffffff;
`;

export const Menu = styled.ul`
  color: #212121;
  list-style-type: none;

  display: flex;
  gap: 22px;
  row-gap: 1px;
  flex-wrap: wrap;
  @media (max-width: 821px) {
    display: none;
  }
`;

export const MenuItem = styled.li`
  display: inline-block;
  /* padding: 2px 10px;
  // margin-bottom: 10px;
  position: relative; */
  text-wrap: nowrap;
  color: #212121;
`;

export const MenuLink = styled(Link)<any>`
  text-decoration: none;
  color: #212121; /* Cor inicial */
  color: ${(props) => (props.target ? " #00CB2D" : "#212121")};
  transition: color 0.3s ease-in; /* Transição de cor suave */
  //opacity: 85%;
  letter-spacing: 0.98px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;

  /* 
  ${({ target }) =>
    target &&
    css`
      opacity: 100%;

      :before {
        content: "";
        position: absolute;
        left: 10px;
        bottom: -3px;
        width: 0;
        border-bottom: 2px solid #00cb2d;
        border-radius: 10px;
        animation: border_animation 0.3s linear forwards;
      }
    `} */

  :hover {
    opacity: 100%;
    color: #00cb2d;

    /* ${({ target }) =>
      !target &&
      css`
        :before {
          content: "";
          position: absolute;
          left: 10px;
          bottom: -3px;
          width: 0;
          border-bottom: 2px solid #00cb2d;
          border-radius: 10px;
          animation: border_animation 0.3s linear forwards;
        }
      `} */
  }
  @keyframes border_animation {
    0% {
      width: 0%;
    }
    100% {
      width: 40%;
    }
  }

  @media (max-width: 1080px) {
    font-size: 12px;
  }

  @media (max-width: 889px) {
    font-size: 10px;
  }
`;
