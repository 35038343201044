import map_pin from "../assets/Footer/map_pin.svg";
import phone from "../assets/Footer/phone.svg";
import phone_main from "../assets/Footer/phone_main.svg";
import mail from "../assets/Footer/mail.svg";
import mail_main from "../assets/Footer/mail_main.svg";
import mackenzie from "../assets/About/LOGOS_PARCEIROS/Mackenzie.svg";
import uea from "../assets/About/LOGOS_PARCEIROS/uea.svg";
import unniversity from "../assets/About/LOGOS_PARCEIROS/unniversity.svg";
import cba from "../assets/About/LOGOS_PARCEIROS/CBA.svg";
import ufam from "../assets/About/LOGOS_PARCEIROS/UFAM.svg";
import idesam from "../assets/About/LOGOS_PARCEIROS/idesam.svg";
import cits from "../assets/About/LOGOS_PARCEIROS/CITS.svg";
import uninorte from "../assets/About/LOGOS_PARCEIROS/Uninorte.svg";
import senai from "../assets/About/LOGOS_PARCEIROS/instituto_senai.svg";
import softex from "../assets/About/LOGOS_PARCEIROS/Softex.svg";
import nepem from "../assets/About/LOGOS_PARCEIROS/NEPEM.svg";
import suframa from "../assets/About/LOGOS_PARCEIROS/SUframa.svg";
import sinaes from "../assets/About/LOGOS_PARCEIROS/SINAEES.svg";
import fucapi from "../assets/About/LOGOS_PARCEIROS/FUCAPI.svg";
import ppbio from "../assets/About/LOGOS_PARCEIROS/PPBIO.svg";
import pp41 from "../assets/About/LOGOS_PARCEIROS/PPI_40.svg";
import cide from "../assets/About/LOGOS_PARCEIROS/CIDE.svg";
import fipfieam from "../assets/About/LOGOS_PARCEIROS/FIPFIEAM.svg";
import instagram from "../assets/Footer/Instagram.svg";
import linkedin from "../assets/Footer/Linkedin.svg";
import youtube from "../assets/Footer/Youtube.svg";
import facebook from "../assets/Footer/Facebook.svg";
export const social = {
  linkedin: "https://www.linkedin.com/company/institutoconecthus",
  instagram: "https://www.instagram.com/institutoconecthus/",
  youtube: "https://www.youtube.com/channel/UCXZDcWK49fhnrvLPeRT-c7g",
  facebook: "https://www.facebook.com/institutoconecthus",
  wpp: "https://wa.me/5592995234420?text=Gostaria%20de%20saber%20mais%20sobre%20o%20Instituto%20Conecthus",
};

export const pdfs = {
  lab_software: "documents/labs/LAB_SOFTWARE.pdf",
  lab_hardware: "documents/labs/LAB_HARDWARE.pdf",
  lab_biotecnologia: "documents/labs/LAB_BIOTECNOLOGIA.pdf",
  lab_confiabilidade: "documents/labs/LAB_CONFIABILIDADE.pdf",
  lab_eng_mecanica: "documents/labs/LAB_ENG_MECANICA.pdf",
  lab_quimica: "documents/labs/LAB_QUIMICA.pdf",
  lab_smt: "documents/labs/LAB_SMT.pdf",
};
export const videos = {
  lab_software: "https://www.youtube.com/embed/6iEEnu5gbXg",
  lab_smt: "https://www.youtube.com/embed/2bS2udngZhE",
  lab_quimica: "https://www.youtube.com/embed/1V6cj8uxOTE",
  lab_hardware: "https://www.youtube.com/embed/7Sds8M8SSUk",
  lab_biotecnologia: "https://www.youtube.com/embed/j65w57gkOQc",
  lab_eng_mecanica: "https://www.youtube.com/embed/df7YpDbmG7g",
  lab_confiabilidade: "https://www.youtube.com/embed/QOOfIRxNkL8",
  lab_todos: "https://www.youtube.com/watch?v=m4nu8PmY-5E",
  apresentacao_marca: "https://www.youtube.com/embed/ZZpCTP6iPEE",
  video_tour: "https://www.youtube.com/watch?v=oxJS7Eqny5Q&t=60s",
};
export const footerIcons = {
  pin: map_pin,
  phone: phone,
  phone_main: phone_main,
  mail: mail,
  mail_main: mail_main,
};

export const socialIcons={
  insta:instagram,
  linkedin:linkedin,
  youtube:youtube,
  facebook:facebook
}

export const CaurrousePartinerslIcon = [
  {
    icon: mackenzie,
    name: "mackenzie",
  },
  {
    icon: uea,
    name: "uea",
  },
  {
    icon: unniversity,
    name: "unniversity",
  },

  {
    icon: cba,
    name: "cba",
  },
  {
    icon: ufam,
    name: "ufam",
  },
  {
    icon: idesam,
    name: "idesam",
  },
  {
    icon: cits,
    name: "cits",
  },
  {
    icon: uninorte,
    name: "uninorte",
  },
  {
    icon: senai,
    name: "senai",
  },
  {
    icon: softex,
    name: "softex",
  },
  {
    icon: nepem,
    name: "nepem",
  },
  {
    icon: suframa,
    name: "suframa",
  },
  {
    icon: sinaes,
    name: "sinaes",
  },
  {
    icon: fucapi,
    name: "fucapi",
  },
  {
    icon: ppbio,
    name: "ppbio",
  },
  {
    icon: pp41,
    name: "pp41",
  },
  {
    icon: cide,
    name: "cide",
  },
  {
    icon: fipfieam,
    name: "fipfieam",
  },
];
