import { useEffect, useRef, useState } from "react";
import { Content, CardContent, ItemContent } from "./styles";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import ptBRJson from "../../i18n/translations/pt-BR.json";
import { useHistory, useLocation } from "react-router-dom";
import routesMap from "utils/routesMap";
function InputSearch({isInputEnabled, setIsInputEnabled}:any) {
  const [inputValue, setInputValue] = useState("");
 
  const [searchResults, setSearchResults] = useState<any>([]);
  const inputRef = useRef(null) as any;
  const handleIconClick = () => {
    setIsInputEnabled(!isInputEnabled);
  };
  const location = useLocation();
  const { hash } = location;
  const handleClickOutside = (event: any) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsInputEnabled(false);
      // setInputValue("")
    }
   // console.log("event.target", hash)
    if(searchResults.length ===0){
      //console.log("vazioo")
      setIsInputEnabled(false);
      //setInputValue("")
    }
  };
  useEffect(() => {
    // if(inputValue.length === 0){

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    // }
  }, []);
  
  useEffect(() => {
    if (inputValue.length > 0) {
      const searchKeyword = inputValue.toLowerCase();

      const results = Object.entries(ptBRJson.translation)
        .filter(([key, value]) => value.toLowerCase().includes(searchKeyword))
        .map(([key, value]) => {
          const [anchor, route] = routesMap[key] || [];
          return { key, value, anchor, route };
        })
        .filter(result => result.route && result.anchor);  // Filtra entradas sem rota ou âncora

      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, [inputValue]);
  //console.log("i18n", ptBRJson.translation)
  const history = useHistory();
 

  const handleResultClick = (key: any) => {
    const route = routesMap[key];
    if (route) {
      console.log("route", route);
      history.push({
        pathname: route[1],
        hash: route[0],
      });
     
      setSearchResults([]);
      setInputValue("");
    }
  };
  useEffect(() => {
    if (hash) {
      const scrollToAnchor = () => {
        const anchor = document.querySelector(hash);
        if (anchor) {
          anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      };
      scrollToAnchor();
    }
  }, [location]);

  const splitText = (text: string, limit: number) => {
    const words = text.split(" ");
    const title = words.slice(0, limit).join(" ");
    const subtitle = words.slice(limit, limit * 2).join(" ");
    return { title, subtitle };
  };
  // const handleBlur = () => {
  //   if(inputValue.length > 0){

  //     setIsInputEnabled(true);
  //   }
  //   else{
  //     setIsInputEnabled(false);
  //   }
  // };
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Content ref={inputRef}>
        <SearchIcon onClick={handleIconClick} />{" "}
        <input
          className={searchResults.length >0 || isInputEnabled ? "filled" : ""}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={()=>setIsInputEnabled(true)}
        //  onBlur={handleBlur}
        />
      </Content>
      {searchResults.length > 0 && (
        <CardContent>
          {searchResults.map((result: any, index: any) => {
            const { title, subtitle } = splitText(result.value, 5);
            return (
              <ItemContent
                key={index}
                onClick={() => handleResultClick(result.key)}
              >
                <div className="title-content">{title}</div>
                <div className="subtitle-content">{subtitle}</div>
              </ItemContent>
            );
          })}
        </CardContent>
      )}
    </div>
  );
}

export default InputSearch;
