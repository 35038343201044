import styled from "styled-components";

interface BackgroundLabInterface {
  background: string;
  isPlay?: boolean;
}

interface IFrameInterface {
  isPlay: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 23px;
  overflow: hidden;

  @media (max-width: 1056px) {
    padding: 20px;
  }
`;

export const ContainerLabInfo = styled.div<BackgroundLabInterface>`
  width: 100%;
  height: auto;
  background-color: #333c50;
  padding: 60px 12% 60px 12%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  @media (max-width: 900px) {
    gap: 32px;
    padding: 40px 20px;
    //height: auto;
  }
`;

export const TitleLabInfo = styled.a`
  font-size: 48px;
  font-weight: 600;
  color: #08d040;
  margin: 0;
  @media (max-width: 900px) {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
  }
`;

export const ParagraphLabInfo = styled.p`
  letter-spacing: 0.2%;
  line-height: 28px;
  font-size: 20px;
  color: #ffffff;
  margin: 0;
  @media (max-width: 900px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const LabsTest = styled.div<any>`
  background-color: #222835;
  padding: 55px 12% 2% 12%;
  h2 {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
  }
  h4 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    padding-top: 32px;
    padding-bottom: 40px;
  }
  @media (max-width: 900px) {
    padding: 40px 20px;
    h2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;

export const LabsContent = styled.div`
  background-color: #f5f5f5;
  padding: 2% 12% 5% 12%;
  width: 100%;
  height: auto;
  @media (max-width: 900px) {
    padding: 40px;
  }
`;

export const CardContent = styled.div<any>`
  padding-top: 50px;

  display: flex;
  flex-direction: row;
  gap: 6%;
  flex-direction: ${({ left }) => (left ? "row-reverse" : "row")};
  align-items: center;
  img {
    border-radius: 10px;
    box-shadow: 0px 1px 4px #00000029;
    height: 300px;
    width: 50%;
  }
  @media (max-width: 900px) {
    gap: 32px;
    flex-direction: column;
    img {
      width: 100%;
    }
  }
`;
export const TextCard = styled.div`
  display: flex;
  flex-direction: column;
  color: #000000;
  h2 {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.6%;
  }
  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  @media (max-width: 900px) {
    gap: 16px;
    h2 {
      text-align: center;
    }
  }
`;
export const LabImg = styled.div<any>`
  background-image: url(${({ background }) => background});
  background-size: cover;
  width: 100%;
  height: 536px;

  @media (max-width: 900px) {
    background-position: 150%;
  }
`;

export const ContactService = styled.div<any>`
  //height: 900px;
  width: 100%;
  padding: 5% 12% 2% 12%;
  background-image: url(${({ background }) => background});
  background-color: #222835;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media (max-width: 1200px) {
    padding: 40px 20px;
  }
`;
export const FormContactService = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //background-color: red;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 65px;
  @media (max-width: 1200px) {
    margin-top: 32px;
    flex-direction: column;
  }
`;
export const InfoContact = styled.div`
  color: #ffffff;
  padding: 0px 0px 5% 0px;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  .imgCard {
    border-radius: 10px;
    width: 90%;
    margin-top: 60px;
  }
  @media (max-width: 1200px) {
    .imgCard {
      margin-top: 32px;
      width: 100%;
    }
  }
`;

export const WrapperBoxVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //padding: 20px;
  border: 30px solid black;
  border-radius: 10px;
  border-top-left-radius: 0px;

  @media (max-width: 1196px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    border: 10px solid black;
  }
`;

export const PlayIconVideo = styled.img`
  width: 82px;
  height: 82px;

  @media (max-width: 822px) {
    width: 60px;
    height: 60px;
  }
`;

export const BoxVideoLab = styled.div<BackgroundLabInterface>`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ background }) => background});
  background-size: 100% 100%;
  background-color: black;
  //opacity: 0.7;
  transition: 0.3s all;
  cursor: pointer;
  display: ${({ isPlay }) => (!isPlay ? "" : "none")};

  :hover {
    opacity: 1;
    ${PlayIconVideo} {
      transform: scale(1.2);
    }
  }

  @media (max-width: 1196px) {
    width: 100%;
    height: 244px;
  }
`;

export const ContainerAnchorBtn = styled.div`
  float: right;
  margin-top: 73px;
  margin-right: 152px;
  margin-bottom: 93px;
`;

export const IFrame = styled.iframe<any>`
  width: 100%;
  height: 500px;

  @media (max-width: 1000px) {
    height: 300px;
  }
`;

export const TitleContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  //width: 45%;
  //height: 45%;
  justify-content: flex-start;

  align-items: flex-start;
  padding-left: 12%;
  flex-direction: column;
  h3 {
    color: white;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  @media (max-width: 900px) {
    h3 {
      text-align: center;
      margin: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;
