import Layout from "components/Layout";
import { Faixa } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import background from "../../assets/Communication/background.jpg";
import {
  CommunicationHeader,
  ConecthusNews,
  More,
  NewsCard,
  NewsContent,
  Newsheader,
  RowHeader,
  SeeMoreButton,
  TitleContainerComm,
} from "./styles";
import { social, socialIcons } from "utils/links";
import { CardInfoTitle } from "components/Footer/styles";
import news1 from "../../assets/Communication/news/news1.png";
import news2 from "../../assets/Communication/news/news2.png";
import news3 from "../../assets/Communication/news/news3.png";
import news4 from "../../assets/Communication/news/news4.jpg";
import news5 from "../../assets/Communication/news/news5.png";
import news6 from "../../assets/Communication/news/news6.png";
import { WorkUsDiv, WorkUsTitle } from "pages/Home/styles";
import MainBtn from "components/Buttons/MainBtn";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function Communication() {
  const history = useHistory();
  const { t } = useTranslation();
  const news = [
    {
      title: t("lookWhatTheConecthus"),
      img: news1,
    },
    {
      title: t("weParticipatedinExpo"),
      img: news2,
    },
    {
      title: t("institutoConecthusParticipates"),
      img: news3,
    },
    {
      title: t("CoffeeWithPresident"),
      img: news4,
    },
    {
      title: t("todayMay4th"),
      img: news5,
    },
    {
      title: t("institutoConecthusPresents"),
      img: news6,
    },
  ];
  const handleSeeMoreClick = () => {
    window.open("https://www.instagram.com/institutoconecthus/", "_blank");
  };
  return (
    <Layout>
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <CommunicationHeader background={background}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TitleContainerComm>
              <h3>
                <a
                  className="header-anchor"
                  href="#stayConnected"
                  id="stayConnected"
                >
                  #
                </a>
                {t("stayConnected")}
              </h3>
              <h3>
                <a className="header-anchor" href="#withOur" id="withOur">
                  #
                </a>
                {t("withOur")}{" "}
              </h3>
              <h3>
                <a className="header-anchor" href="#news" id="news">
                  #
                </a>
                {t("news")}
              </h3>
            </TitleContainerComm>
          </div>
        </CommunicationHeader>
      </div>
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>

      <ConecthusNews>
        <Newsheader>
          <RowHeader className="item first-column">
            <h1>Conecthus</h1> <h1 style={{ color: "#08D040" }}> News</h1>
          </RowHeader>

          <CardInfoTitle className="item first-column">
            <a>{t("followUsSocialMedia")}</a>
            <div
              style={{
                display: "flex",
                gap: 20,
              }}
            >
              <a href={social.instagram} target="_blank" rel="noreferrer">
                <img src={socialIcons.insta} alt="" />
              </a>
              <a href={social.linkedin} target="_blank" rel="noreferrer">
                <img src={socialIcons.linkedin} alt="" />
              </a>
              <a href={social.youtube} target="_blank" rel="noreferrer">
                <img src={socialIcons.youtube} alt="" />
              </a>
              <a href={social.facebook} target="_blank" rel="noreferrer">
                <img src={socialIcons.facebook} alt="" />
              </a>
            </div>
          </CardInfoTitle>
          <h3 className="item">
            <a className="header-anchor" href="#stayUpToDate" id="stayUpToDate">
              #
            </a>
            {t("stayUpToDate")}
          </h3>
        </Newsheader>

        <NewsContent>
          {news.map((item: any) => (
            <NewsCard background={item.img}>
              <h3>{item.title}</h3>
            </NewsCard>
          ))}
        </NewsContent>

        <More onClick={handleSeeMoreClick}>
          <SeeMoreButton>{t("seeMore")}</SeeMoreButton>
        </More>
      </ConecthusNews>
      <WorkUsDiv>
        <WorkUsTitle>
          <h2>{t("comeJoinOurTeam")}</h2>
          <h2> {t("comeJoinOurTeam2")}</h2>
        </WorkUsTitle>
        <a
          href={`mailto:vagas@conecthus.org.br?subject=Currículo para análise&body=Olá${escape(
            ","
          )} segue anexo meu currículo para apreciação referente a vagas na área de...`}
        >
          <MainBtn
            variant="outlined"
            label={t("workWithUs")}
            onClick={() => history.push("/contact")}
          />
        </a>
      </WorkUsDiv>
    </Layout>
  );
}

export default Communication;
