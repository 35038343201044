import { Box } from "@mui/material";
import styled from "styled-components";

export const BoxPtEn = styled(Box)({
  display: "flex",
  height: "2rem",
});

export const Elipise = styled.div<any>`

  width: 33px;
  min-width: 33px;
  max-width: 33px;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  border-radius: 50%;
  background-color: #000; /* Cor de fundo */
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #02bc4d;
    color: #000;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
