import {
  BlurEffect,
  Container,
  ContainterLanguage,
  Elpsis,
  Menu,
  MenuItem,
  MenuLink,
} from "./styles";
import { MenuInterface } from "../../../utils/menu_links";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getLocalStorage, setLocalStorage } from "utils/local-storage.util";

interface ToggleMenuInterface {
  menu_links: Array<MenuInterface>;
}

export default function ToggleMenu({ menu_links }: ToggleMenuInterface) {
  const location = useLocation<any>();
  const { t } = useTranslation();

  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    if (language) {
      setCurrentLanguage();
    }
  }, [getLocalStorage("language")]);

  function setCurrentLanguage() {
    if (getLocalStorage("language")) {
      changeLanguage(getLocalStorage("language"));
    }
  }

  function handleChangeLanguage(event: React.MouseEvent<HTMLElement>) {
    if (language === "pt-BR") {
      changeLanguage("en-US");
      setLocalStorage("language", "en-US");
    } else {
      changeLanguage("pt-BR");
      setLocalStorage("language", "pt-BR");
    }
  }
  return (
    <Container>
      <BlurEffect />
      <Menu>
        <ContainterLanguage>
          <div
          className="content"
          >

          {language === "pt-BR" ? "Idioma" : "Language"}
          <Elpsis onClick={handleChangeLanguage}>
            {language === "pt-BR" ? "PT" : "EN"}
          </Elpsis>
          </div>
          <div className="divider" />
        </ContainterLanguage>
        {menu_links?.map((menu: MenuInterface, index: number) => (
          <MenuItem key={index} target={menu.link === location.pathname}>
            <MenuLink target={menu.link === location.pathname} to={menu.link}>
              {t(menu.label)}
            </MenuLink>
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
}
