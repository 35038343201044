import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';

import Jobs from '../pages/Jobs';
import Services from '../pages/Services';
import Areas from '../pages/Areas';
import About from '../pages/About'
import SmartHub from 'pages/SmartHub';
import Career from 'pages/Carreira';
import Academy from 'pages/Academy';
import Communication from 'pages/Communication';
import ContactUS from '../pages/Contact';
import ProvacyPolicy from 'pages/policy';

export const Routes = () => {

    return(
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/about" exact component={About} />
                <Route path="/areas" exact component={Areas} />
                <Route path="/services" exact component={Services} />
                <Route path="/smartHub" exact component={SmartHub} />
                <Route path="/career" exact component={Career} />
                <Route path="/academy" exact component={Academy} />
                <Route path="/communication" exact component={Communication} />
                {/* <Route path="/jobs" exact component={Jobs} /> */}
                <Route path="/contact" exact component={ContactUS} />
                <Route path="/privacyPolicy" exact component={ProvacyPolicy} />
            </Switch>
        </BrowserRouter>
    )
}