import * as React from "react"
const ArrowNarrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M-3.5 6.5h14m0 0-4 4m4-4-4-4"
    />
  </svg>
)
export default ArrowNarrow
