import { FormHelperText } from "@mui/material";
import styled from "styled-components";

export const ContainerAnchorBtn = styled.div`
  float: right;
  margin-top: 73px;
  margin-right: 152px;
  margin-bottom: 93px;
`;

export const WhereOperate = styled.div`
  background-color: #333c50;
  padding: 2% 12% 2% 12%;
  width: 100%;
  h1 {
    color: #08d040;
    font-size: 48px;
    font-weight: 600;
  }
  h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  @media (max-width: 900px) {
    padding: 20px 40px;
    h1 {
      font-size: 30px;
    }
    h3 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
export const ConecthusLabs = styled.div`
  background-color: #222835;
  padding: 2% 12% 2% 12%;
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  h1 {
    margin: 0;
    color: #08d040;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
  }
  .ConecthusLabsRow {
    display: flex;
    flex-direction: row;
    gap: 7px;
    img {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: 900px) {
    padding: 40px 20px;
    h1 {
      font-size: 30px;

      line-height: 38px; /* 126.667% */
    }
  }
`;
export const LabsCards = styled.div`
  position: relative;
  height: 650px;
  // background-color: red;
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
  }
`;
export const LabsSoftware = styled.div<any>`
  background-color: ${(props) => (props.even ? "#FFFFFF" : "#02bc4d")};
  padding: 2% 12% 2% 12%;
  h2 {
    color: ${(props) => (props.even ? "#000000" : "#FFFFFF")};
    font-size: 30px;
    font-weight: 600;
    padding: 0px 20px;
  }
  h3 {
    color: ${(props) => (props.even ? "#000000" : "#FFFFFF")};
    font-size: 18px;
    font-weight: 400;
    padding: 0px 20px;
  }
  @media (max-width: 900px) {
    padding: 40px 0px;
  }
`;
export const LabsCardFront = styled.div<any>`
  width: 278px;
  height: 442px;
  border-radius: 16px;
  position: absolute;
  top: 86px;
  background-color: white;
  right: ${({ right }) => (right ? "110px" : "inherit")};
  box-shadow: 1px 4px 8px #00000029;
  @media (max-width: 900px) {
    right: inherit;
    left: inherit;
    top: 25%;
  }
`;
export const SubBack = styled.div<any>`
  background-image: url(${({ labs_sub_back }) => labs_sub_back});
  background-size: 100%;
  background-position: center;
  height: 150px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  transition: background-size 0.3s ease; /* Adicionando uma transição suave */
  overflow: hidden;

  &:hover {
    background-size: 120%; /* Zoom in no hover */
  }
`;
export const TitleSub = styled.div`
  background-color: #000000;
  padding: 12px 24px 12px 24px;
  color: #08d040;
  font: 20px;
`;
export const LabsCardBack = styled.div<any>`
  width: 80%;
  height: 614px;
  border-radius: 10px;
  left: ${({ left }) => (left ? 0 : "140px")};
  position: absolute;
  background-image: url(${({ background }) => background});
  background-size: cover;

  @media (max-width: 900px) {
    position: relative;
    left: 0;
    width: 100%;
    height: 390px;
  }
`;

export const SubContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
`;

export const SubDownload = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  border-radius: 8px;
  padding: 6px;
  h3 {
    color: #02bc4d;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  transition: background-color 0.4s ease;
  &:hover {
    background-color: #000000;
  }
`;

export const Contact = styled.div<any>`
  //height: 900px;
  width: 100%;
  padding: 5% 12% 2% 12%;
  background-image: url(${({ background }) => background});
  background-color: #222835;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;
export const ContactInfo = styled.div`
  //width: 460px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;

 // background-color: red;
  flex-wrap: wrap;
`;
export const TitleContactInfo = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
 
  h1 {
    margin: 0;
    color: #ffffff;
    font-size: ${({ size }) => (size ? size : "48px")};
    font-weight: 600;
  }
  h2 {
    margin: 0;
    color: #08d040;
    font-size: ${({ size }) => (size ? size : "48px")};
    font-weight: 600;

    //line-height: 58.505px;
  }

  @media (max-width: 900px) {
    .ImgInfo {
      height: 36;
     
      width: 100%;
    
    }
    flex-wrap: nowrap;
    align-items: center;
    h1,
    h2 {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;
export const ContactImg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  img {
    height: 191px;
    width: 460px;
    border-radius: 8px;
  }
  @media (max-width: 900px) {
    height: auto;
    img {
      height: 100%;
      width: 100%;
    }
  }
`;
export const FormContactUs = styled.div`
  padding: 1% 2% 1% 2%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  border-radius: 14px;
  width: 50%;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 900px) {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 20px;
  gap: 20px;
  width: 100%;
`;
export const PolicyPricacy = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.014px;
  a,
  .privacy-policy {
    display: inline;
  }

  .privacy-policy {
    color: #08d040;
    font-weight: bold; /* Opcional: ajusta a aparência do texto */
  }
`;
export const CheckForm = styled.div`
  display: flex;
  align-items: center;
`;

export const HelperText = styled(FormHelperText)<any>(
  ({ theme, error, align }) => ({
    color: error ? theme.colors.error.main : "#636C82",

    //textAlign: align ? "end" : "left",
    margin: "0px 0px 0px 3px",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    //fontFamily: "'Poppins',sans-serif !important",
    letterSpacing: 0,
    display: "flex",
    justifyContent: align === "end" ? "flex-end" : "space-between",
    flexWrap: "wrap",
    a: {
      color: "#B00020",
      fontWeight: 400,
      lineHeight: 1.66,
      fontSize: "0.75rem",
    },
  })
);
