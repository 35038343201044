/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import Parallax from "../../components/Parallax";
import {
  ContainerTitle,
  ParagraphTitle,
  Faixa,
  MapDiv,
  ContentMap,
  MapCard,
  MapsDiv,
  MapBack,
  MapCardInfoTitle,
  MapCardInfo,
  Legend,
  PartinersDiv,
  CarrouselPartiners,
  CardCarrousel,
  ContainerAbout,
  CardAbout,
  TitleAbout,
  CarrouselAbout,
  CardsTextAbout,
  TextsAbout,
  QualityPolicy,
  RowForm,
  MVVContainer,
  MVVText,
  ValuesText,
  QualityPolicyContent,
  QualityObjectives,
  QualityText,
  CarrouselTrack,
  OursLabsBoxVideo,
  QualityPolicyTitle,
  QualityPolicyIcons,
  CardAboutMob,
  CarrouselAboutMob,
} from "./styles";
import escritorio from "../../assets/About/escritorio.png";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import ArrowDownMain from "../../assets/About/ArrowDownMain.svg";
import ISO9001 from "../../assets/About/ISO9001.svg";
import selo_1 from "../../assets/About/selo_1.svg";
import {
  AboutDiv,
  ArticleContent,
  ArticleDiv,
  BoxVideos,
  BulletsDiv,
  ClientsCard,
  ClientsCardButton,
  ClientsDiv,
  LabsDiv,
  LabsWrapperBoxVideo,
} from "pages/Home/styles";
import { IFrame, WrapperBoxVideo } from "pages/Services/styles";
import BULLETS from "../../assets/Home/BULLETS.svg";
import MAPA from "../../assets/About/MAPA.svg";
import LOGO from "../../assets/Header/Header_Logo_Black.svg";
import { Divider } from "@mui/material";
import { CaurrousePartinerslIcon, footerIcons } from "utils/links";
import MainBtn from "components/Buttons/MainBtn";
import cicle_plus from "../../assets/About/circle-plus.svg";
import circle_right from "../../assets/About/circle_right.svg";
import circle_right_main from "../../assets/About/circle_right_main.svg";
import WeAreCarousel from "./components/WeAreCarousel";
import { useTranslation } from "react-i18next";
import ArrowNarrow from "assets/ArrowNarrow";
import { ArticleDescription } from "pages/Home/components/Article/styles";

export default function About() {
  const { t } = useTranslation();

  const quality_objective = [
    {
      title: t("complyWithTheInstitute"),
    },
    {
      title: t("provideQualityProducts"),
    },
    {
      title: t("continuouslyImprove"),
    },
    {
      title: t("trainEmployees"),
    },
    {
      title: t("respectAndPromote"),
    },
  ];

  const about_texts = [
    {
      title: t("weHaveMoreThan100"),
    },
    {
      title: t("engineersDevelopersDesigners"),
    },
    { title: t("phDsInAI") },
    { title: t("mastersInCV") },
    { title: t("technologicalDevelopment") },
    { title: t("excellentLaboratories") },
  ];
  const trackRef = useRef(null) as any;
  let currentIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      if (trackRef.current) {
        const width = trackRef.current.children[0].offsetWidth + 40; // item width + gap
        trackRef.current.style.transform = `translateX(-${
          width * currentIndex
        }px)`;
        currentIndex = (currentIndex + 1) % CaurrousePartinerslIcon.length;
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout>
      <Parallax
        background={escritorio}
        title={t("areDriveToCreateFuture")}
        rightBottom
      />
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>
      <AboutDiv>
        {/* <ArticleDiv>
          <Article
            title={t("whoWeAre")}
            description={t("areAnInstituteAccredited")}
          />
        </ArticleDiv> */}
        <ArticleDiv>
          <ArticleContent id="main">
            <div style={{ width: "351px", paddingBottom: 20 }}>
              <h1>
                <a className="header-anchor" href="#whoWeAre" id="whoWeAre">
                  #
                </a>
                {t("whoWeAre")}
              </h1>
            </div>
            <ArticleDescription>
              <a
                className="header-anchor"
                href="#areAnInstituteAccredited"
                id="areAnInstituteAccredited"
              >
                #
              </a>
              {t("areAnInstituteAccredited")}
            </ArticleDescription>
          </ArticleContent>
        </ArticleDiv>
      </AboutDiv>
      <ContainerTitle>
        <ParagraphTitle>
          <a
            className="header-anchor"
            href="#multidisciplinaryResearch"
            id="multidisciplinaryResearch"
          >
            #
          </a>
          {t("multidisciplinaryResearch")}
        </ParagraphTitle>
      </ContainerTitle>
      <LabsDiv>
        <BoxVideos>
          <LabsWrapperBoxVideo>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <BulletsDiv>
                <img src={BULLETS} alt="" width={"40%"} />
              </BulletsDiv>

              <OursLabsBoxVideo>
                <h1 style={{ fontSize: 16 }}>{t("ourLaboratories")}</h1>
              </OursLabsBoxVideo>
            </div>
            <WrapperBoxVideo>
              <IFrame
                src="https://www.youtube.com/embed/m4nu8PmY-5E?si=kQiBZTpioYJ41A2g"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                frameBorder={0}
                isPlay={true}
              />
            </WrapperBoxVideo>
          </LabsWrapperBoxVideo>
        </BoxVideos>
        <h3>
          <a
            className="header-anchor"
            href="#ourMainCommitmentIsInnovation"
            id="ourMainCommitmentIsInnovation"
          >
            #
          </a>
          {t("ourMainCommitmentIsInnovation")}
        </h3>
      </LabsDiv>
      <MapDiv>
        <MapBack />

        <div
          style={{
            position: "absolute",
            width: "100%",
            padding: "0% 12% 0% 10%",
          }}
        >
          <h1>
            <a className="header-anchor" href="#whereAreWe" id="whereAreWe">
              #
            </a>
            {t("whereAreWe")}
          </h1>
          <ContentMap>
            <MapCard>
              <div style={{ width: "100%", textAlign: "center" }}>
                <img src={LOGO} width={"80%"} alt="" />
                <Divider sx={{ backgroundColor: "#D2D5DA" }} />
              </div>
              <MapCardInfo>
                <div>
                  <img src={footerIcons.pin} alt="" />
                </div>
                <MapCardInfoTitle>
                  <h1>Conecthus Manaus</h1>
                  <h2>Av. Buriti, 3001 - Distrito Industrial I</h2>
                  <h2>CEP: 69075 - 000 | Manaus-AM</h2>
                </MapCardInfoTitle>
              </MapCardInfo>

              <MapCardInfo>
                <div>
                  <img src={footerIcons.phone} alt="" />
                </div>
                <MapCardInfoTitle>
                  <h2>+55 (92) 99214.8657</h2>
                  <h2>+55 (92) 99487.6001</h2>
                </MapCardInfoTitle>
              </MapCardInfo>

              <MapCardInfo>
                <div>
                  <img src={footerIcons.mail} alt="" />
                </div>
                <MapCardInfoTitle>
                  <h2>cso@conecthus.org.br</h2>
                </MapCardInfoTitle>
              </MapCardInfo>
            </MapCard>

            <MapsDiv>
              <img src={MAPA} alt="" width={"100%"} />

              <Legend>
                <li>
                  <h1>{t("futureExpansions")}:</h1>
                  <h2>Roraima Rôndonia São Paulo</h2>
                </li>
              </Legend>
            </MapsDiv>
          </ContentMap>
        </div>
      </MapDiv>
      <PartinersDiv>
        <h1>
          <a className="header-anchor" href="#partners" id="partners">
            #
          </a>
          {t("partners")}
        </h1>
        <h2>
          <a
            className="header-anchor"
            href="#ourTechnologyPartners"
            id="ourTechnologyPartners"
          >
            #
          </a>
          {t("ourTechnologyPartners")}
        </h2>

        <CarrouselPartiners>
          <CarrouselTrack ref={trackRef}>
            {CaurrousePartinerslIcon.concat(CaurrousePartinerslIcon).map(
              (item, index) => (
                <CardCarrousel key={index}>
                  <img src={item.icon} alt={item.name} />
                </CardCarrousel>
              )
            )}
          </CarrouselTrack>
        </CarrouselPartiners>
      </PartinersDiv>

      <ContainerAbout>
        <CardAbout>
          <TitleAbout className="grid-item">
            <h2>
              <a
                className="header-anchor"
                href="#weAreTeamInnovators"
                id="weAreTeamInnovators"
              >
                #
              </a>
              {t("weAreTeamInnovators")}
            </h2>
            <h3>
              <a
                className="header-anchor"
                href="#contactUsFind"
                id="contactUsFind"
              >
                #
              </a>
              {t("contactUsFind")}
            </h3>
            <MainBtn label={t("discoverOurCompetencies")} variant={"main"} />
          </TitleAbout>
          <CarrouselAbout className="grid-item">
            <WeAreCarousel />
          </CarrouselAbout>

          <CardsTextAbout className="grid-item">
            {about_texts.map((item: any, index: any) => (
              <TextsAbout even={index % 2 === 0}>
                <img src={cicle_plus} alt="" />
                <h3>{item.title}</h3>
              </TextsAbout>
            ))}
          </CardsTextAbout>
        </CardAbout>

        <CardAboutMob>
          <TitleAbout>
            <h2>{t("weAreTeamInnovators")}</h2>
          </TitleAbout>
          <CarrouselAboutMob>
            <WeAreCarousel />
          </CarrouselAboutMob>

          <CardsTextAbout>
            {about_texts.map((item: any, index: any) => (
              <TextsAbout even={index % 2 === 0}>
                <img src={cicle_plus} alt="" />
                <h3>{item.title}</h3>
              </TextsAbout>
            ))}
          </CardsTextAbout>
          <h3>{t("contactUsFind")}</h3>
          <MainBtn label={t("discoverOurCompetencies")} variant={"main"} />
        </CardAboutMob>
      </ContainerAbout>

      <QualityPolicy>
        <QualityPolicyTitle>
          <h1>
            <a
              className="header-anchor"
              href="#qualityPolicy"
              id="qualityPolicy"
            >
              #
            </a>
            {t("qualityPolicy")}
          </h1>
          <RowForm>
            <img src={ArrowDownMain} alt="" />
            <img src={ArrowDownMain} alt="" />
            <img src={ArrowDownMain} alt="" />
          </RowForm>
        </QualityPolicyTitle>

        <QualityPolicyIcons>
          <img src={ISO9001} alt="" />
          <img src={selo_1} alt="" />
        </QualityPolicyIcons>
      </QualityPolicy>
      <MVVContainer>
        <MVVText>
          <img src={circle_right} alt="" />
          <h1>
            <a className="header-anchor" href="#mission" id="mission">
              #
            </a>
            {t("mission")}
          </h1>
          <h3>
            <a
              className="header-anchor"
              href="#designDevelopExecute"
              id="designDevelopExecute"
            >
              #
            </a>
            {t("designDevelopExecute")}
          </h3>
        </MVVText>
        <MVVText>
          <img src={circle_right} alt="" />
          <h1>
            <a className="header-anchor" href="#vision" id="vision">
              #
            </a>
            {t("vision")}
          </h1>
          <h3>
            <a
              className="header-anchor"
              href="#beGlobalReference"
              id="beGlobalReference"
            >
              #
            </a>
            {t("beGlobalReference")}
          </h3>
        </MVVText>
        <MVVText>
          <img src={circle_right} alt="" />
          <h1>
            <a className="header-anchor" href="#values" id="values">
              #
            </a>
            {t("values")}
          </h1>
          <Divider
            sx={{
              backgroundColor: "white",
              width: "100%",
              marginTop: 6,
              height: 2,
            }}
          />
        </MVVText>

        <ValuesText>
          <h2>
            <a className="header-anchor" href="#delivery" id="delivery">
              #
            </a>
            {t("delivery")}
          </h2>
          <h3>
            <a
              className="header-anchor"
              href="#weUnderstandHowDeliver"
              id="weUnderstandHowDeliver"
            >
              #
            </a>
            {t("weUnderstandHowDeliver")}
          </h3>
        </ValuesText>
        <ValuesText>
          <h2>
            <a
              className="header-anchor"
              href="#criativityInnovation"
              id="criativityInnovation"
            >
              #
            </a>
            {t("criativityInnovation")}
          </h2>
          <h3>
            <a
              className="header-anchor"
              href="#toBeGlobalReference"
              id="toBeGlobalReference"
            >
              #
            </a>
            {t("toBeGlobalReference")}
          </h3>
        </ValuesText>
        <ValuesText>
          <h2>
            <a
              className="header-anchor"
              href="#unconditionalRespectDiversity"
              id="unconditionalRespectDiversity"
            >
              #
            </a>
            {t("unconditionalRespectDiversity")}
          </h2>
          <h3>{t("weRespectAllDifferences")}</h3>
        </ValuesText>
        <ValuesText>
          <h2>
            <a className="header-anchor" href="#teamWork" id="teamWork">
              #
            </a>
            {t("teamWork")}
          </h2>
          <h3>
            <a
              className="header-anchor"
              href="#weAreCommittedUniting"
              id="weAreCommittedUniting"
            >
              #
            </a>
            {t("weAreCommittedUniting")}
          </h3>
        </ValuesText>
      </MVVContainer>
      <QualityPolicyContent>
        <h1>{t("qualityPolicy")}</h1>
        <h3>
          <a
            className="header-anchor"
            href="#theConecthusInstituteOperates"
            id="theConecthusInstituteOperates"
          >
            #
          </a>
          {t("theConecthusInstituteOperates")}
        </h3>

        <h2>
          <a
            className="header-anchor"
            href="#qualityObjectives"
            id="qualityObjectives"
          >
            #
          </a>
          {t("qualityObjectives")}
        </h2>
        <QualityObjectives>
          {quality_objective.map((item: any) => (
            <QualityText className="grid-item">
              <img src={circle_right_main} alt="" height={34} />
              <h3>{item.title}</h3>
            </QualityText>
          ))}
        </QualityObjectives>
      </QualityPolicyContent>
      <ClientsDiv>
        <ClientsCard>
          <h3>
            <a
              className="header-anchor"
              href="#readyBoosYourBusiness"
              id="readyBoosYourBusiness"
            >
              #
            </a>
            {t("readyBoosYourBusiness")}
          </h3>
          <ClientsCardButton>
            {t("talkToUs")}
            <ArrowNarrow />
          </ClientsCardButton>
        </ClientsCard>
      </ClientsDiv>
    </Layout>
  );
}
