
import { Box, Button } from "./styles";

interface MainBtnInterface {
  icon?: any;
  label: string;
  onClick?: () => void;
  variant?: string;
  isAnchor?: boolean;
  padding?:string
  disabled?:boolean
}

export default function MainBtn({
  icon,
  label,
  onClick,
  variant,
  isAnchor,
  padding,
  disabled
}: MainBtnInterface) {
  return (
    <Box 
   // href={isAnchor ? "#main" : "#"}
    >
      <Button disabled={disabled} padding={padding} onClick={onClick} hasIcon={icon} variant={variant}>
        {label}
        {icon && icon}
      </Button>
    </Box>
  );
}
