import Footer_Logo from "../../assets/Header/Header_Logo_Black.svg";
import {
  Logo,
  Container,
  Title,
  ContainerHeader,
  CardInfo,
  CardInfoTitle,
  InformationsDiv,
  InformationsLabel,
  QrCode,
  CardInfoRow,
  QrCodeImg,
} from "./styles";
import GlobalStyles from "../../style/GlobalStyles";
import cso from "../../assets/Footer/cso.svg";
import csoLabls from "../../assets/Footer/cso-labls.svg";
import map_pin from "../../assets/Footer/map_pin.svg";
import phone from "../../assets/Footer/phone.svg";
import mail from "../../assets/Footer/mail.svg";
import { social, socialIcons } from "../../utils/links";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const anoAtual = new Date().getFullYear();
  return (
    <Container id="footer">
      <ContainerHeader>
        <Logo src={Footer_Logo} />
        <Title>
          <h1>{t("conecthusTechBiotechInstituteAmazonas")}</h1>
          <h2>{t("connectingTodayPossibilities")}</h2>
        </Title>
        <QrCode>
          <h1>Customer Service Officer </h1>
          <QrCodeImg>
            <img src={cso} alt="" />
            <img src={csoLabls} alt="" />
          </QrCodeImg>
        </QrCode>
      </ContainerHeader>

      <CardInfoRow>
        <CardInfo>
          <div>
            <img src={map_pin} alt="" />
          </div>
          <CardInfoTitle>
            <h1>Conecthus Manaus</h1>
            <h2>Av. Buriti, 3001 - Distrito Industrial I</h2>
            <h2>CEP: 69075 - 000 | Manaus-AM</h2>
          </CardInfoTitle>
        </CardInfo>

        <CardInfo>
          <div>
            <img src={phone} alt="" />
          </div>
          <CardInfoTitle>
            <h2>+55 (92) 99214.8657</h2>
            <h2>+55 (92) 99487.6001</h2>
            <h2>+55 (92) 99523.4420</h2>
          </CardInfoTitle>
        </CardInfo>

        <CardInfo>
          <div>
            <img src={mail} alt="" />
          </div>
          <CardInfoTitle>
            <h2>cso@conecthus.org.br</h2>
          </CardInfoTitle>
        </CardInfo>
        <CardInfoTitle>
          <a>{t("followUsSocialMedia")}</a>
          <div
            style={{
              display: "flex",
              gap: 20,
            }}
          >
            <a href={social.instagram} target="_blank" rel="noreferrer">
              <img src={socialIcons.insta} alt="" />
            </a>
            <a href={social.linkedin} target="_blank" rel="noreferrer">
              <img src={socialIcons.linkedin} alt="" />
            </a>
            <a href={social.youtube} target="_blank" rel="noreferrer">
              <img src={socialIcons.youtube} alt="" />
            </a>
            <a href={social.facebook} target="_blank" rel="noreferrer">
              <img src={socialIcons.facebook} alt="" />
            </a>
          </div>
        </CardInfoTitle>
      </CardInfoRow>

      <InformationsDiv>
        <InformationsLabel
          onClick={() => (window.location.href = "/privacyPolicy")}
        >
          {t("privacyPolicy")}
        </InformationsLabel>

        <InformationsLabel>
          | © {anoAtual} | {t("conecthusTechBiotechInstituteAmazonas")}. All
          Rights Reserved.
        </InformationsLabel>
      </InformationsDiv>

      <GlobalStyles />
    </Container>
  );
};

export default Footer;
