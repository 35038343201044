import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

function SelectMain({ label, items, value, onChange, disabled }: any) {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        disabled={disabled}
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChange}
      >
        {items.map((item: any) => (
          <MenuItem value={item?.value}>{t(item?.name)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectMain;
