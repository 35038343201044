import styled, { keyframes } from "styled-components";
import bg_back from "../../assets/About/bg_back.png";
import BG_Black from "../../assets/About/Bg_Black.png";
interface IFrameInterface {
  isPlay: boolean;
}

interface BackgroundMediaInterface {
  background: string;
  isPlay?: boolean;
}
export const Faixa = styled.div`
  height: 70px;
  width: 100%;
  background-color: #02bc4d;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const ContainerTitle = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-right: 266px;
  padding-left: 266px;

  @media (max-width: 1041px) {
    padding: 0;
  }
`;
export const ParagraphTitle = styled.p`
  width: auto;
  margin-top: 23px;
  line-height: 38px;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.92px;
  color: #1f1f24;
  text-wrap: balance;
  /* margin-right: 69px;
  margin-left: 69px; */
`;
export const MapDiv = styled.div`
  // width: 100%;
  //padding: 0px 12% 0px 12%;
  position: "relative";
  height: 1090px;
  width: 100%;
  // background-color: red;
  h1 {
    font: normal normal 600 48px/5vh Barlow;
    color: #02bc4d;
  }
`;
export const MapBack = styled.div`
  width: 100%;
  height: 1060px;
  background-image: url(${bg_back});
  opacity: 25%;
  background-attachment: local;
  background-size: cover;
  display: flex;
  overflow: visible;
  text-align: start;
  position: absolute;
  margin-top: 2%;
`;
export const ContentMap = styled.div`
  //position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 700px;
  padding-top: 6%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
export const MapCard = styled.div`
  width: 313px;
  height: 313px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 1px 14px 18px #00000029;
  //position: absolute;

  img {
    padding: 15px;
    height: auto;
  }
`;
export const MapCardInfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 13px;
    font-weight: bold;
    margin: 5px;
    color: #000000;
  }
  h2 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
  }
  a {
    color: #08d040;
  }
`;
export const MapCardInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const MapsDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const PartinersDiv = styled.div`
  width: 100%;
  h1 {
    padding: 2% 12% 0px 12%;
    font: normal normal 600 48px/5vh Barlow;
    color: #02bc4d;
    // padding: 0px 20px;
  }
  h2 {
    // font: normal normal 600 30px/5vh Barlow;
    padding: 2% 12% 0px 12%;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
   // padding: 0px 20px;
  }
  @media (max-width: 900px) {
    h1{
      padding: 0px 20px;
    }
    h2{
      padding: 0px 20px;
    }
  }
`;
const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

export const CarrouselTrack = styled.div`
  display: flex;
  width: fit-content;
  animation: ${scrollAnimation} 20s linear infinite;
`;

export const CarrouselPartiners = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;
export const CardCarrousel = styled.div`
  flex: 0 0 auto;
  min-width: 150px; /* Ajuste conforme necessário */
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
`;

export const ContainerAbout = styled.div`
  background-color: #333333;
  height: 800px;
  padding: 2% 12% 0px 12%;
  @media (max-width: 900px) {
    padding: 44px 20px;
    height: auto;
  }
`;
export const CardAbout = styled.div`
  background-color: #ffffff;

  border-radius: 8px;
  height: 660px;
  display: grid;
  grid-template-rows: 71% auto; /* Duas linhas */
  grid-template-columns: 50% 50%; /* Duas colunas na primeira linha */
  overflow: hidden;
  .grid-item:nth-child(3) {
    grid-column: span 2; /* Faz o item ocupar as duas colunas */
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
export const CardAboutMob = styled.div`
  background-color: #ffffff;
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  h3 {
    padding: 10px 30px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
  @media (min-width: 900px) {
    display: none;
  }
`;
export const TitleAbout = styled.div`
  padding: 8% 0px 0px 8%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: #000000;
  width: 80%;
  @media (max-width: 900px) {
    padding: 20px 32px;
    width: 100%;
  }
  h2 {
    font: normal normal 600 1.5/5vh Barlow;
    margin: 0;
  }
  h3 {
    margin: 0;
    width: 80%;
    font: normal normal 500 20px/24px Barlow;
  }
`;

export const CarrouselAbout = styled.div`
  // height: 547px;
  //width: 600px
  /* @media (min-width: 900px) {
    width: 100%;
    display: none;
    height: 300px;
    background-color: red;
  } */
`;
export const CarrouselAboutMob = styled.div`
  // height: 547px;
  //width: 600px
  width: 100%;

  //height: 300px;
  // background-color: red;
`;
export const CardsTextAbout = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
  }
`;
export const TextsAbout = styled.div<any>`
  background-color: ${(props) => (props.even ? "#02bc4d" : "#275F40")};
  width: 20%;
  padding: 7px 25px 20px 17px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 32px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  @media (max-width: 900px) {
    padding: 30px;
    width: 100%;
    flex-direction: row;
    h3 {
      padding: 0;
    }
    gap: 10px;
  }
`;
export const QualityPolicy = styled.div`
  background-color: #222835;
  width: 100%;
  //height: 244px;
  padding: 0% 12% 0% 12%;
  display: flex;
  h1 {
    color: white;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
  }
  @media (max-width: 900px) {
    padding: 52px;
    flex-direction: column;
    align-items: center;
  }
`;
export const QualityPolicyTitle = styled.div`
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  justify-content: center;
  @media (max-width: 900px) {
    align-items: center;
  }
`;
export const QualityPolicyIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  @media (max-width: 900px) {
    margin-top: 30px;
    img {
      width: 120px;
    }
  }
`;
export const ColForm = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "100%")};
  gap: 10px;
  justify-content: ${({ justify }) => (justify ? justify : "normal")};
`;
export const RowForm = styled.div<any>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: ${({ align }) => (align ? align : "normal")};
`;
export const MVVContainer = styled.div`
  width: 100%;
  height: auto;
  background-image: url(${BG_Black});
  background-color: #222835;
  background-attachment: local;
  background-size: cover;
  padding: 0% 12% 5% 12%;
`;
export const MVVText = styled.div`
  padding-top: 50px;
  h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */

    color: #02bc4d;
    margin: 0;
  }
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: #ffffff;
    margin: 0;
  }
  @media (max-width: 900px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 16px;
    }
  }
`;
export const ValuesText = styled.div`
  padding-top: 50px;
  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
    color: #02bc4d;
    margin: 0;
  }
  h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    color: #ffffff;
    margin: 0;
  }
  @media (max-width: 900px) {
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 16px;
    }
  }
`;
export const QualityPolicyContent = styled.div`
  background-color: #ffffff;
  padding: 2% 12% 2% 12%;
  width: 100%;
  h1 {
    margin: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 120% */
  }
  h3 {
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
  }
  h2 {
    font-family: Barlow;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
  }
  @media (max-width: 900px) {
    padding: 26px;
    h1 {
      font-size: 30px;
    }
    h3 {
      font-size: 16px;
    }
  }
`;
export const QualityObjectives = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-row-gap: 30px; /* Ajuste o gap entre as linhas conforme necessário */
  }
`;
export const QualityText = styled.div`
  //padding: 20px;
  //text-align: center;
  display: flex;
  align-items: flex-start;
  // background-color: red;
  gap: 20px;
`;
export const Legend = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h1 {
    color: #08d040;
    font-size: 30px;
    margin: 0;
    margin-top: 14px;
  }
  h2 {
    color: #1f1f24;
    font-size: 30px;
    margin: 0;
  }
  li {
    list-style: square;
    color: #e8e838;
    font-size: 80px;
  }
`;

export const ContainerAnchorBtn = styled.div`
  float: right;
  margin-bottom: 70px;
  margin-top: 70px;
  margin-right: 152px;
`;

export const WrapperBoxVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;

  @media (max-width: 1041px) {
    width: 100%;
  }
`;

export const MediaIcon = styled.img`
  width: 314px;
  height: 100px;
  margin-bottom: 12px;
`;

export const PlayIconVideo = styled.img`
  width: 82px;
  height: 82px;

  @media (max-width: 822px) {
    width: 60px;
    height: 60px;
  }
`;

export const BoxVideoAbout = styled.div<BackgroundMediaInterface>`
  width: 943px;
  height: 475px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${({ background }) => background});
  background-size: 100% 100%;
  opacity: 0.7;
  transition: 0.3s all;
  cursor: pointer;
  display: ${({ isPlay }) => (!isPlay ? "" : "none")};

  :hover {
    opacity: 1;
    ${PlayIconVideo} {
      transform: scale(1.2);
    }
  }

  @media (max-width: 1041px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 425px) {
    height: 200px;
  }
`;
export const IFrame = styled.iframe<IFrameInterface>`
  width: 100%;
  height: 500px;
  border-radius: 10px;
  display: ${({ isPlay }) => (isPlay ? "" : "none")};

  @media (max-width: 1041px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 425px) {
    height: 200px;
  }
`;

export const OursLabsBoxVideo = styled.div`
  border-width: 7px 7px 0px;
  border-style: solid;
  border-color: black;
  border-image: initial;
  border-radius: 19px 19px 0px 0px;
  height: 59px;
  width: 64%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 18px;

  @media (max-width: 500px) {
    height: 45px;
    border-width: 4px 4px 0px;
  }
`;
