import styled from "styled-components";
import registerCV_back from "../../assets/Career/registerCV_back.jpg";
export const CarreirasHeader = styled.div<any>`
  width: 100%;
  height: 85vh;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: 0% 35%;
  background-position: center;
  background-attachment: local;
  display: flex;
  overflow: visible;
  text-align: start;
  position: relative;
  justify-content: flex-end;
  @media (max-width: 900px) {
    background-size: 230%;
    background-position: 57% 22%;
    height: 400px;
    text-align: center;
  }
  @media (max-width: 500px) {
    //background-size: 230%;
    background-position: 57% 30%;
    
  }
`;
export const TitleCarreirasHeader = styled.div<any>`
  position: absolute;
  //background-color: red;
  // height: 100%;
  width: 470px;
  top: 5%;
  left: 12%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  h3 {
    color: ${({ bottom }) => (bottom ? "#373936" : "white")};
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    margin: 0;
  }
  @media (max-width: 900px) {
    padding: 20px 30px;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    left: inherit;
    right: inherit;
    top: inherit;
    display: flex;
    text-align: center;
    h3 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      margin: 0;
    }
  }
`;
export const OurCulture = styled.div`
  width: 100%;
  padding: 4% 12% 4% 12%;
  background-color: #ffffff;
  color: #333333;
  display: flex;
  flex-direction: row;
  gap: 80px;
  align-items: center;
  img {
    width: 610px;
    border-radius: 14px;
    box-shadow: 0px 1px 8px #00000029;
  }
  @media (max-width: 1200px) {
    padding: 24px 20px;
    gap: 20px;
    flex-direction: column-reverse;
    img {
      width: 100%;
    }
  }
`;
export const TextOurCulture = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 48px;
    font-weight: 600;
  }
  h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  @media (max-width: 1200px) {
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      text-align: center;
    }
  }
`;
export const BenefitsContent = styled.div`
  width: 100%;
  padding: 4% 12% 4% 12%;
  background-color: #f5f5f5;

  @media (max-width: 1200px) {
    padding: 24px 20px;
  }
`;
export const BenefitsTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
  align-items: center;
  color: #333333;
  img {
    width: 610px;
    border-radius: 14px;
    box-shadow: 0px 1px 8px #00000029;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
    img {
      width: 100%;
    }
  }
`;

export const BenefitsCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 38px;
  column-gap: 4%;
  justify-content: center;
  padding-top: 50px;
`;
export const BenefitsCard = styled.div`
  display: flex;
  gap: 20px;
  padding: 34px;
  background-color: #ffffff;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 38px 50px 0px #00000026;
  width: 390px;
  height: 170px;
  border-radius: 10px;
  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    margin: 0;
  }
  img {
    height: 48px;
    width: 48px;
  }
  @media (max-width: 1200px) {
    width: 150px;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    padding: 20px;
    img {
      height: 68px;
      width: 68px;
    }
    h2 {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;

export const WorkAtConnecthus = styled.div`
  padding: 4% 12% 4% 12%;
  width: 100%;
  background-color: #222835;
  h1 {
    color: #08d040;
    font-size: 48px;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px 30px;

    h1 {
      text-align: center;
    }
  }
`;
export const CardWrap = styled.div`
  position: relative;
  height: 550px;
  @media (max-width: 1200px) {
    height: 780px;
  }
`;
export const CardBack = styled.div<any>`
  width: 65%;
  height: 498px;
  border-radius: 10px;
  right: 0px;
  position: absolute;
  border: 1px solid #08d040;
  color: #ffffff;
  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
    top: 313px;
  }
`;
export const CardFront = styled.div<any>`
  width: 40%;
  height: 327px;
  border-radius: 10px;
  position: absolute;
  top: 86px;
  left: 0px;
  border: 1px solid #08d040;
  padding: 50px 53px 30px 53px;
  background-color: #222835;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 20px;
  }
`;
export const FrontContent = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-columns: 20px;
  justify-content: space-between;
  height: 100%;
  color: #ffffff;
  h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
`;
export const Stars = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const CardInsideBack = styled.div`
  background-color: #08d040;
  border-radius: 10px;
  padding: 20px 60px 50px 50px;
  color: white;
  position: absolute;
  top: 190px;
  width: 56%;
  height: 247px;
  right: 10%;
  @media (max-width: 1200px) {
    width: 100%;
    right: 0;
  }
`;

// Componente estilizado com animações de fade-in e fade-out
export const CardInsideFront = styled.div<any>`
  border-radius: 10px;
  left: 15%;
  top: 90px;
  position: absolute;
  width: 22%;
  height: 290px;
  background-color: black;
  background-image: url(${({ background }) => background});
  background-size: 120%;
  background-position: 24% 17%;
  box-shadow: -7px 5px 1px black;
  opacity: 1;
  &.deposition-container {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &.deposition-container.hidden {
    opacity: 0.5;
  }
  @media (max-width: 1200px) {
    display: none;
  }
`;
export const TextInsideBack = styled.div`
  position: absolute;
  padding: 30px;
  h3 {
    font-size: 13px;
    font-weight: 500;
  }
  opacity: 1;
  &.deposition-container {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }

  &.deposition-container.hidden {
    opacity: 0.5;
  }
`;
export const SubTextInside = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  p {
    font-size: 13px;
    font-weight: 300;
    font-style: italic;
  }
`;
export const Dots = styled.div`
  right: 17px;
  bottom: -14px;
  position: absolute;
`;

export const OurJobs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 12% 100px 12%;
  background-color: #08d040;
  //width: 40%;
  text-align: center;
  gap: 20px;
  h1 {
    color: white;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  h2 {
    font-size: 30px;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    padding: 80px 20px;
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }
  }
`;

export const JobsContent = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 70px;
  row-gap: 30px;
  padding: 2% 2% 4% 2%;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const JobCard = styled.div`
  padding: 20px;
  width: 533px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0px 29px 50px 0px #00000066;
  background-color: #ffffff;
  border-radius: 10px;
  align-items: center;
  gap: 50px;
  h1 {
    font-size: 3vh;
    font-weight: 600;
    margin: 0;
  }
  @media (max-width: 1200px) {
    width: 330px;
  }
`;

export const ButtonMain = styled.div<any>`
  background-color: #02bc4d;
  color: #ffffff;
  border-radius: 10px;
  width: 140px !important;
  height: 36px;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  align-content: center;
  opacity: ${({ noJob }) => (noJob ? "0.5" : "1")};
`;

export const FormRegisterCV = styled.div`
  width: 100%;
  padding: 4% 0% 4% 12%;
  background-image: url(${registerCV_back});
  background-size: 130%;
  @media (max-width: 900px) {
    padding: 4% 4% 4% 4%;
    background-size: cover;
  }
`;

export const FormCV = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 32px;
  gap: 20px;
`;
export const ContentCV = styled.div`
  background-color: #ffffff;
  border-radius: 14px;
  width: 50%;
  height: 100%;
  padding: 0px 4% 2% 4%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //gap: 20px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: #eaeaea;
  }
`;

export const ButtonDrop = styled.a`
  font-weight: bold;
  color: #25c51b;
  cursor: pointer;
`;
