import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Parallax from "../../components/Parallax";
import {
  CheckForm,
  ConecthusLabs,
  Contact,
  ContactImg,
  ContactInfo,
  Form,
  FormContactUs,
  HelperText,
  LabsCardBack,
  LabsCardFront,
  LabsCards,
  LabsSoftware,
  PolicyPricacy,
  SubBack,
  SubContent,
  SubDownload,
  TitleContactInfo,
  TitleSub,
  WhereOperate,
} from "./styles";
import bg_back from "../../assets/Areas/bg_back.jpg";
import biologia from "../../assets/Areas/biologia.jpg";
import sub_biologia from "../../assets/Areas/sub_biologia.jpg";
import automacao from "../../assets/Areas/automacao.jpg";
import { Faixa } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import ArrowDownMain from "../../assets/About/ArrowDownMain.svg";
import labs_back from "./../../assets/Areas/labs_back.jpg";
import labs_sub_back from "./../../assets/Areas/labs_sub_back.jpg";
import downloadIcon from "./../../assets/Areas/download.svg";
import hardware from "./../../assets/Areas/hardware.jpg";
import sub_hardware from "./../../assets/Areas/sub_hardware.jpg";
import sub_automacao from "./../../assets/Areas/sub_automacao.jpg";
import testes from "./../../assets/Areas/testes.jpg";
import sub_testes from "./../../assets/Areas/sub_testes.jpg";
import biotecnologia from "./../../assets/Areas/biotecnologia.jpg";
import sub_biotecnologia from "./../../assets/Areas/sub_biotecnologia.jpg";
import engenharia from "./../../assets/Areas/engenharia.jpg";
import sub_engenharia from "./../../assets/Areas/sub_engenharia.jpg";
import produtos from "./../../assets/Areas/produtos.jpg";
import bg_back_areas from "./../../assets/Areas/bg_back_areas.png";
import circle_right_main from "../../assets/About/circle_right_main.svg";
import contact1 from "../../assets/Areas/contact/1.png";
import contact2 from "../../assets/Areas/contact/2.png";
import contact3 from "../../assets/Areas/contact/3.png";
import { Checkbox, TextField } from "@mui/material";
import MainBtn from "components/Buttons/MainBtn";
import { useTranslation } from "react-i18next";
import { countryList, statesList } from "utils/states-city";
import SelectMain from "components/SelectMain";
import {
  handleCellPhoneChange,
  handleEmailInputChange,
  isAnyFieldEmpty,
} from "utils/formatText";

interface AreaInterface {
  title: string;
  description: string;
  background: any;
  leftToRight: boolean;
  subBackground: any;
  subTitle: string;
  subDescription: string;
}

export default function Areas() {
  const { t } = useTranslation();
  const areas: Array<AreaInterface> = [
    {
      title: t("softwareInnovationLaboratory"),
      description: t("ourSoftwareInnovationLaboratory"),
      background: labs_back,
      leftToRight: false,
      subBackground: labs_sub_back,
      subTitle: t("softwareInnovationLaboratory"),
      subDescription: t("weResearchPrototypeDevelop"),
    },
    {
      title: t("hardwareDevelopmentLaboratory"),
      description: t("theConecthusInstituteHardware"),
      background: hardware,
      leftToRight: true,
      subBackground: sub_hardware,
      subTitle: t("hardwareLaboratory"),
      subDescription: t("theHardwareLaboratory"),
    },
    {
      title: t("industry4_0InnovationLab"),
      description: t("weIntegrateAdvanced"),
      background: biologia,
      leftToRight: false,
      subBackground: sub_biologia,
      subTitle: t("SMTLaboratory"),
      subDescription: t("theSMTLaboratory"),
    },
    {
      title: t("advancedIndustrialAutomationLab"),
      description: t("inThisSpaceOfCreation"),
      background: automacao,
      leftToRight: true,
      subBackground: sub_automacao,
      subTitle: t("advancedIndustrialAutomationLab"),
      subDescription: t("completeToolingForInhouse"),
    },
    {
      title: t("reliabilityTestingLaboratory"),
      description: t("reliabilityTestingPerformed"),
      background: testes,
      leftToRight: false,
      subBackground: sub_testes,
      subTitle: t("testingAndReliabilityLaboratory"),
      subDescription: t("theReliabilityLaboratoryVariety"),
    },
    {
      title: t("biotechnologyLaboratory"),
      description: t("theBiotechnologyLaboratory"),
      background: biotecnologia,
      leftToRight: true,
      subBackground: sub_biotecnologia,
      subTitle: t("biotechnologyLaboratory"),
      subDescription: t("theHardwareLaboratoryIsEpicenter"),
    },
    {
      title: t("mechanicalEngineeringLaboratory"),
      description: t("theConecthusInstitutemechanical"),
      background: engenharia,
      leftToRight: false,
      subBackground: sub_engenharia,
      subTitle: t("mechanicalEngineeringLaboratory"),
      subDescription: t("ourLaboratoryIsSpace"),
    },
    {
      title: t("productCreationDesignLaboratory"),
      description: t("theProductCreationandDesign"),
      background: produtos,
      leftToRight: true,
      subBackground: sub_engenharia,
      subTitle: t("creationandDesignLaboratory"),
      subDescription: t("theProductCreationandDesignLab"),
    },
  ];
  const [formContactUs, setContactUs] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    UF: "",
    company: "",
    sector: "",
    message: "",
    check: false,
  });
  const [formError, setFormError] = useState({
    errors: {
      email: "",
    },
  });
  const [UFList, setUFList] = useState<any>([]);
  const handleForm = () => {
    // console.log("form", formContactUs);
    const url = "https://api.conecthus.org.br/mailer/send";
    const params = {
      sender: "Fale_Conosco",
      receiver: "cso@conecthus.org.br",
      subject: "Formulario - Canal de Contato",
      message: `<h3>Segue o formulario de contato de ${formContactUs.name}</h3>
        <br>nome: ${formContactUs.name}</br>
        <p></p>
        <br>Email: ${formContactUs.email}</br>
        <p></p>
        <br>Telefone: ${formContactUs.phone}</br>
        <p></p>
        <br>País: ${formContactUs.country}</br>
        <p></p>
        <br>Estado: ${formContactUs.UF}</br>
        <p></p>
        <br>Empresa: ${formContactUs.company}</br>
        <p></p>
        <br>Cargo: ${formContactUs.sector}</br>
        <p></p>
        <br>Mensagem: ${formContactUs.message}</br>
        <p></p>
        
        `,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setContactUs({
          name: "",
          email: "",
          phone: "",
          country: "",
          UF: "",
          company: "",
          sector: "",
          message: "",
          check: false,
        });
        window.location.href = "#";
        const params = {
          sender: "Fale_Conosco",
          receiver: `${formContactUs.email}`,
          subject: "Confirmação de Recebimento - Conecthus",
          message: `<h3>Prezado(a) ${formContactUs.name}</h3><br>Agradecemos por entrar em contato conosco através do formulário em nosso website. Este e-mail é para confirmar que recebemos sua mensagem e estamos trabalhando para fornecer a melhor assistência.</b><br></br><br>Nossa equipe está analisando suas informações e faremos o possível para responder em breve.</br>
          <br></br><br>Atenciosamente,</br><br></br><br>Instituto Conecthus</br>`,
        };

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  //console.log("isValid", isAnyFieldEmpty(formContactUs));

  useEffect(() => {
    setUFList(statesList(formContactUs.country));
  }, [formContactUs.country]);
  return (
    <Layout>
      <Parallax
        background={bg_back}
        rightBottom
        title={t("innovationDifferentAreas")}
      />
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>
      <WhereOperate>
        <h1>
          <a
            className="header-anchor"
            href="#whereWeOperate"
            id="whereWeOperate"
          >
            #
          </a>
          {t("whereWeOperate")}
        </h1>
        <h3>
          <a
            className="header-anchor"
            href="#weWorkWithExtensiveExperience"
            id="weWorkWithExtensiveExperience"
          >
            #
          </a>
          {t("weWorkWithExtensiveExperience")}
        </h3>
      </WhereOperate>
      <ConecthusLabs>
        <h1>Conecthus Labs</h1>
        <div className="ConecthusLabsRow">
          <img src={ArrowDownMain} alt="" />
          <img src={ArrowDownMain} alt="" />
          <img src={ArrowDownMain} alt="" />
        </div>
      </ConecthusLabs>

      {areas.map((item: any, index: any) => (
        <LabsSoftware even={index % 2 === 0}>
          <h2>{item.title}</h2>
          <h3>{item.description}</h3>
          <LabsCards
            style={{
              paddingTop: !item.leftToRight ? 40 : 0,
            }}
          >
            <LabsCardBack
              left={item.leftToRight}
              background={item.background}
            ></LabsCardBack>
            <LabsCardFront right={item.leftToRight}>
              <SubBack labs_sub_back={item.subBackground}></SubBack>
              <TitleSub>{item.subTitle}</TitleSub>
              <SubContent>
                <h3>{item.subDescription}</h3>
                <SubDownload>
                  <h3>Portfólio Download</h3>
                  <img src={downloadIcon} alt="" />
                </SubDownload>
              </SubContent>
            </LabsCardFront>
          </LabsCards>
        </LabsSoftware>
      ))}

      <Contact background={bg_back_areas}>
        <ContactInfo>
          <TitleContactInfo>
            <img src={circle_right_main} alt="" height={36} />
            <h1>
              <a
                className="header-anchor"
                href="#getInTouchLetGo"
                id="getInTouchLetGo"
              >
                #
              </a>
              {t("getInTouchLetGo")}
            </h1>
          </TitleContactInfo>
          <ContactImg>
            <img src={contact1} alt="" />
            <img src={contact2} alt="" />
            <img src={contact3} alt="" />
          </ContactImg>
        </ContactInfo>

        <FormContactUs>
          <h1>{t("contactUs")}</h1>
          <h3>{t("welcomeOurContactChannel")}</h3>

          <Form>
            <TextField
              id="outlined-basic"
              label={t("name")}
              variant="outlined"
              value={formContactUs.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={formContactUs.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  email: event.target.value,
                }));
              }}
              onBlur={(e: any) =>
                handleEmailInputChange(e, setFormError, "email")
              }
              inputProps={{ maxLength: 100 }}
            />
            {formError.errors.email && (
              <HelperText>
                <a>{t(formError.errors.email)}</a>
              </HelperText>
            )}
            <TextField
              id="outlined-basic"
              label={t("phone")}
              variant="outlined"
              value={formContactUs.phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleCellPhoneChange(event, setContactUs, "phone");
              }}
              inputProps={{ maxLength: 12 }}
            />

            <SelectMain
              label={t("country")}
              items={countryList}
              value={formContactUs.country}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  country: event.target.value,
                }));
              }}
            />

            <SelectMain
              label={t("state")}
              items={UFList}
              value={formContactUs.UF}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  UF: event.target.value,
                }));
              }}
              disabled={!formContactUs.country}
            />
            <TextField
              id="outlined-basic"
              label={t("company")}
              variant="outlined"
              value={formContactUs.company}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  company: event.target.value,
                }));
              }}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              id="outlined-basic"
              label={t("sector")}
              variant="outlined"
              value={formContactUs.sector}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  sector: event.target.value,
                }));
              }}
              inputProps={{ maxLength: 20 }}
            />
            <TextField
              id="outlined-basic"
              label={t("yourMessage")}
              variant="outlined"
              value={formContactUs.message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setContactUs((prev: any) => ({
                  ...prev,
                  message: event.target.value,
                }));
              }}
              multiline
              maxRows={4}
              rows={4}
              inputProps={{ maxLength: 200 }}
            />
            <CheckForm>
              <Checkbox
                checked={formContactUs.check}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setContactUs((prev: any) => ({
                    ...prev,
                    check: event.target.checked,
                  }));
                }}
                sx={{
                  "&:hover": {
                    height: "100%",
                  },
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <PolicyPricacy>
                {t("institutoConecthuRequests")}
                <h3>{t("privacyPolicy")}</h3>.
              </PolicyPricacy>
            </CheckForm>
          </Form>
          <MainBtn
            onClick={handleForm}
            disabled={isAnyFieldEmpty(formContactUs) || !formContactUs.check}
            label={t("send")}
            variant="contained"
            padding="15px 40px"
          />
        </FormContactUs>
      </Contact>
    </Layout>
  );
}
