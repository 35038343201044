import styled from "styled-components";

export const Content = styled.div`
  border: 0.516px solid #373936;
  border-radius: 41px;
  padding: 8px 12px 8px 8px;
  display: flex;

  align-items: center;
  gap: 10px;
  //margin-left: 12px;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  input {
    transition: width 0.3s ease-in;
    height: 100%;
    width: 28px;
    border: none;
    outline: none;

    &:focus-visible {
      outline: none;
      width: 120px;
    }
    &.filled {
      width: 120px;
    }
  }
`;

export const CardContent = styled.div`
  position: absolute;
  background-color: #ffffff;
  height: auto;
  width: 220px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 4px;
  top: 44px;
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  padding: 5px 10px;
&:hover{
  background-color: #f1f6fc;
  .subtitle-content {
    color: #3080d0;
  }
}
  .title-content {
    font-size: 14px;
    color: black;
  }
  .subtitle-content {
    font-size: 12px;
    color: gray;
  }
`;
