import styled from "styled-components";

export const Content = styled.div`
  background-color: #f5f5f5;
  padding: 60px 12% 60px 12%;
  h1 {
    text-align: center;
    /* Heading/H1-Barlow - Bold - 48 - 57.6 */
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 57.6px; /* 120% */
    margin: 0;
    margin-bottom: 60px;
  }
`;
export const TooglePolicy = styled.div<any>`
  margin-bottom: 24px;
  background-color: #1a202c;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 38px 50px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;
export const TootleTitle = styled.div<any>`
  color: #02bc4d;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 38px;
  gap: 10px;
  img {
    transform: ${({ open }) => (open ? "rotate(-180deg)" : "none")};
  }
  @media (max-width: 1200px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
`;

export const TootleContent = styled.div`
  background-color: #ffffff;
  color: #1a202c;
  padding: 24px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  img {
    width: 100%;
    padding-bottom: 24px;
  }
`;

export const DescCol = styled.div`
  display: flex;
  flex-direction: column;
  ul {
    padding-left: 36px;
  }
  a {
    margin: 0;
    font-size: 20px;
    font-style: bold;
    font-weight: 500;
    line-height: 28px;
    color: black;
  }
  h3 {
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
  }
  .row {
    flex-direction: row;
  }
`;
