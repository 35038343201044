import styled from "styled-components";
export const ContactHeader = styled.div<any>`
  width: 100%;
  height: 85vh;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  background-attachment: local;
  display: flex;
  overflow: visible;
  text-align: start;
  position: relative;
  justify-content: flex-end;
  @media (max-width: 900px) {
    background-size: 248%;
    background-position: 32% 90%;
    height: 400px;
    text-align: center;
  }
`;
export const TitleContactHeader = styled.div<any>`
  position: absolute;
  //background-color: red;
  // height: 100%;
  width: 44%;
  bottom: 10%;
  left: 12%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  h3 {
    color: ${({ bottom }) => (bottom ? "#373936" : "white")};
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    margin: 0;
  }
  @media (max-width: 900px) {
    padding: 20px 69px;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    left: inherit;
    right: inherit;
    top: inherit;
    display: flex;
    text-align: center;
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
`;
export const Container = styled.div`
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.22px;
  color: white;
  margin-bottom: 14px;

  @media (max-width: 438px) {
    font-size: 13px;
  }
`;

export const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 200px;
  padding-right: 200px;

  @media (max-width: 858px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ContactContainer = styled.div`
  width: auto;
  height: 900px;
  display: flex;
  justify-content: center;
  margin-top: 63px;

  @media (max-width: 1054px) {
    height: 900px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContactLeft = styled.div`
  width: 707px;
  height: 440px;

  position: relative;
  overflow: hidden;
  border-radius: 10px;

  @media (max-width: 1054px) {
    width: 100%;
    height: 200px;
    margin-bottom: 40px;
  }
`;

export const ContactRight = styled.div`
  width: 30%;
  margin-left: 100px;

  @media (max-width: 1054px) {
    width: 70%;
    margin-left: 0px;
  }

  @media (max-width: 438px) {
    width: 100%;
    font-size: 10px;
    color: white;
  }
`;

export const ContactTitle = styled.p`
  font-size: 18px;
  letter-spacing: 1.26px;
  color: white;
  opacity: 0.77;

  @media (max-width: 438px) {
    font-size: 12px;
  }
`;

export const ContactInfo = styled.p`
  font-size: 16px;
  letter-spacing: 1.12px;
  color: white;
  opacity: 0.77;
  line-height: 2.5;

  @media (max-width: 438px) {
    font-size: 12px;
  }
`;

export const ContactInfoBold = styled.p`
  letter-spacing: 1.26px;
  font-size: 16px;
  color: white;
  font-weight: bold;
  opacity: 0.77;

  @media (max-width: 438px) {
    font-size: 12px;
  }
`;

export const ContactInfoBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ContactInfoIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 17px;
`;

export const ContactInfoFooter = styled.div`
  margin-top: 40px;

  @media (max-width: 1054px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 150px;
  text-align: center;
`;

export const SocialMediaIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const ContactFormContent = styled.div<any>`
  //height: 900px;
  width: 100%;
  padding: 5% 12% 2% 12%;
  background-image: url(${({ background }) => background});
  background-color: #222835;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: 40px 20px;
  }
`;

export const FormContact = styled.div`
  padding: 1% 2% 1% 2%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  border-radius: 14px;
  width: 50%;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 900px) {
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 20px;
  }
`;

export const FormInfoTitleContact = styled.div`
  width: 460px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 900px) {
    padding: 32px 20px;
    width: 100%;
    justify-content: center;

    align-items: center;
    img {
      width: 70%;
      //padding: 32px 20px ;
    }
  }
`;

export const TitleContact = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  h1 {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
  }
  img {
    width: auto;
  }
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    h1 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;
