import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  body {
    width: 100%;
    background-color:#FFFFFF;
    font-family: Barlow, sans-serif;
    }

  * {
    margin: 0;
    padding: 0;
    font-family: Barlow, sans-serif;
    }

    html {
      scroll-behavior: smooth;
      font-family: Barlow, sans-serif;
    }
h1,h2,h3,h4,h5{
  font-family: Barlow, sans-serif;
}
a{
  font-family: Barlow, sans-serif;
} 
.header-anchor{
 
  user-select: none;
    opacity: 0;
    float: left;
    user-select: none;
    -webkit-user-select: none;
    color: currentColor;
    cursor: inherit;
    margin-left: -.87em;
    padding-right: .23em;
    margin-top: .125em;
    &:hover{
      color: currentColor;
    }
}
`;

export default GlobalStyles;
