import { ReactElement } from "react";
import {
  ParallaxContainer,
  Title,
  TitleBottom,
  TitleLeftBottom,
  TitleRightBottom,
} from "./styles";

interface ParallaxInterface {
  background: string;
  title: string;
  icon?: any;
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  leftBottom?: boolean;
  rightBottom?: boolean;
}

export default function Parallax({
  background,
  title,
  icon,
  top,
  bottom,
  left,
  leftBottom,
  rightBottom,
}: ParallaxInterface): ReactElement {
  return (
    <div
      style={{
        // backgroundColor: "#000000d6",
        position: "relative",
        width: "100%",
        // height: "85vh",
      }}
    >
      <ParallaxContainer background={background} />
      {bottom ? (
        <TitleBottom left={left}>
          {icon && <img src={icon} height={100} alt="" />}
          <h3 >{title}</h3>
        </TitleBottom>
      ) : leftBottom ? (
        <TitleLeftBottom>
          {icon && <img src={icon} height={100} alt="" />}
          <h3>{title}</h3>
        </TitleLeftBottom>
      ) : rightBottom ? (
        <TitleRightBottom>
          {icon && <img src={icon} height={100} alt="" />}
          <h3>{title}</h3>
        </TitleRightBottom>
      ) : (
        <Title top={top} bottom={bottom}>
          {icon && <img src={icon} height={130} alt="" />}
          <h3>{title}</h3>
        </Title>
      )}
    </div>
  );
}
