/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Elipise } from "./BoxPtEn/style";

import { useTranslation } from "react-i18next";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../utils/local-storage.util";

export function MultiLanguage({isInputEnabled}:any) {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  useEffect(() => {
    if (language) {
      setCurrentLanguage();
    }
  }, [getLocalStorage("language")]);

  function setCurrentLanguage() {
    if (getLocalStorage("language")) {
      changeLanguage(getLocalStorage("language"));
    }
  }

  function handleChangeLanguage(event: React.MouseEvent<HTMLElement>) {
    if (language === "pt-BR") {
      changeLanguage("en-US");
      setLocalStorage("language", "en-US");
    } else {
      changeLanguage("pt-BR");
      setLocalStorage("language", "pt-BR");
    }
  }
  return (
    <Elipise onClick={handleChangeLanguage} isInputEnabled={isInputEnabled}>
      {language === "pt-BR" ? "PT" : "EN"}
    </Elipise>
  );
}
