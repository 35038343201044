import {
  Container,
  HomeScreen,
  HomeTitle,
  AboutDiv,
  ClientsDiv,
  LabsDiv,
  LabsWrapperBoxVideo,
  AreaActivity,
  AreaTitle,
  AreaCard,
  CardsArea,
  Chips,
  LabsTitle,
  ClientsCard,
  Escritorio,
  PartnersDiv,
  DividerPartiner,
  PartinersItems,
  OurDifferences,
  CardsOurDiff,
  CardOurDiff,
  LastCard,
  WorkUsDiv,
  WorkUsTitle,
  ArticleDiv,
  BoxVideos,
  SelosLogos,
  BulletsDiv,
  HomeButton,
  FxLeft,
  FxRight,
  ClientsCardButton,
  OurLabsTitle,
  ArticleContent,
} from "./styles";
import "semantic-ui-css/semantic.min.css"; ///
import "react-multi-carousel/lib/styles.css";
import MOSAICO from "../../assets/Home/MOSAICO.svg";
import Layout from "components/Layout";
import { IFrame, WrapperBoxVideo } from "pages/Services/styles";
import { useState } from "react";
import MainBtn from "../../components/Buttons/MainBtn";
import SELOSLOGOS from "../../assets/Home/selos_e_logos.svg";
import engMec from "../../assets/Home/areas/engMec.svg";
import p_d from "../../assets/Home/areas/engenhariaMec.svg";
import P_D from "../../assets/Home/areas/P_D.svg";
import soft_hard from "../../assets/Home/areas/soft_hard.svg";
import testes from "../../assets/Home/areas/testes.svg";
import selo_cursos from "../../assets/Home/areas/selo_cursos.svg";
import bio from "../../assets/Home/areas/bio.svg";
import design from "../../assets/Home/areas/design.svg";
import { Divider } from "@mui/material";
import BULLETS from "../../assets/Home/BULLETS.svg";
import CIGS from "../../assets/Home/partiners/CIGS.svg";
import elgin from "../../assets/Home/partiners/elgin.svg";
import denso from "../../assets/Home/partiners/denso.svg";
import fonxconn from "../../assets/Home/partiners/fonxconn.svg";
import compal from "../../assets/Home/partiners/compal.svg";
import suframa from "../../assets/Home/partiners/suframa.svg";
import kaon from "../../assets/Home/partiners/kaon.svg";
import unicoba from "../../assets/Home/partiners/unicoba.svg";
import tpv from "../../assets/Home/partiners/tpv.svg";
import vantiva from "../../assets/Home/partiners/vantiva.svg";
import tectoy from "../../assets/Home/partiners/tectoy.svg";
import adata from "../../assets/Home/partiners/adata.svg";
import dell from "../../assets/Home/partiners/dell.svg";
import philco from "../../assets/Home/partiners/philco.svg";
import amazoncopy from "../../assets/Home/partiners/amazoncopy.svg";
import amazoniaSmart from "../../assets/Home/partiners/amazoniaSmart.svg";
import digiboard from "../../assets/Home/partiners/digiboard.svg";
import jabil from "../../assets/Home/partiners/jabil.svg";
import stone from "../../assets/Home/partiners/stone.svg";
import luminicenter from "../../assets/Home/partiners/luminicenter.svg";
import samsung from "../../assets/Home/partiners/samsung.svg";
import idesam from "../../assets/Home/partiners/idesam.svg";
import humax from "../../assets/Home/partiners/humax.svg";
import constanta from "../../assets/Home/partiners/constanta.svg";
import vical from "../../assets/Home/partiners/vical.svg";
import salcomp from "../../assets/Home/partiners/salcomp.svg";
import Icone_Infra from "../../assets/Home/Icone_Infra.svg";
import Icone_Certificação from "../../assets/Home/Icone_Certificacao.svg";
import Icone_acessibilidade from "../../assets/Home/Icone_acessibilidade.svg";
import Icone_Novos_produtos from "../../assets/Home/Icone_Novos_produtos.svg";
import Icone_Expertise from "../../assets/Home/Icone_Expertise.svg";
import ClientsCarousel from "components/ClientsCarousel";
import { useTranslation } from "react-i18next";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import fx1 from "../../assets/Home/fx1.png";
import fx2 from "../../assets/Home/fx2.png";
import ArrowNarrow from "assets/ArrowNarrow";
import { useHistory } from "react-router-dom";
import { ArticleDescription } from "./components/Article/styles";

export default function Home() {
  const [isPlay, setisPlay] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();
  const areas = [
    {
      name: "Software & Hardware",
      icon: soft_hard,
      chips: ["tech", t("innovation"), "UX/UI"],
      desc: t("developInnovativeSolutions"),
      key: "developInnovativeSolutions",
      path: "",
    },
    {
      name: t("mechanicalEngineering"),
      icon: engMec,
      chips: [t("automation"), t("improvements"), "IA"],
      desc: t("developCompleteSolutions"),
      path: "",
    },
    {
      name: t("coursesAndTraining"),
      icon: selo_cursos,
      chips: [t("excellence"), t("training")],
      desc: t("provideSpecializedCourses"),
      path: "",
    },
    {
      name: t("reliabilityTestingServices"),
      icon: testes,
      chips: ["tech", t("industry"), t("quality")],
      desc: t("offerVarietyTechnological"),
      path: "",
    },

    {
      name: t("R_DInnovation"),
      icon: p_d,
      chips: ["tech", t("consultancy"), t("tactics")],
      desc: "Além de, soluções inovadoras no campo de pesquisa e desenvolvimento, também dispomos de consultoria especializada para os processos de P&D de sua empresa.",
      path: "",
    },
    {
      name: t("industrialAutomation"),
      icon: P_D,
      chips: ["tech", t("optimization"), "IA"],
      desc: t("designIndustrialAutomation"),
      path: "",
    },

    {
      name: t("bioeconomy"),
      icon: bio,
      chips: ["bio", t("innovation"), t("newProducts")],
      desc: t("developProductsTechnologies"),
      path: "",
    },
    {
      name: "Design-Driven Innovation",
      icon: design,
      chips: ["3D", t("newProducts"), "branding"],
      desc: t("developCreateSolutions"),
      path: "",
    },
  ];
  const partinetsIcons = [
    {
      name: "CIGS",
      icon: CIGS,
    },
    {
      name: "Elgin",
      icon: elgin,
    },
    {
      name: "denso",
      icon: denso,
    },
    {
      name: "fonxconn",
      icon: fonxconn,
    },
    {
      name: "compal",
      icon: compal,
    },
    {
      name: "suframa",
      icon: suframa,
    },
    {
      name: "kaon",
      icon: kaon,
    },
    {
      name: "unicoba",
      icon: unicoba,
    },
    {
      name: "tpv",
      icon: tpv,
    },
    {
      name: "vantiva",
      icon: vantiva,
    },
    {
      name: "tectoy",
      icon: tectoy,
    },
    {
      name: "adata",
      icon: adata,
    },
    {
      name: "dell",
      icon: dell,
    },
    {
      name: "philco",
      icon: philco,
    },
    {
      name: "amazoncopy",
      icon: amazoncopy,
    },
    {
      name: "amazoniaSmart",
      icon: amazoniaSmart,
    },
    {
      name: "digiboard",
      icon: digiboard,
    },
    {
      name: "jabil",
      icon: jabil,
    },
    {
      name: "stone",
      icon: stone,
    },
    {
      name: "luminicenter",
      icon: luminicenter,
    },
    {
      name: "samsung",
      icon: samsung,
    },
    {
      name: "idesam",
      icon: idesam,
    },
    {
      name: "humax",
      icon: humax,
    },
    {
      name: "constanta",
      icon: constanta,
    },
    {
      name: "vical",
      icon: vical,
    },
    {
      name: "salcomp",
      icon: salcomp,
    },
  ];

  const OurDifference = [
    {
      name: t("advancedLabInfrastructure"),
      icon: Icone_Infra,
      desc: t("laboratoriesEquipped"),
    },
    {
      name: t("certificationStandardizationSupport"),
      icon: Icone_Certificação,
      desc: t("forIndustriesCompanies"),
    },
    {
      name: t("accessibilityPartnerships"),
      icon: Icone_acessibilidade,
      desc: t("haveWideNetwork"),
    },
    {
      name: t("innovativeIdeasNewProducts"),
      icon: Icone_Novos_produtos,
      desc: t("oneOftheMainDifferences"),
    },
    {
      name: t("technicalScientificExpertise"),
      icon: Icone_Expertise,
      desc: t("teamHighlyQualified"),
    },
  ];

  return (
    <Layout>
      <Container>
        <HomeScreen>
          <HomeTitle>
            <h1>
              <a
                className="header-anchor"
                href="#softwareHardwareBio"
                id="softwareHardwareBio"
              >
                #
              </a>
              {t("softwareHardwareBio")}
            </h1>

            <h2>
              <a
                className="header-anchor"
                href="#innovationInTech"
                id="innovationInTech"
              >
                #
              </a>
              {t("innovationInTech")}
            </h2>
            <MainBtn
              onClick={() => history.push("/services")}
              label={t("discoverOurCompetencies")}
              variant={"main"}
            />
          </HomeTitle>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              position: "absolute",
              top: "97%",
            }}
          >
            <HomeButton>
              {t("navigateTheWebsite")}
              <img src={ArrowDown} alt="" />
            </HomeButton>
          </div>
        </HomeScreen>

        <AboutDiv>
          <ArticleDiv>
            <ArticleContent id="main">
              <div style={{ width: "351px", paddingBottom: 20 }}>
                <h1>
                  <a
                    className="header-anchor"
                    href="#discoverOurInstitute"
                    id="discoverOurInstitute"
                  >
                    #
                  </a>
                  {t("discoverOurInstitute")}
                </h1>
              </div>
              <ArticleDescription>
                <a
                  className="header-anchor"
                  href="#since2017TheConecthus"
                  id="since2017TheConecthus"
                >
                  #
                </a>
                {t("since2017TheConecthus")}
              </ArticleDescription>
            </ArticleContent>
            <div
              style={{
                height: 355,
              }}
            >
              <img src={MOSAICO} alt="" />
            </div>
          </ArticleDiv>

          <SelosLogos>
            <img src={SELOSLOGOS} alt="" />
          </SelosLogos>
        </AboutDiv>

        <AreaActivity>
          <AreaTitle>
            <h1>
              <a
                className="header-anchor"
                href="#discoverSomeAreasActivity"
                id="discoverSomeAreasActivity"
              >
                #
              </a>
              {t("discoverSomeAreasActivity")}
            </h1>
          </AreaTitle>
          <CardsArea>
            {areas.map((item: any) => (
              <AreaCard>
                <div>
                  <div className="title">
                    <h1>{item.name}</h1>
                    <img src={item.icon} alt="" />
                  </div>
                  <Divider sx={{ backgroundColor: "#D2D5DA", width: "100%" }} />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 10,
                      padding: " 7px 0px 7px 0px",
                      flexWrap: "wrap",
                    }}
                  >
                    {item.chips.map((i: any) => (
                      <Chips> {i}</Chips>
                    ))}
                  </div>

                  <h4>
                    <a
                      className="header-anchor"
                      href={`#${item.key}`}
                      id={item.key}
                    >
                      #
                    </a>
                    {item.desc}
                  </h4>
                </div>

                <div>
                  <MainBtn
                    label={t("learnMore")}
                    variant={"main"}
                    onClick={() => history.push("/areas")}
                    icon={<ArrowNarrow />}
                  />
                </div>
              </AreaCard>
            ))}
          </CardsArea>
        </AreaActivity>

        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <LabsDiv>
            <LabsTitle>
              <h1>
                <a
                  className="header-anchor"
                  href="#ourLaboratories"
                  id="ourLaboratories"
                >
                  #
                </a>
                {t("ourLaboratories")}
              </h1>
              <h4>
                <a
                  className="header-anchor"
                  href="#inASpaceOfMore"
                  id="inASpaceOfMore"
                >
                  #
                </a>
                {t("inASpaceOfMore")}
              </h4>
            </LabsTitle>

            <div
              style={{
                position: "relative",
              }}
            >
              <div style={{ position: "relative", zIndex: 99 }}>
                <BoxVideos>
                  <LabsWrapperBoxVideo>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <BulletsDiv>
                        <img src={BULLETS} alt="" width={"40%"} />
                      </BulletsDiv>

                      <OurLabsTitle>
                        <h1>{t("ourLaboratories")}</h1>
                      </OurLabsTitle>
                    </div>
                    <WrapperBoxVideo>
                      <IFrame
                        src="https://www.youtube.com/embed/m4nu8PmY-5E?si=kQiBZTpioYJ41A2g"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                        frameBorder={0}
                        isPlay={isPlay}
                      />
                      {/* <BoxVideoLab
                        background={video_back}
                        isPlay={isPlay}
                        onClick={handlePlayVideo}
                      >
                        <PlayIconVideo src={play_icon} />
                      </BoxVideoLab> */}
                    </WrapperBoxVideo>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          color: "red",
                          bottom: "0",
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                  </LabsWrapperBoxVideo>

                  <h2>
                    <a
                      className="header-anchor"
                      href="#withSuccessInMore"
                      id="withSuccessInMore"
                    >
                      #
                    </a>
                    {t("withSuccessInMore")}
                  </h2>

                  <LabsWrapperBoxVideo>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }}
                    >
                      <BulletsDiv>
                        <img src={BULLETS} alt="" width={"40%"} />
                      </BulletsDiv>

                      <OurLabsTitle>
                        <h1>
                          <a
                            className="header-anchor"
                            href="#institutionalVideoTour"
                            id="institutionalVideoTour"
                          >
                            #
                          </a>
                          {t("institutionalVideoTour")}
                        </h1>
                      </OurLabsTitle>
                    </div>
                    <WrapperBoxVideo>
                      <IFrame
                        src="https://www.youtube.com/embed/oxJS7Eqny5Q?si=wJhQnddDTn97QDwN"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                        isPlay={isPlay}
                      />
                    </WrapperBoxVideo>
                  </LabsWrapperBoxVideo>
                </BoxVideos>
              </div>
              <FxLeft>
                <img src={fx1} alt="" />
              </FxLeft>
              <FxRight>
                <img src={fx2} alt="" />
              </FxRight>
            </div>
          </LabsDiv>
        </div>

        <Escritorio></Escritorio>

        <ClientsDiv>
          <ClientsCard>
            <div>
              <h3>
                <a
                  className="header-anchor"
                  href="#readyBoostYourBusiness"
                  id="readyBoostYourBusiness"
                >
                  #
                </a>
                {t("readyBoostYourBusiness")}
              </h3>
              <h3>{t("workTogether")}</h3>
            </div>
            <ClientsCardButton onClick={() => history.push("/areas")}>
              <a className="header-anchor" href="#talkToUs" id="talkToUs">
                #
              </a>
              {t("talkToUs")}
              <ArrowNarrow />
            </ClientsCardButton>
          </ClientsCard>
        </ClientsDiv>
        <PartnersDiv>
          <h1>
            <a
              className="header-anchor"
              href="#theFocusOnDelivery"
              id="theFocusOnDelivery"
            >
              #
            </a>
            {t("theFocusOnDelivery")}
          </h1>
          <h2>
            <a
              className="header-anchor"
              href="#customersAndPartners"
              id="customersAndPartners"
            >
              #
            </a>
            {t("customersAndPartners")}
          </h2>
          <DividerPartiner />
          <PartinersItems>
            {partinetsIcons.map((item: any) => (
              <div className="item">
                <img src={item.icon} alt={item.name} />
              </div>
            ))}
          </PartinersItems>
          <div
            style={{
              width: "100%",
              paddingTop: 60,
            }}
          >
            <DividerPartiner />
          </div>
        </PartnersDiv>

        <div>
          <ClientsCarousel />
        </div>

        <OurDifferences>
          <h1>{t("ourDifferences")}</h1>
          <CardsOurDiff>
            {OurDifference.map((item: any) => (
              <CardOurDiff>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                  }}
                >
                  <div className="title">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <img src={item.icon} alt="" />
                      <DividerPartiner />
                    </div>
                    <h1>{item.name}</h1>
                  </div>

                  <h4>{item.desc}</h4>
                </div>
              </CardOurDiff>
            ))}
            <LastCard>
              <h1>
                <a
                  className="header-anchor"
                  href="#boostYourBusiness"
                  id="boostYourBusiness"
                >
                  #
                </a>
                {t("boostYourBusiness")}
              </h1>

              <MainBtn
                label={t("discoverOurCompetencies")}
                variant={"main"}
                onClick={() => history.push("/areas")}
              />
            </LastCard>
          </CardsOurDiff>
        </OurDifferences>

        <WorkUsDiv>
          <WorkUsTitle>
            <h2>
              <a
                className="header-anchor"
                href="#comeJoinOurTeam"
                id="comeJoinOurTeam"
              >
                #
              </a>
              {t("comeJoinOurTeam")}
            </h2>
            <h2> {t("comeJoinOurTeam2")}</h2>
          </WorkUsTitle>

          <MainBtn
            label={t("workWithUs")}
            variant="outlined"
            onClick={() => history.push("/contact")}
          />
        </WorkUsDiv>
      </Container>
    </Layout>
  );
}
