const routesMap:any = {
    softwareHardwareBio: ['#softwareHardwareBio',"/"],
    innovationInTech: ["#innovationInTech",'/'],
    discoverOurCompetencies:["#discoverOurCompetencies",'/'],
    navigateTheWebsite:["#navigateTheWebsite","/"],
    discoverOurInstitute:["#discoverOurInstitute","/"],
    since2017TheConecthus:["#since2017TheConecthus","/"],
    discoverSomeAreasActivity:["#discoverSomeAreasActivity","/"],
    learnMore:["#learnMore","/"],
    ourLaboratories:["#ourLaboratories","/"],
    inASpaceOfMore:["#inASpaceOfMore","/"],
    withSuccessInMore:["#withSuccessInMore","/"],
    institutionalVideoTour:["#institutionalVideoTour","/"],
    readyBoostYourBusiness:["#readyBoostYourBusiness","/"],
    workTogether:["#workTogether","/"],
    talkToUs:["#talkToUs","/"],
    theFocusOnDelivery:["#theFocusOnDelivery","/"],
    customersAndPartners:["#customersAndPartners","/"],
    ourDifferences:["#ourDifferences","/"],
    boostYourBusiness:["#boostYourBusiness","/"],
    comeJoinOurTeam:["#comeJoinOurTeam","/"],
    comeJoinOurTeam2:["#comeJoinOurTeam2","/"],
    workWithUs:["#workWithUs","/"],
    developInnovativeSolutions:["#developInnovativeSolutions","/"],
    reliabilityTestingServices:["reliabilityTestingServices","/"],
    whoWeAre:["#whoWeAre","about"],
    areAnInstituteAccredited:["#areAnInstituteAccredited","about"],
    multidisciplinaryResearch:["#multidisciplinaryResearch","about"],
    ourMainCommitmentIsInnovation:["#ourMainCommitmentIsInnovation", "about"],
    whereAreWe:["#whereAreWe","about"],
    partners:["#partners","about"],
    ourTechnologyPartners:["#ourTechnologyPartners","about"],
    weAreTeamInnovators:["#weAreTeamInnovators","about"],
    contactUsFind:["#contactUsFind","about"],
    qualityPolicy:["#qualityPolicy","about"],
    mission:["#mission","about"],
    designDevelopExecute:["#designDevelopExecute","about"],
    vision:["#vision","about"],
    beGlobalReference:["#beGlobalReference","about"],
    values:["#values","about"],
    delivery:["#delivery","about"],
    weUnderstandHowDeliver:["#weUnderstandHowDeliver","about"],
    criativityInnovation:["#criativityInnovation","about"],
    toBeGlobalReference:["#toBeGlobalReference","about"],
    unconditionalRespectDiversity:["#unconditionalRespectDiversity","about"],
    teamWork:["#teamWork","about"],
    weAreCommittedUniting:["#weAreCommittedUniting","about"],
    theConecthusInstituteOperates:["#theConecthusInstituteOperates","about"],
    qualityObjectives:["#qualityObjectives","about"],
    readyBoosYourBusiness:["#readyBoosYourBusiness","about"],
    whereWeOperate:["#whereWeOperate","areas"],
    weWorkWithExtensiveExperience:["#weWorkWithExtensiveExperience","areas"],
    getInTouchLetGo:["#getInTouchLetGo","areas"],
    serviceWith:["#serviceWith","services"],
    infrastructureTeam:["#infrastructureTeam","services"],
    specializedGuarantee:["#specializedGuarantee","services"],
    reliabilityLaboratory:["#reliabilityLaboratory","services"],
    reliabilityTestingPerformed:["#reliabilityTestingPerformed","services"],
    reliabilityTestingLaboratory:["#reliabilityTestingLaboratory","services"],
    theConecthusInstituteHardware:["#theConecthusInstituteHardware","services"],
    testingAndReliabilityLaboratory:["#testingAndReliabilityLaboratory","services"],
    theReliabilityLaboratoryVariety:["#theReliabilityLaboratoryVariety","services"],
    speakToOneurExperts:["#speakToOneurExperts","services"],
    scheduleVisitAndSee:["#scheduleVisitAndSee","services"],
    fillOutTheForm:["#fillOutTheForm","services"],
    powerYourStartupWith:["#powerYourStartupWith","smartHub"],
    coworkingandBusinessIncubation:["#coworkingandBusinessIncubation","smartHub"],
    throughTheIncubationProgram:["#throughTheIncubationProgram","smartHub"],
    checkOutTheStartups:["#checkOutTheStartups","smartHub"],
    ourIncubationProgram:["#ourIncubationProgram","smartHub"],
    weAreInspiredEverYday:["#weAreInspiredEverYday","career"],
    ourCulture:["#ourCulture","career"],
    weRecognizeAndAdmire:["#weRecognizeAndAdmire","career"],
    discoverOurBenefits:["#discoverOurBenefits","career"],
    inDynamicAndInnovative:["#inDynamicAndInnovative","career"],
    whorkAtConecthus:["#whorkAtConecthus","career"],
    aPlaceThatBringTogether:["#aPlaceThatBringTogether","career"],
    findJobOpportunities:["#findJobOpportunities","career"],
    registerYourCV:["#registerYourCV","career"],
    coursesWorkshopsTrainings:["#coursesWorkshopsTrainings","academy"],
    structureReadyEmpower:["#structureReadyEmpower","academy"],
    believeThatInnovationBegins:["#believeThatInnovationBegins","academy"],
    seeOurCoursesTraining:["#seeOurCoursesTraining","academy"],
    WesternAmazon:["#WesternAmazon","academy"],
    letTurnIdeaIntoReality:["#letTurnIdeaIntoReality","academy"],
    getTouchAndBook:["#getTouchAndBook","academy"],
    connectToTheFutureAcademy:["#connectToTheFutureAcademy","academy"],
    stayConnected:["#stayConnected","communication"],
    withOur:["#withOur","communication"],
    news:["#withOur","communication"],
    stayUpToDate:["#stayUpToDate","communication"],
    helloletConnect:["#helloletConnect","contact"],
    welcomeOurContactChannel:["#welcomeOurContactChannel","contact"],
    weAreHereToHelp:["#weAreHereToHelp","contact"],

    

  };
  
  export default routesMap;