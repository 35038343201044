import Layout from "../../components/Layout";
import { Faixa } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import background from "../../assets/Contact/background.jpg";
import { CheckForm, Form, HelperText, PolicyPricacy } from "pages/Areas/styles";
import conecthus_vector from "../../assets/SmartHub/conecthus_vector.svg";
import { Checkbox, TextField } from "@mui/material";
import MainBtn from "components/Buttons/MainBtn";
import { useState } from "react";
import circle_right_main from "../../assets/About/circle_right_main.svg";
import { useTranslation } from "react-i18next";
import {
  handleCellPhoneChange,
  handleEmailInputChange,
  isAnyFieldEmpty,
} from "utils/formatText";
import {
  ContactFormContent,
  ContactHeader,
  FormContact,
  FormInfoTitleContact,
  TitleContact,
  TitleContactHeader,
} from "./styles";

export default function ContactUS() {
  const { t } = useTranslation();
  const [formContactUs, setFormContactUs] = useState({
    name: "",
    email: "",
    phone: "",

    message: "",
    check: false,
  });
  const [formError, setFormError] = useState({
    errors: {
      email: "",
    },
  });

  //const [UFList, setUFList] = useState<any>([]);
  const handleForm = () => {
    // console.log("form", formContactUs);
    const url = "https://api.conecthus.org.br/mailer/send";
    const params = {
      sender: "FaleConosco_Conecthus",
      receiver: "ouvidoria@conecthus.org.br",
      subject: "Formulario - Fale Conosco",
      message: `<h3>Segue o formulario de contato de ${formContactUs.name}</h3>
        <br>nome: ${formContactUs.name}</br>
        <p></p>
        <br>Email: ${formContactUs.email}</br>
        <p></p>
        <br>Telefone: ${formContactUs.phone}</br>
        <p></p>
        <br>Mensagem: ${formContactUs.message}</br>
        <p></p>
        
        `,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setFormContactUs({
          name: "",
          email: "",
          phone: "",

          message: "",
          check: false,
        });
        window.location.href = "#";
        const params = {
          sender: "FaleConosco_Conecthus",
          receiver: `${formContactUs.email}`,
          subject: "Confirmação de Recebimento - Conecthus",
          message: `<h3>Prezado(a) ${formContactUs.name}</h3>
          <br>Agradecemos por entrar em contato conosco através do formulário em nosso website. 
          Este e-mail é para confirmar que recebemos sua mensagem e estamos trabalhando para fornecer 
          a melhor assistência.</b><br></br>
          <br>Nossa equipe está analisando suas informações e faremos o possível para responder em breve.</br><br>
          </br><br>Atenciosamente,</br><br></br>
          <br>Instituto Conecthus</br>`,
        };

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <Layout>
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <ContactHeader background={background}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TitleContactHeader>
              <h3>
                <a
                  className="header-anchor"
                  href="#helloletConnect"
                  id="helloletConnect"
                >
                  #
                </a>
                {t("helloletConnect")}
              </h3>
            </TitleContactHeader>
          </div>
        </ContactHeader>
      </div>

      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>

      <ContactFormContent>
        <FormContact>
          <h1 style={{ textAlign: "center", padding: 20 }}>{t("contactUs")}</h1>
          <h3>
            <a
              className="header-anchor"
              href="#welcomeOurContactChannel"
              id="welcomeOurContactChannel"
            >
              #
            </a>
            {t("welcomeOurContactChannel")}
          </h3>

          <Form>
            <TextField
              id="outlined-basic"
              label={t("name")}
              variant="outlined"
              value={formContactUs.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormContactUs((prev: any) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
              onBlur={(e: any) =>
                handleEmailInputChange(e, setFormError, "email")
              }
              inputProps={{ maxLength: 100 }}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={formContactUs.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormContactUs((prev: any) => ({
                  ...prev,
                  email: event.target.value,
                }));
              }}
              onBlur={(e: any) =>
                handleEmailInputChange(e, setFormError, "email")
              }
              inputProps={{ maxLength: 100 }}
            />
            {formError.errors.email && (
              <HelperText>
                <a>{t(formError.errors.email)}</a>
              </HelperText>
            )}
            <TextField
              id="outlined-basic"
              label={t("phone")}
              variant="outlined"
              value={formContactUs.phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleCellPhoneChange(event, setFormContactUs, "phone");
              }}
              inputProps={{ maxLength: 12 }}
            />

            <TextField
              id="outlined-basic"
              label={t("yourMessage")}
              variant="outlined"
              value={formContactUs.message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormContactUs((prev: any) => ({
                  ...prev,
                  message: event.target.value,
                }));
              }}
              multiline
              maxRows={4}
              rows={4}
              inputProps={{ maxLength: 200 }}
            />
            <CheckForm>
              <Checkbox
                checked={formContactUs.check}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormContactUs((prev: any) => ({
                    ...prev,
                    check: event.target.checked,
                  }));
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <PolicyPricacy>
                <>{t("institutoConecthuRequests")} </>
                <span className="privacy-policy">{t("privacyPolicy")}</span>.
              </PolicyPricacy>
            </CheckForm>
          </Form>
          <MainBtn
            onClick={handleForm}
            disabled={isAnyFieldEmpty(formContactUs) || !formContactUs.check}
            label={t("send")}
            variant="contained"
            padding="15px 40px"
          />
        </FormContact>
        <FormInfoTitleContact>
          <TitleContact>
            <img src={circle_right_main} alt="" height={36} />
            <h1>
              <a
                className="header-anchor"
                href="#weAreHereToHelp"
                id="weAreHereToHelp"
              >
                #
              </a>
              {t("weAreHereToHelp")}
            </h1>
          </TitleContact>

          <img src={conecthus_vector} alt="" />
        </FormInfoTitleContact>
      </ContactFormContent>
    </Layout>
  );
}
