import styled from "styled-components";
export const AcademyHeader = styled.div<any>`
  width: 100%;
  height: 85vh;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  background-attachment: local;
  display: flex;
  overflow: visible;
  text-align: start;
  position: relative;
  justify-content: flex-end;
  @media (max-width: 900px) {
    background-size: 170%;
    background-position: 47% 98%;
    height: 400px;
    /* padding: 20px 20px; */
    text-align: center;
  }
`;
export const TitleAcademyHeader = styled.div<any>`
  position: absolute;
  width: 100%;
  top: auto;
  bottom: 24%;
  left: auto;
  right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: #373936;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    margin: 0;
  }
  @media (max-width: 900px) {
    padding: 40px 30px;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: 10%;
    display: flex;
    text-align: center;
    h3 {
      text-wrap: balance;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
  }
`;
export const BgContent = styled.div<any>`
  background-image: url(${({ background }) => background});
  background-size: 121%;
  border-radius: 10px;
  background-attachment: local;
  position: relative;
  background-position: 0% 43%;
  h1 {
    height: 100%;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    color: #ffffff;
  }
  @media (max-width: 900px) {
    padding: 0;
    border-radius: 0;
    background-position: 47% 98%;
    background-size: cover;
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
    }
  }
`;
export const BgBack = styled.div`
  padding: 12% 15% 12% 7%;
  height: 100%;
  background-color: #00000061;
  border-radius: 10px;
  @media (max-width: 900px) {
    padding: 140px 30px;
  }
`;
export const Content = styled.div`
  margin: 4% 12% 4% 12%;

  h2 {
    padding: 2% 4% 0% 4%;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 126.667% */
    letter-spacing: 0.18px;
    margin: 0;
  }
  @media (max-width: 900px) {
    margin: 0;

    h2 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      padding: 32px 20px;
    }
  }
`;
export const OurCourses = styled.div`
  background-color: #f5f5f5;
  padding: 4% 12% 4% 12%;
  h1 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    margin: 0 0 45px 0;
  }
  @media (max-width: 1200px) {
    padding: 0;
    h1 {
      padding: 32px 20px;
      text-align: center;
      background-color: #1a202c;
      color: #08d040;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 126.667% */
      letter-spacing: 0.18px;
    }
  }
`;
export const Cards = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 70px;
  row-gap: 30px;

  @media (max-width: 1200px) {
    margin: 20px;
    display: flex;
    flex-direction: column;
  }
`;
export const CardCourse = styled.div<any>`
  border-radius: 10px;
  width: 100%;
  height: 406px;
  background-image: url(${({ background }) => background});
  background-size: 150%;
  background-position: center;
  border-radius: 10px;
  transition: background-size 0.3s ease; /* Adicionando uma transição suave */
  // overflow: hidden;
  border-bottom: 100px solid #273450;
  position: relative;
  &:hover {
    background-size: 190%; /* Zoom in no hover */
  }
  h3 {
    position: absolute;
    top: 112%;
    color: white;
    display: flex;
    width: 100%;
    padding: 0px 14px;
    justify-content: center;
    font-weight: 500;
    margin: 0;
  }
`;

export const GetInContact = styled.div<any>`
  width: 100%;
  // height: 900px;
  padding: 5% 12% 2% 12%;
  background-image: url(${({ background }) => background});
  // background-color: #222835;
  background-size: cover;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 0px 20px 32px 20px;
  }
`;

export const ContentForm = styled.div`
  background-color: #ffffff;
  border-radius: 14px;
  width: 70%;
  height: 100%;
  padding: 0px 4% 2% 4%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //gap: 20px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
export const FormContact = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 32px;
  gap: 20px;
`;
export const TitleFormContact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  //padding: 52px 0px;
  h1 {
    color: #08d040;
    font-size: 48px;
    font-weight: 600;
    width: 70%;
    margin: 0;
  }
  @media (max-width: 1200px) {
    padding: 52px 35px;
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px; /* 126.667% */
      letter-spacing: 0.18px;
      width: 100%;
    }
  }
`;
