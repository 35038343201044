import React, { useState } from "react";
import Layout from "../../components/Layout";
import { Faixa } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import smart_background from "../../assets/SmartHub/smart_background.png";
import conecthus_hub from "../../assets/SmartHub/conecthus_hub.svg";
import conecthus_smartHub from "../../assets/SmartHub/conecthus_smartHub.jpeg";
import card_back from "../../assets/SmartHub/card_back.png";
import Foto_hub_1 from "../../assets/SmartHub/Foto_hub_1.png";
import Foto_hub_2 from "../../assets/SmartHub/Foto_hub_2.png";
import Foto_hub_3 from "../../assets/SmartHub/Foto_hub_3.png";
import conecthus_vector from "../../assets/SmartHub/conecthus_vector.svg";
import {
  CardImg,
  CardLearnMore,
  CardsSection,
  Chip,
  ChipsStatups,
  ContactInfoHubs,
  CoworkingContent,
  IconsList,
  ImgGroup,
  ImgRowGroup,
  SmartHubHeader,
  StartupList,
  TitleContactInfoHubs,
  TitleHeader,
  TitleList,
} from "./styles";
import energi from "../../assets/SmartHub/startups/energi.svg";
import gigas_pro from "../../assets/SmartHub/startups/gigas_pro.svg";
import flameseed from "../../assets/SmartHub/startups/flameseed.svg";
import aurelio from "../../assets/SmartHub/startups/aurelio.svg";
import aqua_pia from "../../assets/SmartHub/startups/aqua_pia.svg";
import brservice from "../../assets/SmartHub/startups/brservice.svg";
import tchibum from "../../assets/SmartHub/startups/tchibum.svg";
import MainBtn from "components/Buttons/MainBtn";
import circle_right_main from "../../assets/About/circle_right_main.svg";
import {
  CheckForm,
  Contact,
  Form,
  FormContactUs,
  HelperText,
  PolicyPricacy,
} from "pages/Areas/styles";
import { Checkbox, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  handleCellPhoneChange,
  handleEmailInputChange,
  isAnyFieldEmpty,
} from "utils/formatText";

function SmartHub() {
  const { t } = useTranslation();
  const [formStartup, setformStartup] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
    check: false,
  });
  const [formError, setFormError] = useState({
    errors: {
      email: "",
    },
  });

  const handleForm = () => {
    // console.log("form", formStartup);
    const url = "https://api.conecthus.org.br/mailer/send";
    const params = {
      sender: "SmartHub_Conecthus",
      receiver: "ouvidoria@conecthus.org.br",
      subject: "Formulario - SmartHub",
      message: `<h3>Segue o formulario de contato de ${formStartup.name}</h3>
        <br>nome: ${formStartup.name}</br>
        <p></p>
        <br>Email: ${formStartup.email}</br>
        <p></p>
        <br>Telefone: ${formStartup.phone}</br>
        <p></p>
        <br>Empresa: ${formStartup.company}</br>
        <p></p>
        <br>Mensagem: ${formStartup.message}</br>
        <p></p>
        
        `,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setformStartup({
          name: "",
          email: "",
          phone: "",
          company: "",
          message: "",
          check: false,
        });
        window.location.href = "#";
        const params = {
          sender: "SmartHub_Conecthus",
          receiver: `${formStartup.email}`,
          subject: "Confirmação de Recebimento - Conecthus",
          message: `<h3>Prezado(a) ${formStartup.name}</h3>
          <br>Agradecemos por entrar em contato conosco através do formulário em nosso website. 
          Este e-mail é para confirmar que recebemos sua mensagem e estamos trabalhando para fornecer 
          a melhor assistência.</b><br></br>
          <br>Nossa equipe está analisando suas informações e faremos o possível para responder em breve.</br><br>
          </br><br>Atenciosamente,</br><br></br>
          <br>Instituto Conecthus</br>`,
        };

        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <Layout>
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <SmartHubHeader background={smart_background}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TitleHeader>
              <img src={conecthus_hub} height={100} alt="" />

              <h3>
                <a
                  className="header-anchor"
                  href="#powerYourStartupWith"
                  id="powerYourStartupWith"
                >
                  #
                </a>
                {t("powerYourStartupWith")}
              </h3>
            </TitleHeader>
          </div>
        </SmartHubHeader>
      </div>

      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>
      <CoworkingContent>
        <h1>
          <a
            className="header-anchor"
            href="#coworkingandBusinessIncubation"
            id="coworkingandBusinessIncubation"
          >
            #
          </a>
          {t("coworkingandBusinessIncubation")}
        </h1>
        <h3>
        <a
            className="header-anchor"
            href="#throughTheIncubationProgram"
            id="throughTheIncubationProgram"
          >
            #
          </a>
          {t("throughTheIncubationProgram")}</h3>
        <img src={conecthus_smartHub} alt="" />
      </CoworkingContent>
      <StartupList>
        <TitleList>
        <a
            className="header-anchor"
            href="#checkOutTheStartups"
            id="checkOutTheStartups"
          >
            #
          </a>
          {t("checkOutTheStartups")}
          <h2>
             <a
            className="header-anchor"
            href="#ourIncubationProgram"
            id="ourIncubationProgram"
          >
            #
          </a>
            {t("ourIncubationProgram")}</h2>
        </TitleList>
        <IconsList>
          <img src={energi} alt="" />
          <img src={gigas_pro} alt="" />
          <img src={flameseed} alt="" />
          <img src={aurelio} alt="" />
          <img src={aqua_pia} alt="" />
          <img src={brservice} alt="" />
          <img src={tchibum} alt="" />
        </IconsList>
      </StartupList>

      <CardsSection>
        <CardLearnMore>
          <CardImg background={card_back}></CardImg>
          <ChipsStatups>
            <Chip>Sturtup</Chip>
            <Chip>{t("incubator")}</Chip>
            <Chip>{t("technology")}</Chip>
          </ChipsStatups>
          <h1>{t("readyBoostYourBusiness")}</h1>
          <MainBtn label={t("learnMore")} />
        </CardLearnMore>
        <ImgGroup>
          <ImgRowGroup>
            <img src={Foto_hub_1} alt="" />
            <img src={Foto_hub_2} alt="" />
          </ImgRowGroup>
          <img src={Foto_hub_3} alt="" />
        </ImgGroup>
      </CardsSection>
      <Contact>
        <ContactInfoHubs>
          <TitleContactInfoHubs size="30px">
            <img src={circle_right_main} alt="" height={36} />
            <h1>{t("wantToBoostYouCompany")}</h1>
            <h2>{t("letTurnIdeaIntoReality")}</h2>
          </TitleContactInfoHubs>

          <img src={conecthus_vector} alt="" />
        </ContactInfoHubs>

        <FormContactUs>
          <h1 style={{ textAlign: "center", padding: 20 }}>
            {t("connectToTheFuture")}
          </h1>

          <Form>
            <TextField
              id="outlined-basic"
              label={t("name")}
              variant="outlined"
              value={formStartup.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setformStartup((prev: any) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={formStartup.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setformStartup((prev: any) => ({
                  ...prev,
                  email: event.target.value,
                }));
              }}
              onBlur={(e: any) =>
                handleEmailInputChange(e, setFormError, "email")
              }
              inputProps={{ maxLength: 100 }}
            />
            {formError.errors.email && (
              <HelperText>
                <a>{t(formError.errors.email)}</a>
              </HelperText>
            )}
            <TextField
              id="outlined-basic"
              label={t("phone")}
              variant="outlined"
              value={formStartup.phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleCellPhoneChange(event, setformStartup, "phone");
              }}
              inputProps={{ maxLength: 12 }}
            />

            <TextField
              id="outlined-basic"
              label={t("company")}
              variant="outlined"
              value={formStartup.company}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setformStartup((prev: any) => ({
                  ...prev,
                  company: event.target.value,
                }));
              }}
              inputProps={{ maxLength: 50 }}
            />

            <TextField
              id="outlined-basic"
              label={t("yourMessage")}
              variant="outlined"
              value={formStartup.message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setformStartup((prev: any) => ({
                  ...prev,
                  message: event.target.value,
                }));
              }}
              multiline
              maxRows={4}
              rows={4}
              inputProps={{ maxLength: 200 }}
            />
            <CheckForm>
              <Checkbox
                checked={formStartup.check}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setformStartup((prev: any) => ({
                    ...prev,
                    check: event.target.checked,
                  }));
                }}
                sx={{
                  "&:hover": {
                    height: "100%",
                  },
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <PolicyPricacy>
                {t("institutoConecthuRequests")}
                <h3>{t("privacyPolicy")}</h3>.
              </PolicyPricacy>
            </CheckForm>
          </Form>
          <MainBtn
            onClick={handleForm}
            disabled={isAnyFieldEmpty(formStartup) || !formStartup.check}
            label={t("send")}
            variant="contained"
            padding="15px 40px"
          />
        </FormContactUs>
      </Contact>
    </Layout>
  );
}

export default SmartHub;
