import styled, { css } from "styled-components";

export const Box = styled.a`
  //width: 190px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
/* 
  :hover {
    transform: scale(1.1);
    opacity: 0.9;
  } */
/* 
  @media (max-width: 546px) {
    width: 150px;
    height: 42px;
  } */
`;

export const Icon = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  margin-left: 15px;
`;

export const Button = styled.button<any>`
 // width: 100%;
  /*   height: 100%; */
  padding:${({ padding }) => (padding ? padding :"15px")};
  color: ${({ variant }) => (variant==="contained" ? "#FFFFFF" : variant ==="main"? "#08D040":"#08D040")};
  //font-size: 20px;
  font-weight: bold;
  background: ${({ variant }) => (variant==="contained" ? "#08D040" : variant ==="main"? "#000000" : "transparent")};
  border:  ${({ variant }) => (variant ==="outlined" ? "1px solid white" : variant ==="main"? "none": "1px solid #02BC4D")};
  border-radius: 9px;
  letter-spacing: 1.4px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" :"pointer")};
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 40px;

&:hover{
  background-color:#08D040;
  color: black;
  border: none;
}
`;
