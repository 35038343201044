import { Country ,State } from 'country-state-city';

export const countryList = Country.getAllCountries().map(country => ({
    value: country.isoCode,
    name: `${country.name} - ${country.isoCode}`
  }))
export const statesList = (country:any)=>{
    return State.getStatesOfCountry(country).map(state => ({
        value: state.isoCode,
        name: `${state.name} - ${state.isoCode}`
      }))
}