import styled, { css } from "styled-components";

export const Container = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 307px;
  background: white;
  padding: 20px 12% 100px 12%;
  /* display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-top: 20px; */
  /* 
  @media (max-width: 1300px) {
    width: 100%;
    height: 800px;
  } */
`;

export const Title = styled.p`
  // font: normal normal 600 22px arial;
  letter-spacing: 0px;
  color: #ffffff;
  //margin-left: -900px;
  text-align: center;
  h1 {
    font: normal normal 600 16px Barlow;
    color: #08d040;
    margin: 0;
  }
  h2 {
    font: normal normal 600 16px Barlow;
    color: #000000;
    margin: 0;
  }

  /* @media(max-width: 1731px) {
        margin-left: -700px;
   }

   @media(max-width: 1474px) {
       margin-left: 0px;
   }

   @media(max-width: 1300px) {
       font-size: 18px;
   }

   @media(max-width: 456px) {
       font-size: 15px;
   }

   @media(max-width: 375px) {
       font-size: 12px;
   } */
`;
export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
  flex-wrap: wrap;
`;
export const FooterInfo = styled.div`
  display: flex;
  //background-color: red;
  flex-direction: row;
  gap: 30px;
  // padding: 5% 0px 20px 5%;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  @media (max-width: 900px) {
  }
`;
export const CardInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  //background-color: red;
  @media (max-width: 900px) {
    padding-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;
export const CardInfoTitle = styled.div`
  display: flex;
  flex-direction: column;
  // background-color:blue;
  text-align: left;
  h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 5px;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  a {
    font-family: Montserrat;
   
    color: #08d040;
    margin-bottom: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  @media (max-width: 900px) {
    font-family: Montserrat;
    text-align: center;
    h1 {
      font-size: 13.23px;
      font-style: normal;
      font-weight: 400;
    }
    h2 {
      font-size: 13.23px;
      font-style: normal;
      font-weight: 400;
    }
  }
`;

export const NavigatorDiv = styled.div`
  /* @media (max-width: 1300px) {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: column;
  display: flex;
  } */
`;

export const NavigatorLabel = styled.text<any>`
  text-align: left;
  font: normal normal medium 14px/90px Barlow;
  letter-spacing: 0.98px;
  color: #ffffff;
  opacity: 0.7;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  ${({ target }) =>
    target &&
    css`
      opacity: 1;
    `}
`;

export const SocialIconsDiv = styled.div`
  width: 168px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1300px) {
    font-size: 12px;
  }

  @media (max-width: 375px) {
    align-items: flex-end;
  }
`;

export const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
`;

export const InformationsDiv = styled.div`
  //width: 65%;
  //padding: 20px 12% 0px 12%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
   flex-direction: column;
  }
`;

export const InformationsLabel = styled.text`
  text-align: center;

  font: normal normal 300 12px/18px Montserrat;
  color: #000000;
`;

export const Logo = styled.img`
  width: 260px;
  height: 58px;
`;
export const QrCode = styled.div`
  h1 {
    margin: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  text-align: center;
`;

export const QrCodeImg = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    img {
      width: 193px;
      height: 185px;
    }
  }
`;
