import{  useState } from "react";
import {
  CloseStyledIcon,
  Container,
  HamburguerIcon,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  WrapperHamburgerIcon,
} from "./styles";
import Header_Logo from "../../assets/Header/Header_Logo_Black.svg";
import { useLocation } from "react-router";
import { MenuInterface, menu_links } from "../../utils/menu_links";
import { MultiLanguage } from "components/MultiLanguage";
import { useTranslation } from "react-i18next";
import InputSearch from "components/InputSearch";

interface HeaderInterface {
  isToggle: boolean;
  setisToggle: any;
}

export default function Header({ isToggle, setisToggle }: HeaderInterface) {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  //console.log("isInputEnabled", isInputEnabled)
  return (
    <Container id="header" isToggle={isToggle}>
      <Logo
        src={Header_Logo}
        alt="Logo"
        onClick={() => {
          window.location.href = "/";
        }}
        isInputEnabled={isInputEnabled}
      />

      <Menu>
        {menu_links?.map((menu: MenuInterface, index: number) => (
          <MenuItem key={index}>
            <MenuLink target={menu.link === location.pathname} to={menu.link}>
              {t(menu.label)}
            </MenuLink>
          </MenuItem>
        ))}
      </Menu>
      <div style={{ display: "flex", alignItems: "center", gap:10 }}>
        <MultiLanguage isInputEnabled={isInputEnabled}/>
        <InputSearch isInputEnabled={isInputEnabled} setIsInputEnabled={setIsInputEnabled}/>
        <WrapperHamburgerIcon onClick={() => setisToggle(!isToggle)}>
          {isToggle ? <CloseStyledIcon /> : <HamburguerIcon />}
        </WrapperHamburgerIcon>
      </div>
    </Container>
  );
}
