import styled from "styled-components";
export const SmartHubHeader = styled.div<any>`
  width: 100%;
  height: 85vh;
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  // opacity: 40%;
  background-attachment: local;
  display: flex;
  overflow: visible;
  text-align: start;
  position: relative;
  justify-content: flex-end;
  // padding: 23% 12% 0px 0px;
  /* @media (max-width: 1050px) {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  @media (max-width: 900px) {
    background-size: 230%;
    background-position: 16% 23%;
    height: 400px;
    // padding: 20px 20px;
    text-align: center;
  }
`;

export const TitleHeader = styled.div<any>`
  position: absolute;
  //background-color: red;
  // height: 100%;
  width: 470px;
  top: 22%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  h3 {
    color: ${({ bottom }) => (bottom ? "#373936" : "white")};
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
    margin: 0;
  }
  @media (max-width: 900px) {
    background-color: #00000066;
    padding: 20px 30px;
    justify-content: center;
    height: 100%;
    text-align: center;
    width: 100%;
    left: inherit;
    right: inherit;
    top: inherit;
    //  bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 145px;
    h3 {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
    }
    img {
      flex-shrink: 0;

      height: 20%;
      //  width: 100%;
      // margin: 30px;
    }
  }
`;
export const CoworkingContent = styled.div`
  padding: 4% 12% 4% 12%;
  background-color: #ffffff;
  color: #333333;
  display: flex;
  flex-direction: column;
  gap: 30px;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 600;
  }
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
  }
  img {
    margin-top: 30px;
    // height: 380px;
    border-radius: 10px;
    object-fit: cover;
  }
`;
export const StartupList = styled.div`
  padding: 4% 12% 4% 12%;
  background-color: #333c50;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
  }
`;
export const TitleList = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-size: 38px;
  font-weight: 600;
  line-height: 38px;
  margin: 0;
  padding: 32px 40px;
  h2 {
    color: #08d040;
    font-size: 38px;
    font-weight: 600;
    margin: 0;
  }
  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    h2 {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
    }
  }
`;

export const IconsList = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 22px;
  column-gap: 22px;
`;

export const CardsSection = styled.div`
  background-color: "#FFFFFF";
  height: auto;
  padding: 4% 12% 4% 12%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 36px;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
  }
`;

export const CardLearnMore = styled.div`
  background-color: #ffffff;
  width: 456px;
  //height: 580px;
  box-shadow: 0px 1px 8px #00000029;
  border-radius: 14px;
  padding-bottom: 30px;

  h1 {
    margin: 0;
    padding: 10px 10px 0px 20px;
    font-size: 4vh;
    line-height: 38px;
    letter-spacing: 0.6%;
  }
  button {
    margin: 30px 0px 0px 20px;
  }
  @media (max-width: 900px) {
    // padding: 20px 30px;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      padding: 20px 40px;
    }
    button {
      margin: 30px 40px;
    }
  }
`;

export const CardImg = styled.div<any>`
  background-image: url(${({ background }) => background});
  background-size: 126%;
  background-position: center;
  height: 190px;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  transition: background-size 0.3s ease; /* Adicionando uma transição suave */
  overflow: hidden;

  &:hover {
    background-size: 136%; /* Zoom in no hover */
  }
  @media (max-width: 900px) {
    height: 200px;
  }
`;
export const ChipsStatups = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 10px;
`;
export const Chip = styled.div`
  background-color: #44d66d;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  padding: 3px 8px 3px 8px;
`;
export const ImgGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  img {
    box-shadow: 0px 1px 8px #00000029;
    border-radius: 16px;
  }
  @media (max-width: 900px) {
    width: 100%;
    img {
      height: 270px;
      object-fit: cover;
    }
  }
`;
export const ImgRowGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  img {
    // height: 60%;
    width: 50%;
    object-fit: cover;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    img {
      width: 100%;
    }
  }
`;

export const ContactInfoHubs = styled.div`
  //width: 460px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;

  // background-color: red;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    align-items: center;
    img {
      width: 70%;
    }
  }
`;
export const TitleContactInfoHubs = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  img {
    width: 100%;
    align-items: flex-start;
  }
  h1 {
    margin: 0;
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
  }
  h2 {
    margin: 0;
    color: #08d040;
    font-size: 30px;
    font-weight: 600;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
    align-content: flex-start;
    width: 100%;
    img {
    width: auto;
   
  }
  }
`;
