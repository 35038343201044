export interface MenuInterface {
  label: string;
  link: string;
}

export const menu_links: Array<MenuInterface> = [
  {
    label: "whoWeAre",
    link: "/about",
  },
  {
    label: "areas",
    link: "/areas",
  },
  {
    label: "services",
    link: "/services",
  },
  {
    label: "SmartHub",
    link: "/smartHub",
  },
  {
    label: "career",
    link: "/career",
  },
  {
    label: "trainings",
    link: "/academy",
  },
  {
    label: "communication",
    link: "/communication",
  },
  {
    label: "contactUs",
    link: "/contact",
  },
];
