import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
import foto1 from "../../assets/Home/CARROSSEL/CARROSSEL_1.png";
import foto2 from "../../assets/Home/CARROSSEL/CARROSSEL_2.png";
import foto3 from "../../assets/Home/CARROSSEL/CARROSSEL_3.png";
import foto4 from "../../assets/Home/CARROSSEL/CARROSSEL_4.png";
import foto5 from "../../assets/Home/CARROSSEL/CARROSSEL_5.png";
import foto6 from "../../assets/Home/CARROSSEL/CARROSSEL_6.png";
import foto7 from "../../assets/Home/CARROSSEL/CARROSSEL_7.png";
import foto8 from "../../assets/Home/CARROSSEL/CARROSSEL_8.png";
import styled from "styled-components";

const responsive = {
  desktop: {
    breakpoint: { max: 2000, min: 1100 },
    items: 5,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
    paritialVisibilityGutter: 30,
  },
};

const images = [foto1, foto2, foto3, foto4, foto5, foto6, foto7, foto8];

const ClientsCarousel = () => {
  return (
    <div className="parent">
     

      <Carousel
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        arrows={false}
        infinite={true}
        partialVisible={false}
        dotListClass="custom-dot-list-style"
        responsive={responsive}
      >
        {images.slice(0, images.length).map((image, index) => {
          return (
            <Slider className="slider" key={index}>
              <img src={image} alt=""  />
            </Slider>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ClientsCarousel;

export const Slider = styled.div`
  margin: 0 20px;
  overflow: "hidden";
  padding: 1rem 0;
  .slider img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  img{
    height: 200px;
   // width: 300px;
  }

  @media (max-width: 500px) {
    margin: 0 5px;
    //background-color: red;
    padding: 10px 0;
    img{
     
      height: 100px;
    }
  }
`;
