/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "components/Layout";
import Parallax from "components/Parallax";
import { Faixa } from "pages/About/styles";
import back_policy from "../../assets/back_policy.jpg";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import { useTranslation } from "react-i18next";
import {
  Content,
  DescCol,
  TooglePolicy,
  TootleContent,
  TootleTitle,
} from "./styles";
import Icon_Drop from "../../assets/Icon_Drop-down.svg";
import { useState } from "react";
import toogle_back from "../../assets/toogle_back.png";

export const TooglePolicyItens = ({ item }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TooglePolicy onClick={handleToggle}>
      <TootleTitle open={isOpen}>
        {item.title}
        <img
          src={Icon_Drop}
          alt=""
          style={{ transform: isOpen ? "rotate(-180deg)" : "none" }}
        />
      </TootleTitle>
      {isOpen && (
        <TootleContent>
          <img src={toogle_back} alt="" />
          {item.desc}
        </TootleContent>
      )}
    </TooglePolicy>
  );
};

function ProvacyPolicy() {
  const { t } = useTranslation();
  //const [isOpen, setIsOpen] = useState(false);
  // const handleToofle = () => {
  //   setIsOpen(!isOpen);
  // };
  const ToogleData = [
    {
      title: t("privacyAndData"),
      desc: (
        <DescCol>
          <div className="row">
            <h3>
              O <a>Conecthus instituto de Tecnologia e Biotecnologia</a> do
              Amazonas, inscrito no <a>CNPJ: 29.093.966/0001-00</a>, em
              atendimento à lei Geral de
            </h3>
          </div>
          <h3>
            Proteção de Dados (Lei n 13.709, de 14 de agosto de 2018.
            Disponibiliza o canal para informações sobre o tratamento de dados
            pessoais e aos direitos dos titulares, conforme indicado abaixo:
          </h3>
          <a>albertino.filho@conecthus.org.br</a>
          <a>dpo@conecthus.org.br</a>
          <h3>
            Estamos empenhados em preservar sua privacidade e proteger seus
            dados. O propósito desta política é fornecer esclarecimentos sobre
            as informações pessoais tratadas pelo Conecthus em nossos canais
            digitais, redes sociais e serviços correspondentes, e como esses
            dados são utilizados.
          </h3>
        </DescCol>
      ),
    },
    {
      title: "1. Informações Coletadas",
      desc: (
        <DescCol>
          <h3> Podemos coletar as seguintes informações pessoais: </h3>
          <ul>
            <li>
              <h3>
                Informações de contato, como nome, endereço de e-mail, número de
                telefone;
              </h3>
            </li>
            <li>
              <h3>
                Informações de perfil, como data de nascimento, sexo e
                nacionalidade;
              </h3>
            </li>
            <li>
              <h3>Informações profissionais e educacionais;</h3>
            </li>
            <li>
              <h3>Informações sobre eventualdeficiência de saúde.</h3>
            </li>
          </ul>
        </DescCol>
      ),
    },
    {
      title: "2. Finalidade da Coleta",
      desc: (
        <DescCol>
          <a>Fornecer e manter nossos serviços: </a>
          <ul>
            <li>
              <h3>
                Personalizar sua experiência e oferecer conteúdo relevantes;
              </h3>
            </li>
            <li>
              <h3>
                Analisar o uso dos serviços e melhorar nossos produtos e
                serviços;
              </h3>
            </li>
            <li>
              <h3>
                Realizar pesquisas de satisfação, análise de mercado e consultas
                relacionadas às atividades desenvolvidas pelo Conecthus;
              </h3>
            </li>
            <li>
              <h3>Contratação de parceiros e colaboradores;</h3>
            </li>
            <li>
              <h3>Cumprir obrigações legais e regulatórias.</h3>
            </li>
          </ul>
        </DescCol>
      ),
    },
    {
      title: "4. Compartilhamento de Dados",
      desc: (
        <DescCol>
          <h3>
            Os dados coletados e as atividades registradas em nosso ambiente
            podem ser compartilhados nas circunstâncias e dentro dos limites
            exigidos e autorizados por lei, com:
          </h3>

          <ul>
            <li>
             
              <h3>
                Prestadores de serviço que nos auxiliam na operação de nossos
                serviços;
              </h3>
            </li>

            <li>
              <h3>
                Parceiros de negócios para oferecer serviços conjuntos ou
                promover produtos;
              </h3>
            </li>

            <li>
             
              <h3>
                Autoridades governamentais, quando exigido por lei ou para
                proteger nossos direitos legais.
              </h3>
            </li>
          </ul>
        </DescCol>
      ),
    },
    {
      title: "5. Segurança dos Dados",
      desc: (
        <DescCol>
          <h3>
            É essencial manter seus dados seguros mantendo nomes de usuários e
            senhas em sigilo. Nunca solicitaremos confirmação de dados por
            e-mail, e nem enviaremos anexos executáveis ou links para downloads.
          </h3>

          <h3>
            Implementamos medidas de segurança técnicas, administrativas e
            físicas para proteger suas informações pessoais contra acesso não
            autorizado, uso indevido ou divulgação.
          </h3>
        </DescCol>
      ),
    },
    {
      title: "6. Seus Direitos de Privacidade",
      desc: (
        <DescCol>
          <h3>
            Você tem o direito de acessar, corrigir ou excluir suas informações
            pessoais. Você também pode optar por não receber comunicações de
            marketing direto.
          </h3>
        </DescCol>
      ),
    },
  ];
  return (
    <Layout>
      <Parallax
        background={back_policy}
        leftBottom
        title={t("protectingYourData")}
      />
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>
      <Content>
        <h1>{t("conecthusDataPrivacy")}</h1>
        {ToogleData.map((item, index) => (
          <TooglePolicyItens key={index} item={item} />
        ))}
      </Content>
    </Layout>
  );
}

export default ProvacyPolicy;
