import { ReactElement, ReactNode, useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ToggleMenu from "./ToggleMenu";
import { menu_links } from "../../utils/menu_links";
import { useLocation } from "react-router-dom";
import { Fab } from "@mui/material";


import { ReactComponent as ZapIcon } from "../../assets/zapIcon.svg";
import styled from "styled-components";
interface LayoutInterface {
  children: ReactNode;
}

export default function Layout({ children }: LayoutInterface): ReactElement {
  const [isToggle, setisToggle] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    if (!hash) {
      // Scroll to top only if there's no hash in the URL
      window.scrollTo(0, 0);
    }
  }, [location]);

  const [isFabOpen, sertFabOpen] = useState(false);
  const handleToogle = () => {
    sertFabOpen(!isFabOpen);
  };

  return (
    <>
      <Header isToggle={isToggle} setisToggle={setisToggle} />
      {isToggle && <ToggleMenu menu_links={menu_links} />}
      {children}
      <div
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
          zIndex: 999,
        }}
      >
        {isFabOpen && (
          <div>
            <CardFab>
              <h2>
                Entre em contato conosco e um dos nossos especialistas estará à
                disposição para atendê-lo.
              </h2>

              <CardLink
                href="https://api.whatsapp.com/send?phone=555592992148657&text=Ol%C3%A1%2C%20vim%20pelo%20link%20da%20website%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os."
                target="_blank"
                rel="noreferrer"
              >
              
                  <h3>Iniciar conversa</h3>
                <ZapIcon />
              </CardLink>
            </CardFab>
          </div>
        )}
        <Fab
          sx={{
            backgroundColor: "	#25d366",
            padding: 1.4,
            margin: 3,
            "&:hover": { backgroundColor: "#128c7e" },
          }}
          size="large"
          onClick={handleToogle}
        >
          <ZapIcon />
        </Fab>
      </div>
      <Footer />
    </>
  );
}
export const CardLink = styled.a`
  background-color: #25d366;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  font-family: Barlow;
  h3 {
    color: #000000de;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;
export const CardFab = styled.div`
font-family: Barlow;
  h2 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 4px;
  }
  background-color: #ffffff;
  padding: 20px;
  position: fixed;
  /* top: 104px; */
  bottom: 116px;
  right: 20px;
  height: 237px;
  width: 300px;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 4px 6px 0px #25d366;
  pointer-events: auto;
  opacity: 1;
  scale: 1;
  z-index: 16000160 !important;
  transform-origin: right bottom;
  transition: scale 150ms ease-in, opacity 250ms ease-in;

  &:before {
    content: "";
    position: absolute;
    top: 100%;
    right: 29px;
    width: 0;
    height: 0;
    border-width: 0 0px 30px 30px;
    border-color: transparent transparent white transparent;
    border-style: solid;
    transform: rotate(270deg);
    z-index: 1;
  }
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    right: 27px;
    width: 0;
    height: 0;
    border-width: 0px 0px 34px 34px;
    border-color: transparent transparent black transparent;
    border-style: solid;
    border-radius: 2px;
    filter: drop-shadow(-2px 5px 0px #25d366);
    transform: rotate(270deg);
  }
`;
