import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 589px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  @media (min-width: 821px) {
    display: none;
  }
`;

export const BlurEffect = styled.div`
  width: 100%;
  height: 100%;
`;

export const Menu = styled.ul`
  margin-top: 10px;
  list-style-type: none;
  position: absolute;
  width: 70%;
`;

export const MenuItem = styled.li<any>`
  padding: 16px 8px;
  margin-bottom: 4px;
  text-align: start;
  background-color: ${({ target }) => (target ? "#1A202C" : "#FFFFF")};
  border-radius: 10px;
`;

export const MenuLink = styled(Link)<any>`
  text-decoration: none;
  color: #212121;

  letter-spacing: 1px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  &:hover {
    color: ${({ target }) => (target ? "#08D040" : "#212121")};
  }
  ${({ target }) =>
    target &&
    css`
      opacity: 100%;
      color: #08d040;
    `}
`;

export const ContainterLanguage = styled.div`
  text-align: center;
  .divider {
    border: 1px solid black;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 15px;
  }
`;

export const Elpsis = styled.div`
  width: 33px;
  min-width: 33px;
  max-width: 33px;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  border-radius: 50%;
  background-color: #000; /* Cor de fundo */
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #02bc4d;
    color: #000;
  }
`;
