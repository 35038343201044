export const handleCellPhoneChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setCellPhone: any,
  input: any
) => {
  e.preventDefault();
  const inputValue = e.target.value;

  // Remove caracteres não numéricos
  const numericValue = inputValue.replace(/\D/g, "");

  // Aplica a máscara do celular
  const cellPhoneWithMask = numericValue.replace(/^(\d{2})(\d{1,9})$/, "$1 $2");

  setCellPhone((prev: any) => ({
    ...prev,
    [input]: cellPhoneWithMask,
  }));
};

export const isValidEmail = (value: string) => {
    // Expressão regular modificada para validar endereço de e-mail
    const emailRegex = /^[a-zA-Z0-9._%+-]+(?:\.[a-zA-Z0-9._%+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
  };

export const handleEmailInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFormDataError: any,
    input: any
  ) => {
    const inputValue = e.target.value;
    const regex = /\./g;
    const noDots = (inputValue.match(regex) || []).length;
    const consecutiveDots = inputValue.includes("..");
  
    if (
      !consecutiveDots &&
      noDots >= 1 &&
      isValidEmail(inputValue) &&
      inputValue.length > 10
    ) {
      // Aqui, você pode querer verificar a validação adicional do e-mail se necessário
      setFormDataError((prevData: any) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          [input]: "",
        },
      }));
    } else if (consecutiveDots || noDots > 1) {
      setFormDataError((prevData: any) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          [input]: "enterValidEmail",
        },
      }));
    } else {
      if (inputValue.length < 10) {
        //setErrorEmail(`Email ${t("have5characters")}`);
        setFormDataError((prevData: any) => ({
          ...prevData,
          errors: {
            ...prevData.errors,
            [input]: "have5characters",
          },
        }));
      }
      //setErrorEmail(t("enterValidEmail"));
      setFormDataError((prevData: any) => ({
        ...prevData,
        errors: {
          ...prevData.errors,
          [input]: "enterValidEmail",
        },
      }));
    }
  };
  
  export function isAnyFieldEmpty(obj: any) {
    for (const key in obj) {
      if (obj[key] === "" ) {
        return true; // Se pelo menos um campo estiver preenchido, retorna true
      }
    }
    return false; // Se todos os campos estiverem vazios, retorna false
  }