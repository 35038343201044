/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "components/Layout";
import { Faixa } from "pages/About/styles";
import ArrowDown from "../../assets/About/ArrowDown.svg";
import background from "../../assets/Career/BACK.jpg";
import {
  BenefitsCard,
  BenefitsCards,
  BenefitsContent,
  BenefitsTitle,
  ButtonMain,
  CardBack,
  CardFront,
  CardInsideBack,
  CardInsideFront,
  CardWrap,
  CarreirasHeader,
  ContentCV,
  Dots,
  FormCV,
  FormRegisterCV,
  FrontContent,
  JobsContent,
  OurCulture,
  OurJobs,
  Stars,
  SubTextInside,
  TextInsideBack,
  TextOurCulture,
  TitleCarreirasHeader,
  WorkAtConnecthus,
} from "./styles";
import our_culture_back from "../../assets/Career/our_culture_back.jpg";
import benefits_back from "../../assets/Career/benefits_back.jpg";
import vale from "../../assets/Career/benefits_icons/vale.svg";
import plano from "../../assets/Career/benefits_icons/plano.svg";
import odonto from "../../assets/Career/benefits_icons/odonto.svg";
import transport from "../../assets/Career/benefits_icons/transport.svg";
import seguro from "../../assets/Career/benefits_icons/seguro.svg";
import alura from "../../assets/Career/benefits_icons/alura.svg";
import day_off from "../../assets/Career/benefits_icons/day_off.svg";
import star_filled from "../../assets/Career/star-filled.svg";
import work1 from "../../assets/Career/work_us/work1.jpg";
import work2 from "../../assets/Career/work_us/work2.png";
import work3 from "../../assets/Career/work_us/work3.png";
import work4 from "../../assets/Career/work_us/work4.png";
import layer from "../../assets/Career/layer.svg";
import dots from "../../assets/Career/dots.svg";
import { useEffect, useState } from "react";
import MainBtn from "components/Buttons/MainBtn";
import { WorkUsDiv, WorkUsTitle } from "pages/Home/styles";
import { CheckForm, HelperText, PolicyPricacy } from "pages/Areas/styles";
import { Checkbox, TextField } from "@mui/material";
import Icon_Drop from "../../assets/Icon_Drop-down.svg";
import { useTranslation } from "react-i18next";
import {
  handleCellPhoneChange,
  handleEmailInputChange,
} from "utils/formatText";

import { TooglePolicy, TootleContent, TootleTitle } from "pages/policy/styles";
import { useHistory } from "react-router-dom";

const ToogleJobItens = ({ item }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <TooglePolicy onClick={handleToggle}>
      <TootleTitle open={isOpen}>
        {item.title}
        <img
          src={Icon_Drop}
          alt=""
          style={{ transform: isOpen ? "rotate(-180deg)" : "none" }}
        />
      </TootleTitle>
      {isOpen && (
        <TootleContent>
          {item.desc}
          <ButtonMain>{t("apply")}</ButtonMain>
        </TootleContent>
      )}
    </TooglePolicy>
  );
};
function Career() {
  const history = useHistory();
  const { t } = useTranslation();
  // const inputRef = useRef<HTMLInputElement>(null);
  const benefits = [
    {
      icon: vale,
      title: t("foodAndMealVoucher"),
    },
    {
      icon: plano,
      title: t("healthInsurance"),
    },
    {
      icon: odonto,
      title: t("dentalPlan"),
    },
    {
      icon: transport,
      title: t("transportationVouchers"),
    },
    {
      icon: seguro,
      title: t("lifeInsurance"),
    },
    {
      icon: alura,
      title: t("aluraPlatform"),
    },
    {
      icon: day_off,
      title: "Day off",
    },
  ];
  const depositions = [
    {
      img: work1,
      desc: t("whenLeadingLargeTeams"),
      name: "Aristeu Jonatas ",
      position: t("projectManager"),
    },
    {
      img: work2,
      desc: t("hereWeHaveTheOpportunity"),
      name: "Eduardo Magalhães",
      position: t("projectLeader"),
    },
    {
      img: work3,
      desc: t("hereAtTheInstitute"),
      name: "David Wilkerson",
      position: t("artist3D"),
    },

    {
      img: work4,
      desc: t("atConecthusIParticipated"),
      name: "Nadia Cavalcante",
      position: "Designer Jr ",
    },
  ];

  const [currentDepositionIndex, setCurrentDepositionIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false); // Inicia a animação de fade out
      setTimeout(() => {
        setCurrentDepositionIndex(
          (prevIndex) => (prevIndex + 1) % depositions.length
        );
        setIsVisible(true); // Inicia a animação de fade in
      }, 500); // Tempo de espera para a animação de fade out (em milissegundos)
    }, 10000); // 60 segundos em milissegundos

    return () => clearInterval(interval);
  }, []);

  const currentDeposition = depositions[currentDepositionIndex];

  //IMPORTANTE!!!!//
  //para adcionar mais um item escreva a descrição aqui https://onlinehtmleditor.dev/
  //ps: nao esqueça de adcionar o fechamento do <br>
  //IMPORTANTE!!!!//

  const noJobs = [
    {
      title: "Analista de P&D PL",
      desc: (
        <p style={{ textAlign: "left" }}>
          • Acompanhar em tempo real a execução dos projetos de P&amp;D: técnico
          e financeiro.&nbsp;
          <br />
          • Elaboração e análise: convênios, planos de trabalho e plano de
          utilização de recursos (técnico e financeiro).&nbsp;
          <br />
          • Realizar a separação de documentos dos projetos: contratos, termos
          aditivos, adendos. Validação dos pagamentos, conciliação bancária,
          alocação de notas fiscais e seus rateios nas devidas rubricas e
          atividades dos projetos;&nbsp;
          <br />
          Realizar prestação de contas dos projetos: elaborar e analisar
          relatórios técnico e financeiro;&nbsp;
          <br />
          • Conduzir reunião com gerente de projetos, com a finalidade de
          informar custos planejados e realizados e orientá-los na elaboração
          dos relatórios técnicos demonstrativos (RDA) e justificar;&nbsp;
          <br />
          • Controle e atualização do fluxo de caixa dos projetos: atualizar
          planilha financeira com as despesas executadas.&nbsp;
          <br />
          • Acompanhar e treinar o Analista de P&amp;D Jr e Assistentes de
          P&amp;D nas atividades inerentes ao departamento de P&amp;D;&nbsp;
          <br />
          Diferencial:&nbsp;
          <br />
          • Elaboração e Análise Técnica e Financeira de Planos de Trabalho,
          Plano de Utilização de Recursos (PURS) – enquadrabilidade, pertinência
          e adequação.&nbsp;
          <br />
          • Experiência de no mínimo 2 anos em Gestão de Projetos de
          P&amp;D.&nbsp;
          <br />
          Requisitos:&nbsp;
          <br />
          Experiência em controles financeiros e Lei de Informática (legislações
          vigentes).&nbsp;
          <br />
          Conhecimento em Prestação de Contas, P&amp;D (Suframa, MCTI,
          PPI)&nbsp;
          <br />
          Conhecimentos em Pacote Office.&nbsp;
          <br />
          Escolaridade:&nbsp;
          <br />
          Ensino Superior Completo em Administração, Economia, Engenharia,
          Contabilidade, ou áreas afins.
          <br />
        </p>
      ),
    },
    {
      title: "Analista de Infraestrutura Laboratorial ",
      desc: (
        <p style={{ textAlign: "left" }}>
          • Realizar levantamento de matérias primas, insumos e equipamentos
          para solicitação de compra <br />• Realizar o controle e abastecimento
          de estoque <br />• Acompanhar a instalação de equipamentos e melhoria
          da infraestrutura, <br />• Elaborar os relatórios mensais de
          atividades, plotagem de resultados, <br />• Apoiar na execução da
          fabricação dos produtos a partir das formulações definidas, <br />•
          Realizar cotação de matérias primas e equipamentos. <br />• Acompanhar
          prestação de serviços de terceiros. Requisitos: Experiencia na área de
          laboratório de alimentos ou química. Pacote Office Avançado
          Escolaridade: Ensino superior completo em Administração, Engenharia
          Química, Engenharia de Alimentos .
        </p>
      ),
    },
  ];

  const [formRegisterCV, setFormRegisterCV] = useState<any>({
    name: "",
    email: "",
    phone: "",
    country: "",
    UF: "",
    pcd: false,
    education: "",
    message: "",
    CV: null,
    check: false,
  });
  const [formError, setFormError] = useState({
    errors: {
      email: "",
    },
  });
  // const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
  //   event.preventDefault();
  //   };
  //   const openFileInput = () => {
  //   if (inputRef.current) {
  //   inputRef.current.click();
  //   }
  //   };

  const handleForm = () => {
    const url = "https://api.conecthus.org.br/mailer/send";

    // const readFileAsBase64 = (file: any) => {
    // return new Promise((resolve, reject) => {
    // const reader = new FileReader();
    // reader.onload = () => resolve(reader.result);
    // reader.onerror = (error) => reject(error);
    // reader.readAsDataURL(file);
    // });
    // };

    const sendEmail = (base64File: any, fileName: any) => {
      const params = {
        sender: "Carreiras_Conecthus",
        receiver: "vagas@conecthus.org.br",
        subject: "Formulario - Carreiras",
        message: `<h3>${formRegisterCV.message}</h3>
        <br></br>Nome: ${formRegisterCV.name}</br>
        <br></br>Email: ${formRegisterCV.email}</br>
        <br></br>Telefone: ${formRegisterCV.phone}</br>
        `,
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setFormRegisterCV({
            name: "",
            email: "",
            phone: "",
            country: "",
            UF: "",
            company: "",
            sector: "",
            message: "",
            check: false,
          });
          window.location.href = "#";
          const params = {
            sender: "Carreiras_Conecthus",
            receiver: `${formRegisterCV.email}`,
            subject: "Confirmação de Recebimento - Conecthus",
            message: `<h3>Prezado(a) ${formRegisterCV.name}</h3>
        <br></br>Agradecemos por entrar em contato conosco através do formulário em nosso website.
        Este e-mail é para confirmar que recebemos sua mensagem e estamos trabalhando para fornecer
        a melhor assistência.</b><br></br></br>
        <br></br>Nossa equipe está analisando suas informações e faremos o possível para responder em
        breve.</br><br></br>
        </br><br></br>Atenciosamente,</br><br></br></br>
        <br></br>Instituto Conecthus</br>`,
          };

          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          }).then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    };

    // Assuming formRegisterCV.CV is a File object
    // if (formRegisterCV.CV) {
    // readFileAsBase64(formRegisterCV.CV)
    // .then((base64File) => {
    // sendEmail(base64File, formRegisterCV.CV.name);
    // //console.log("aaaa", base64File, formRegisterCV.CV.name)
    // })
    // .catch((error) => {
    // console.error("Error reading file", error);
    // });
    // } else {
    sendEmail("", ""); // or handle the case when there is no file
    // }
  };

  return (
    <Layout>
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <CarreirasHeader background={background}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <TitleCarreirasHeader>
              <h3>
                <a
                  className="header-anchor"
                  href="#weAreInspiredEverYday"
                  id="weAreInspiredEverYday"
                >
                  #
                </a>
                {t("weAreInspiredEverYday")}
              </h3>
            </TitleCarreirasHeader>
          </div>
        </CarreirasHeader>
      </div>
      <Faixa>
        <img src={ArrowDown} alt="" />
      </Faixa>
      <OurCulture>
        <img src={our_culture_back} alt="" />
        <TextOurCulture>
          <h1>
            <a className="header-anchor" href="#ourCulture" id="ourCulture">
              #
            </a>
            {t("ourCulture")}
          </h1>
          <h3>
            <a
              className="header-anchor"
              href="#weRecognizeAndAdmire"
              id="weRecognizeAndAdmire"
            >
              #
            </a>
            {t("weRecognizeAndAdmire")}
          </h3>
        </TextOurCulture>
      </OurCulture>
      <BenefitsContent>
        <BenefitsTitle>
          <TextOurCulture>
            <h1>
              <a
                className="header-anchor"
                href="#discoverOurBenefits"
                id="discoverOurBenefits"
              >
                #
              </a>
              {t("discoverOurBenefits")}
            </h1>
            <h3>
              <a
                className="header-anchor"
                href="#inDynamicAndInnovative"
                id="inDynamicAndInnovative"
              >
                #
              </a>
              {t("inDynamicAndInnovative")}
            </h3>
          </TextOurCulture>
          <img src={benefits_back} alt="" />
        </BenefitsTitle>
        <BenefitsCards>
          {benefits.map((item: any) => (
            <BenefitsCard>
              <img src={item.icon} alt="" />
              <h2>{item.title}</h2>
            </BenefitsCard>
          ))}
        </BenefitsCards>
      </BenefitsContent>

      <WorkAtConnecthus>
        <h1>
          <a
            className="header-anchor"
            href="#whorkAtConecthus"
            id="whorkAtConecthus"
          >
            #
          </a>
          {t("whorkAtConecthus")}
        </h1>

        <CardWrap>
          <CardBack>
            <CardInsideBack>
              <div style={{ position: "absolute" }}>
                <img src={layer} alt="" />
              </div>
              <TextInsideBack
                className={`deposition-container ${
                  isVisible ? "visible" : "hidden"
                }`}
              >
                <h3>{currentDeposition.desc}</h3>
                <SubTextInside>
                  <h3>{currentDeposition.name}</h3>
                  <p>{currentDeposition.position}</p>
                </SubTextInside>
                <Dots>
                  <img src={dots} alt="" />
                </Dots>
              </TextInsideBack>
            </CardInsideBack>
            <CardInsideFront
              className={`deposition-container ${
                isVisible ? "visible" : "hidden"
              }`}
              background={currentDeposition.img}
            />
          </CardBack>
          <CardFront>
            <FrontContent>
              <h3>
                <a
                  className="header-anchor"
                  href="#aPlaceThatBringTogether"
                  id="aPlaceThatBringTogether"
                >
                  #
                </a>
                {t("aPlaceThatBringTogether")}
              </h3>
              <Stars>
                <img src={star_filled} alt="" />
                <img src={star_filled} alt="" />
                <img src={star_filled} alt="" />
                <img src={star_filled} alt="" />
                <img src={star_filled} alt="" />
              </Stars>
            </FrontContent>
          </CardFront>
        </CardWrap>
      </WorkAtConnecthus>
      <OurJobs>
        <h1>
          <a
            className="header-anchor"
            href="#findJobOpportunities"
            id="findJobOpportunities"
          >
            #
          </a>
          {t("findJobOpportunities")}
        </h1>

        <JobsContent>
          {noJobs.map((item, index) => (
            <ToogleJobItens key={index} item={item} />
          ))}
        </JobsContent>
      </OurJobs>

      <FormRegisterCV>
        <ContentCV>
          <h1 style={{ textAlign: "center", padding: 20 }}>
            <a
              className="header-anchor"
              href="#registerYourCV"
              id="registerYourCV"
            >
              #
            </a>
            {t("registerYourCV")}
          </h1>
          <h3>{t("justFillThBlank")}</h3>

          <FormCV>
            <TextField
              id="outlined-basic"
              label={t("name")}
              variant="outlined"
              value={formRegisterCV.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormRegisterCV((prev: any) => ({
                  ...prev,
                  name: event.target.value,
                }));
              }}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              placeholder="Email pessoal"
              variant="outlined"
              value={formRegisterCV.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormRegisterCV((prev: any) => ({
                  ...prev,
                  email: event.target.value,
                }));
              }}
              onBlur={(e: any) =>
                handleEmailInputChange(e, setFormError, "email")
              }
              inputProps={{ maxLength: 100 }}
            />
            {formError.errors.email && (
              <HelperText>
                <div>{t(formError.errors.email)}</div>
              </HelperText>
            )}
            <TextField
              id="outlined-basic"
              label={t("phone")}
              variant="outlined"
              value={formRegisterCV.phone}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleCellPhoneChange(event, setFormRegisterCV, "phone");
              }}
              inputProps={{ maxLength: 12 }}
            />

            {/* <RowForm>
                        <SelectMain label={t("state")} items={UFList} value={formRegisterCV.UF} onChange={(event:
                          React.ChangeEvent<HTMLInputElement>) => {
                          setFormRegisterCV((prev: any) => ({
                          ...prev,
                          UF: event.target.value,
                          }));
                          }}
                          />
                          <SelectMain label={"PCD"} items={PCD} value={formRegisterCV.pcd} onChange={(event:
                            React.ChangeEvent<HTMLInputElement>) => {
                            setFormRegisterCV((prev: any) => ({
                            ...prev,
                            pcd: event.target.value,
                            }));
                            }}
                            />
                      </RowForm> */}

            {/* <SelectMain label={t("education")} items={EDUCATIONS} value={formRegisterCV.education}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormRegisterCV((prev: any) => ({
                        ...prev,
                        education: event.target.value,
                        }));
                        }}
                        /> */}
            <TextField
              id="outlined-basic"
              label={t("jobTitle")}
              variant="outlined"
              placeholder={t("writeMessage")}
              value={formRegisterCV.message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFormRegisterCV((prev: any) => ({
                  ...prev,
                  message: event.target.value,
                }));
              }}
              multiline
              maxRows={4}
              rows={4}
            />
            {/* <ColForm>
                            <p>{t("attachCV")} </p>
                            {formRegisterCV.CV ? (
                            <DropZone>
                              <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      textDecoration: "underline",
                    }}>
                                {formRegisterCV.CV.name}{" "}
                                <Close onClick={()=> {
                                  setFormRegisterCV((prev: any) => ({
                                  ...prev,
                                  CV: null,
                                  }));
                                  }}
                                  />
                              </div>
                            </DropZone>
                            ) : (
                            <DropZone onDragOver={handleDragOver} onClick={openFileInput}>
                              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <ButtonDrop>{t("chooseYourFile")}</ButtonDrop>
                              </div>

                              <input type="file" multiple onChange={(event: any)=> {
                              if (
                              event.target.files &&
                              event.target.files[0].size <= 1024 * 1024 && (event.target.files[0].name.endsWith(".png")
                                || event.target.files[0].name.endsWith(".pdf") ||
                                event.target.files[0].name.endsWith(".jpg")) ) { setFormRegisterCV((prev: any)=> ({
                                ...prev,
                                CV: event.target.files[0],
                                }));
                                } else {
                                alert("Formato de arquivo inválido");
                                }
                                }}
                                hidden
                                ref={inputRef}
                                />
                            </DropZone>
                            )}
                          </ColForm> */}
            <CheckForm>
              <Checkbox
                checked={formRegisterCV.check}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFormRegisterCV((prev: any) => ({
                    ...prev,
                    check: event.target.checked,
                  }));
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <PolicyPricacy>{t("institutoConecthuRequests")}</PolicyPricacy>
            </CheckForm>
          </FormCV>

          <MainBtn
            onClick={handleForm} // disabled={isAnyFieldEmpty(formRegisterCV) || !formRegisterCV.check}
            label={t("send")}
            variant="contained"
            padding="15px 40px"
          />
        </ContentCV>
      </FormRegisterCV>

      <WorkUsDiv>
        <WorkUsTitle>
          <h2>{t("comeJoinOurTeam")}</h2>
          <h2> {t("comeJoinOurTeam2")}</h2>
        </WorkUsTitle>
        <a
          href={`mailto:vagas@conecthus.org.br?subject=Currículo para análise&body=Olá${escape(
            ","
          )} segue anexo
              meu currículo para apreciação referente a vagas na área de...`}
        >
          <MainBtn
            variant="outlined"
            label={t("workWithUs")}
            onClick={() => history.push("/contact")}
          />
        </a>
      </WorkUsDiv>
    </Layout>
  );
}

export default Career;
